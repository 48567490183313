import React from 'react';
import { Flex } from '@chakra-ui/layout';
import { LeaderboardItem } from './LeaderboardItem';
import {
  RankedLeaderboard,
  RankedScoreItem,
} from '../utils/getUpdatedLeaderboard';
import { format } from 'd3-format';
import { isEmpty, startCase } from 'lodash';

interface LeaderboardProps {
  value: RankedLeaderboard;
}

const formatRankString = (rank: number) => {
  switch (rank) {
    case 1:
      return '1st';
    case 2:
      return '2nd';
    case 3:
      return '3rd';
    default:
      return `${rank}th`;
  }
};

const formatUsername = (email: string, playerId: string) => {
  console.log('email', email);
  if (!email || isEmpty(email)) {
    return startCase(playerId);
  }

  return email.split('@')[0];
};

const formatResultsString = (seniority: string, role: string, msa: string) => {
  return `${startCase(`${seniority} ${role} ${msa}`)}`;
};

export const Leaderboard = ({ value }: LeaderboardProps) => {
  return (
    <Flex direction="column" alignItems="center" rowGap={4}>
      {value.map((v: RankedScoreItem) => (
        <LeaderboardItem
          rank={formatRankString(v.rank)}
          username={formatUsername(v.email, v.playerId)}
          result={formatResultsString(v.seniority, v.role, v.msa)}
          salary={format('$,')(v.salary)}
          isCurrent={v.isNew}
        />
      ))}
    </Flex>
  );
};
