import { Client } from 'urql';
import {
  GAME_GET_RESULTS_STATS,
  TALENT_DISCOVERY_MAP_DATA,
} from '../gql/game.models';
import { get, isUndefined, omit } from 'lodash';
import { format } from 'd3';
import { calcMarketTightness } from '@revelio/d3';
import { GO_API_ROOT } from '@revelio/filtering';
import axios from 'axios';

type FetchResultsProps = {
  seniority?: number[];
  role?: number[];
  msa?: number[];
  prevYearSentimentEndDate?: string;
  postingsEndDate?: string;
  gqlClient: Client;
};

export const fetchResultsStats = async ({
  seniority,
  role,
  msa,
  prevYearSentimentEndDate,
  postingsEndDate,
  gqlClient,
}: FetchResultsProps) => {
  const vars = {
    msa,
    role_k150: role,
    seniority,
    prevYearSentimentEndDate,
    postingsEndDate,
  };

  const compensationVars = omit(vars, [
    'prevYearSentimentEndDate',
    'postingsEndDate',
  ]);

  const tdVars = Object.entries(compensationVars).reduce(
    (allVars, [key, ids]) => {
      return {
        ...allVars,
        [key]: {
          ids,
          current: true,
          non_current: false,
        },
      };
    },
    {}
  );

  const resultsQuery = gqlClient.query(GAME_GET_RESULTS_STATS, vars);

  const TDQuery = gqlClient.query(TALENT_DISCOVERY_MAP_DATA, {
    query: { ...tdVars, page: 1 },
  });

  const TDRes = await TDQuery.toPromise().catch((e) => {
    console.log('TD Error: ', e);
    return e;
  });

  const compensationRes = await fetchCompensationData({
    vars: { ...compensationVars, dim1: 'role' },
  }).catch((e) => {
    console.log('Plot Error: ', e);
    return e;
  });

  const res = await resultsQuery.toPromise().catch((e) => {
    console.log('Stats Error:', e);
    return e;
  });

  return { otherStats: res, compensation: compensationRes, td: TDRes };
};

export const fetchCompensationData = async ({
  vars,
}: {
  vars: {
    msa?: (number | string)[];
    role_k150?: (number | string)[];
    seniority?: (number | string)[];
    dim1: string;
  };
}) => {
  try {
    const response = await axios.post(
      `${GO_API_ROOT}/models/compensation`,
      {
        ...vars,
        year_range: [[2023]],
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error making request:', error);
  }
};

const calcGrowthYearOverYear = ({
  currentYearMetric,
  previousYearMetric,
}: Record<string, number>) => {
  const yearDifference = currentYearMetric - previousYearMetric;

  const differenceOverYear = yearDifference / previousYearMetric;

  return differenceOverYear;
};

// TODO: Need to type data
// eslint-disable-next-line
export const transformCompensationData = (data: any) => {
  // TODO: Need to type data
  // eslint-disable-next-line
  const totalCompData = data?.find((d: any) => d.id === 'total_compensation');

  const totalCompValues = get(totalCompData, 'value', []);
  // TODO: Need to type data
  // eslint-disable-next-line
  const kdeData = totalCompValues?.find((d: any) => d.id === 'kde');

  return kdeData;
};

// TODO: Need to type data
// eslint-disable-next-line
export const transformTDResponse = (data: any, id: number) => {
  const tdData = get(data, 'talent_discovery_search_v1_5', {});
  const mapData = get(tdData, 'map_data', []);

  // TODO: Need to type data
  // eslint-disable-next-line
  const mapDataPoint = mapData.find((dataPoint: any) => {
    const { type, id: mapId } = dataPoint || {};
    return type === 2 && mapId === id;
  });

  return get(mapDataPoint, 'count');
};
// TODO: Need to type data
// eslint-disable-next-line
export const transformResultsStatsResponse = (data: any) => {
  const rawComposition = get(data, 'rawComposition', []);
  const compositionMetrics = get(rawComposition[0], 'metrics', {});

  const posting = get(data, 'posting', []);
  const postingData = get(posting[0], 'category', []);

  const postingMetrics = get(postingData.slice(-1)[0], 'metrics', {});

  const postingMetricsLastFour = postingData.slice(-4);

  let largestPostingMetricLastFour: number | undefined;

  // eslint-disable-next-line
  postingMetricsLastFour.forEach((postingMetric: any) => {
    const currentMetric = get(postingMetric, 'metrics', {});
    const { active } = currentMetric;
    if (
      isUndefined(largestPostingMetricLastFour) ||
      active > largestPostingMetricLastFour
    ) {
      largestPostingMetricLastFour = active;
    }
  });

  const previousYearSentiment = get(data, 'previousYearSentiment', []);
  const currentYearSentiment = get(data, 'currentYearSentiment', []);

  const previousYearSentimentMetric = get(
    previousYearSentiment[0],
    'metrics',
    {}
  );
  const currentYearSentimentMetric = get(
    currentYearSentiment[0],
    'metrics',
    {}
  );

  const getCompositionMetricValues = (metrics: any) => {
    return Object.entries(metrics)?.reduce((acc: any, [key, value]: any) => {
      if (key === '__typename') return acc;

      return { ...acc, [key]: value.timeseries.slice(-1)[0].value };
    }, {});
  };

  const { growth_rate, hiring_rate, attrition_rate, tenure, headcount } =
    getCompositionMetricValues(compositionMetrics);

  const { time_to_fill, active } = postingMetrics;

  const { overallRating: previousYearMetric } = previousYearSentimentMetric;
  const { overallRating: currentYearMetric } = currentYearSentimentMetric;

  const growthYearOverYear = calcGrowthYearOverYear({
    currentYearMetric,
    previousYearMetric,
  });
  const marketTightness = calcMarketTightness(active, headcount, time_to_fill);

  const transformed = {
    // headcount: format(',d')(headcount), // we are getting this number from talent-discovery
    growth: format('.1%')(growth_rate),
    hiring: format('.1%')(hiring_rate),
    attrition: format('.1%')(attrition_rate),
    tenure: `${format('.1f')(tenure)} yrs`,
    sentiment:
      !isFinite(growthYearOverYear) || isNaN(growthYearOverYear)
        ? '--'
        : `${growthYearOverYear > 0 ? '+' : ''}${format('.0%')(
            growthYearOverYear
          )}`,
    postings: largestPostingMetricLastFour
      ? format(',d')(largestPostingMetricLastFour)
      : '--',
    time_to_fill: time_to_fill ? `${format('d')(time_to_fill)} days` : '--',
    market_tightness: String(marketTightness),
  };

  return transformed;
};
