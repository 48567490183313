import MailChimpSubscribe from 'react-mailchimp-subscribe';
import { EmailFormInput } from './EmailFormInput';

export const MailChimpEmailSubscribe = () => {
  const MAILCHIMP_URL =
    'https://reveliolabs.us8.list-manage.com/subscribe/post?u=5b585166dc0e8a8d5ed1c2166&amp;id=3f787dbd85&amp;f_id=000fe0e2f0';

  return (
    <MailChimpSubscribe
      url={MAILCHIMP_URL}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render={({ subscribe, status, message }: any) => {
        return (
          <EmailFormInput
            subscribe={subscribe}
            status={status}
            message={message}
          />
        );
      }}
    />
  );
};
