export const data = [
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65827
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110534
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26832
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59336
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176453
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 122173
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51185
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96072
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102745
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96214
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69896
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 4651
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8192
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189695
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13372
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91096
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8578
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93048
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74707
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17588
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6871
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116822
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92966
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 295057
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 160347
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97538
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5565
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24975
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83016
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45770
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173171
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19467
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104603
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81577
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24479
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80226
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106219
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52481
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93555
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145736
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93428
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76986
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176600
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65798
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70656
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115209
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46115
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 144206
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93263
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9777
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48565
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61880
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60295
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154313
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86891
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130507
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50271
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57558
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10262
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106672
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17076
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11081
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69639
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14535
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114594
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8942
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170832
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14564
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91432
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61426
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56911
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169260
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16206
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49328
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6762
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77674
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174284
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 4528
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169229
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36660
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 50511
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16992
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 194452
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58804
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 101648
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 255708
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19260
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35575
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 103374
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34623
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80414
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10552
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55073
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54892
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132890
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135753
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70913
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63286
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103878
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207297
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80750
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62433
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108409
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10616
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81033
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 143020
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96083
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135897
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74010
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72560
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12901
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51601
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90511
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190883
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44699
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136712
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16656
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141998
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16782
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107849
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175685
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140915
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58684
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162272
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151103
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43294
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117624
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69949
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85345
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 222594
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6163
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 137584
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85619
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10242
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45318
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178111
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24944
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129573
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 39693
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41991
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21212
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 252118
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114104
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18759
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73264
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 55579
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 293632
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184791
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77548
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142625
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 207275
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104488
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12404
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187218
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139839
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40765
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 67054
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88254
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107363
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21412
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74796
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87502
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209618
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106741
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97218
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99110
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108782
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52955
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112955
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16908
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67117
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 266759
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81747
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48895
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 132293
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188950
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104877
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 260453
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160311
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 256719
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204828
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104203
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89813
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27138
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125279
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9641
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 238541
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 156195
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 226801
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 60855
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17830
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110537
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9915
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115866
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81773
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60940
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23817
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 155909
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 96300
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225576
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 319552
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138223
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 310253
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17561
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58067
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68567
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103148
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81786
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96335
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5377
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10507
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4896
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 131133
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8708
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98653
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16519
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49065
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17543
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118719
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77615
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58241
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86026
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10314
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12944
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 10975
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56316
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92544
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96705
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70824
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82935
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98441
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51310
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64008
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54017
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24565
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5156
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117386
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50754
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50844
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23349
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 237400
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86611
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120940
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45070
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170608
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91536
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 56477
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10234
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80372
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91249
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55108
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121584
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97758
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36145
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62221
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4168
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25103
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7102
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197932
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49782
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233105
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120475
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71693
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 213135
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86893
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120518
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61624
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86858
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15681
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9887
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118395
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50436
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81161
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160321
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177184
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62457
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15555
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140729
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133400
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12640
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80399
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 30965
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49901
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131761
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11473
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9605
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113636
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79979
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68877
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9934
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94960
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72104
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90100
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118322
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59243
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101037
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20167
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 178729
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 52286
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15290
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96366
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112296
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24577
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189161
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 146407
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104835
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223605
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74998
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33351
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29413
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11496
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33701
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102577
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6414
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47162
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62678
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81648
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12841
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56042
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63724
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172656
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58975
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32967
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146712
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104805
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 257901
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184063
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31725
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64773
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140071
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106150
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 244368
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70067
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108653
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 226914
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181637
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71451
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116014
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7109
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198678
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101857
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23411
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121197
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 142635
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 52131
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13261
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98052
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102849
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 139865
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23032
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76333
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12264
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71572
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 77555
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47964
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29068
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50698
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57481
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25831
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16880
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51785
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74151
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63603
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106427
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127315
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38488
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 263029
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13545
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234661
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62173
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 162991
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33081
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59478
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99759
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 160730
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114864
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84853
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26433
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20609
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150195
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131680
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51347
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120853
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36638
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165592
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80316
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104959
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 143744
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123597
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81321
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31779
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85172
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147211
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65215
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147808
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16658
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70995
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127259
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102780
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14117
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115078
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18058
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191969
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172987
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 232464
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117552
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28461
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31585
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9111
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94137
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38876
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196392
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116048
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134898
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 280952
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39184
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6267
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23697
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4884
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35079
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65087
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64128
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82576
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55381
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75373
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98118
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 44744
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68137
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50154
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 263987
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5345
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8807
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9376
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64815
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7603
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6897
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44916
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4543
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94651
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16092
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33131
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31579
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171244
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108811
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101147
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37419
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 66007
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73191
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14393
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 132063
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 154615
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97777
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37689
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6857
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61626
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180207
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112953
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229724
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93198
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125936
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92628
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4504
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89757
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20648
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10087
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69284
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65604
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80759
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123116
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86272
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9511
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111636
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91372
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11949
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138145
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69098
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43174
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110667
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92429
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28855
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119218
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 160539
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105036
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6994
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223053
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74901
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89451
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 95229
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83669
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 277042
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43382
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166433
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116582
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148276
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10140
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198424
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9154
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91598
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151842
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 101047
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73780
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88540
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97145
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107709
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26717
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54186
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13151
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62383
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48929
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132171
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11183
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11726
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111504
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25082
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75442
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48981
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25260
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71095
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139302
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21441
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174288
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91414
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108138
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20129
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78741
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97406
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 121961
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116106
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103959
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125847
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95586
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61192
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114549
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35958
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16827
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92675
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57814
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116685
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55310
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165315
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80659
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124408
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39942
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239501
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218492
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8882
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73306
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62361
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68831
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10649
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94217
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85654
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5011
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101160
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122940
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75557
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88456
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101857
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167015
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55761
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105802
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 75949
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77005
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63012
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143079
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112799
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95376
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103507
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85682
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 278705
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11239
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181751
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92262
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9311
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78052
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73322
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26592
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79179
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147691
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125068
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90052
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26416
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38234
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44661
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32369
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22186
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121638
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28085
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 271013
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27784
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 63365
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74065
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66249
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190426
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122544
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9233
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131259
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47733
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 62611
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86387
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150184
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62860
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82951
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171657
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217122
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119638
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78991
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105836
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40520
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 93878
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141768
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133514
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108657
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124495
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198292
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 236828
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 228183
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83671
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144891
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10261
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104385
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 266859
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47365
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91668
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18521
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38336
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62164
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16182
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76158
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66014
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106275
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72477
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71375
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8270
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73734
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233167
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78493
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54858
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20187
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76585
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107898
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60106
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57787
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71451
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35901
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77408
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32405
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5857
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104920
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74935
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15573
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65619
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122766
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54328
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 185904
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102355
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13655
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 103308
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149978
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44135
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27230
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76145
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131077
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92879
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59252
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89936
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136099
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79316
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132385
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 237991
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9670
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77746
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 41302
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57783
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70651
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 141900
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17440
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25461
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172469
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120615
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13249
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92358
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69096
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122287
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91961
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186984
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55932
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78753
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110262
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88231
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117691
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107450
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68772
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52213
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142667
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160289
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61276
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11022
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38603
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35415
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67795
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71926
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36250
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81006
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48945
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91553
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92961
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53281
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53705
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16953
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27442
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30083
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 148787
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75756
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62007
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20490
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107150
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63328
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93438
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64954
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42394
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18012
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24235
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62904
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49178
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124366
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83976
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6235
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48008
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93556
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19564
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74823
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63229
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204346
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118896
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145290
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87179
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128012
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118925
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150729
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16013
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96886
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65444
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 149281
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73911
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 129537
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24354
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50643
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15885
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85381
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73741
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119176
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82852
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153673
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68854
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77465
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13174
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 86062
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35496
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89473
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132013
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98811
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 175565
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8024
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57255
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54402
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136976
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34746
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94551
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152040
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31852
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94972
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80032
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79545
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138295
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103023
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72038
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150908
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7001
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20391
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7552
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76805
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61430
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70686
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208795
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162154
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157349
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80596
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 153553
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11013
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120491
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 310457
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113214
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48138
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32805
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98479
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91844
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26493
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39639
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124144
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172138
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44101
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 85938
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99994
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41200
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 198833
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 205156
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81485
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107675
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 151770
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 42124
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 65558
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109641
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63122
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 258227
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 292082
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28257
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89608
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133337
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127298
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60184
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57214
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158300
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10487
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125895
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15142
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 64523
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45662
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116115
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109049
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49807
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71130
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45075
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75833
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90426
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112320
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23946
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23770
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46211
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 133863
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5473
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48922
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41329
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55604
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 209438
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15460
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 253096
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76818
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8081
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129268
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66985
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95432
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102424
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112648
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32931
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93039
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6118
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82462
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105920
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10943
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28774
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71023
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24275
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100524
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79037
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151536
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76811
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84016
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82571
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115808
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65296
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 97969
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154192
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85770
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84133
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93999
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46481
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121249
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29399
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64461
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62529
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75943
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81886
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52474
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71030
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132599
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50944
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27450
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59903
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5708
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132820
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 269425
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119279
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 281830
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80167
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 166553
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73228
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 143212
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29798
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23363
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87027
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9161
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121937
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106395
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19435
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 174122
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6989
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14777
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35313
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77130
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27195
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189660
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50633
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116113
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72850
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127694
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51255
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18417
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95759
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77239
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72694
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60909
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68584
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 376611
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8375
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17317
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59240
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96085
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136783
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93053
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104437
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118579
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87910
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33225
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138714
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142349
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60718
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31946
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85712
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26964
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155577
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71889
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125370
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47532
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180148
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26966
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177898
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210161
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84192
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69532
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57652
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11622
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159502
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90357
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43832
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79074
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97462
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 106828
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67426
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117596
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90505
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75600
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18253
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13672
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74218
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90030
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184518
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10551
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 143752
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84320
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13179
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13025
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66101
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8998
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38042
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74438
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112033
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177853
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108023
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115821
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 302701
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203597
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120183
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219904
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71015
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109094
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92840
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34276
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113090
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100614
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70182
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80477
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11013
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65355
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67700
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195371
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45240
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93609
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 84732
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201366
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165002
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78387
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106532
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10484
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10154
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110133
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 109654
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128917
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105874
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247682
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81556
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110272
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37394
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102561
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20027
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98938
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120200
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191797
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115986
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 213304
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 117646
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67864
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 73747
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40521
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75814
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84957
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33676
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42786
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34265
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15714
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10883
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151843
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 161016
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182980
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63054
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92391
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117513
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114054
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25177
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31738
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5010
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 50317
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25325
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119731
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42390
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108782
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62236
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 137455
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76994
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101680
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62869
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67014
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23160
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81294
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20125
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30218
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24699
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165517
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15810
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55242
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 61563
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 130933
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44656
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27682
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129896
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20371
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74866
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19020
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48061
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55243
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22081
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68846
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10822
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18295
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20620
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73041
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59000
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18576
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9386
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12455
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8632
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6719
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60836
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96786
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104448
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 241445
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37353
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136275
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185300
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56937
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9407
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6474
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10094
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14703
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71599
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24979
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35646
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10131
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6675
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25071
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12065
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12634
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218521
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118842
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106653
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66497
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80060
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27274
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30560
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21622
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73677
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6284
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120192
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21151
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78979
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89793
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103935
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 149879
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15345
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107449
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12056
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70441
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97337
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 42117
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42321
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33009
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24722
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99066
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18911
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119232
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78616
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13628
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97168
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209816
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97060
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106723
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93951
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90840
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58015
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103700
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117064
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32428
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 44012
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87690
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110215
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9622
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40225
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106830
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69262
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209056
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27833
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13122
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 263799
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137689
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81119
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16109
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86473
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66315
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32629
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116703
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67877
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 267533
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70835
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149406
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80701
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8005
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61264
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98241
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 206026
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172916
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10418
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 281428
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81319
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6130
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78952
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53761
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98481
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 192581
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38753
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26815
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112594
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15639
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76286
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78031
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222173
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10919
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95587
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97052
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 243607
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21610
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55811
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210957
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47037
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120215
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72845
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 41128
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75863
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46162
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58149
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50515
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85192
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 200045
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104836
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53789
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11788
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245441
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 130705
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20489
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 271895
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59801
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 268013
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19586
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46969
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105370
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203489
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28823
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117263
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38667
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21914
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84932
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153930
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119810
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165325
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 369401
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107988
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239611
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69313
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90460
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106369
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 181944
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27115
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82996
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11131
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8179
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20164
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 45364
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242282
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89013
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55641
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146645
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31712
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94368
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83051
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148270
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125665
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9196
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86168
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136595
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 129166
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91119
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39332
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50405
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6815
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14058
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8420
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78825
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54562
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7220
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98398
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21159
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61004
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103567
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24160
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58996
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 144275
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66273
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 221599
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50483
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131456
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124729
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66741
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92445
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25081
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83816
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121555
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104661
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65483
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12764
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24288
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98459
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9534
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59681
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92191
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74709
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94946
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 419110
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13633
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20872
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152610
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31016
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66444
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3791
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134213
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69846
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10388
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133463
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108328
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 186495
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55656
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18421
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46606
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72464
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77755
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92255
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134492
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142554
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25406
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21738
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89678
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14372
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89452
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 39301
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13102
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76920
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 38634
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138275
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27562
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109440
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57089
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124457
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82035
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 6111
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73546
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100873
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128032
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97000
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11201
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22079
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7552
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 90159
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6978
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179524
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141329
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58796
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27747
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 79739
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73944
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111386
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8438
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169326
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163103
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129844
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 63797
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 135498
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 133435
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26117
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102545
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102490
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 109720
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76382
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6772
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69727
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116169
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 199467
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159577
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186376
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24538
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78760
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36146
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13537
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71194
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22801
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51111
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21501
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169035
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181562
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16025
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72913
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 151970
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32188
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144912
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119771
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 51592
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52947
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115522
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 77283
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299858
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215315
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29102
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92537
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27673
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7449
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12144
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93303
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13116
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 59018
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97510
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 59710
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119256
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120077
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92081
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107357
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30083
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179976
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 194596
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136050
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98346
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 158581
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57251
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109964
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 150349
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96884
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111597
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85412
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55616
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166161
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24045
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31237
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41335
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 198042
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 303465
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106284
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115773
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205469
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 240112
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24548
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 254766
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212838
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17544
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74627
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185123
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112078
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111043
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 201343
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48735
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49208
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17055
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 60902
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126587
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140218
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94267
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14215
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186256
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17486
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 253275
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121098
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153274
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146200
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 448421
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 192379
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189454
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97457
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154672
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11515
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153442
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211039
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26987
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129750
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157706
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101255
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170220
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90598
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23569
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15224
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19699
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 212860
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73378
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68161
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11030
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75644
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26712
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67789
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24019
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107811
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61565
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9457
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120796
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107598
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63074
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5222
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6610
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68595
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49789
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7130
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62463
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95000
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15047
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15700
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87503
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61525
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34018
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89194
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158129
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80073
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14459
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88788
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75730
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96111
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31542
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89207
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221197
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111398
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6823
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61395
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54205
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11498
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13404
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154115
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114982
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84039
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105118
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6641
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100333
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9702
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92834
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 140471
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26869
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68447
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100388
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69133
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73113
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 199019
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194548
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66259
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29609
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4285
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176274
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49489
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67573
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 214911
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124969
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 199822
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75200
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84452
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4629
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173131
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114398
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53816
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92368
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45484
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73136
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43901
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 193818
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133501
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22033
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53584
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56635
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74554
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6650
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5961
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78883
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50636
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121591
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 237638
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14441
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164246
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101752
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27074
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71016
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 175393
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223166
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123610
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25393
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 258948
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121926
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8265
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86973
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36012
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81493
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101290
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80913
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99950
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8267
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30254
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39631
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8725
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25978
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82528
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70852
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31326
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20686
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14404
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109446
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115366
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82274
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12661
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 164486
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41147
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231554
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126684
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 170429
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26746
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114821
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77932
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78625
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92245
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154058
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67028
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 310782
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97344
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122019
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29759
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96733
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37124
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91494
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124139
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75479
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 321624
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137252
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 102354
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134995
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33879
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 234442
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214247
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20910
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 41863
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 217874
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139533
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58585
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113519
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76388
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 44321
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 134131
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91598
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142420
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 51647
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83600
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100767
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38701
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60987
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31284
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188789
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76697
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12300
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 65938
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41434
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58928
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163432
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183201
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42987
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174776
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67900
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15181
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9148
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137130
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134475
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145014
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 122889
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220995
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68321
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22912
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129382
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 53391
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9156
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36971
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 127417
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 50310
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99233
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200065
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 319521
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95744
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97401
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197917
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144413
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122031
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76989
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108403
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149403
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 89269
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86249
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135493
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124573
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154775
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84130
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51506
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20512
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130852
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110301
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58436
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19330
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28882
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10322
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73309
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14539
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7277
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87468
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79527
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 88498
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46047
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62748
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9297
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119601
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72459
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71314
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80900
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77268
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62453
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63788
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31501
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84878
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80032
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25125
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103818
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174275
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18938
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157026
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103263
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86148
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77842
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50449
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45555
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52292
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100360
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10504
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106577
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65223
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98730
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53086
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118374
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 268787
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77404
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3552
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74602
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52247
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7350
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4992
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12098
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103013
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44140
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9979
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72366
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116166
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97405
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78539
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65822
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113865
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17654
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162672
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11359
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121219
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71257
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59924
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 70070
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99017
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36938
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 67850
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113307
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125414
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9747
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29155
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88975
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124405
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 139552
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108594
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26471
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14811
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23656
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90201
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60644
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118216
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68603
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9403
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75853
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90335
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24893
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154445
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38807
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144731
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31389
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49112
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82735
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55058
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85900
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83159
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 50273
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43253
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89909
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60951
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 45363
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59712
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195518
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73812
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11272
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34201
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45112
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38762
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 56083
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55639
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104716
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 315861
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87548
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164663
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4855
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 128220
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61544
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128440
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27719
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97883
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120717
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30961
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60768
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135728
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34595
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 244567
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131469
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130909
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136987
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166217
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52327
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47101
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120606
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38498
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114055
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60143
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87280
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 270574
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125713
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101325
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67181
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82682
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80167
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122403
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90453
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 116073
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84178
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33762
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79919
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24654
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132676
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106127
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29270
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289744
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90366
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99623
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150406
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89303
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120879
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52323
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69066
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57740
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113020
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55935
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116626
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14136
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18397
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79581
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 180356
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 56516
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75077
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 128285
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 382630
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103322
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114295
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 276094
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99347
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114400
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161939
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23538
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27522
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 126142
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104889
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190521
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133228
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66602
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44698
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104558
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81621
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110931
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159264
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195580
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65312
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96869
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45104
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121547
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55456
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93488
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7926
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88393
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55351
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 363767
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14676
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34703
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83933
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124243
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103209
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120913
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71006
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147931
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17777
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38587
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111023
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81479
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14689
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108785
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14026
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 207938
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86155
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70107
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10789
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54859
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12226
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148560
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12371
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21330
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166726
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122596
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115961
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 354245
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133230
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 298852
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107425
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132073
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59176
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106392
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47128
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 161290
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25418
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32325
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83470
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17923
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181004
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 47390
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32583
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94063
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116097
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77469
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93261
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65040
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73343
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28685
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14566
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159932
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104968
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8272
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160032
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60696
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7877
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93417
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70915
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61543
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18964
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76067
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74752
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116736
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58586
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70149
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9108
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3751
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29224
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64921
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12238
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107403
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62129
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198420
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44517
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158077
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53325
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92664
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108876
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29707
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74146
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68627
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23899
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25862
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75980
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83692
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52263
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158762
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 156147
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11443
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163470
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 215329
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259579
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 131716
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83511
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86359
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6963
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114049
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 112805
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92393
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4852
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98387
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165983
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136669
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 157142
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29593
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155055
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 199301
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164116
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95943
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60687
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89354
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9112
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78248
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103541
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88252
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4756
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106515
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239532
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76599
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234007
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85566
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123867
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24581
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56937
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 176102
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 62949
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75481
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15164
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40170
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159308
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41847
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20873
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 86768
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81205
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83036
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 59140
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72603
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137268
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100947
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 281029
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31971
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41081
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 3822
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179652
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197061
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62064
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33758
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99329
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91101
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112228
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22673
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62711
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 162575
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37210
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78553
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21627
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126611
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83992
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17086
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 414762
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14154
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91726
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 58689
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78117
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25008
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 270984
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114826
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85125
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115127
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37846
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131783
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133816
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72255
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85970
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207035
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24612
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169197
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118139
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81510
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111484
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51980
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19933
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7397
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 171383
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74149
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107950
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115629
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89783
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132827
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113550
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94573
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118232
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178270
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16062
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113892
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179971
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137209
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207710
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 83604
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10484
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40965
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67740
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10158
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14353
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74867
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125387
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 291583
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48586
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72514
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100849
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 268848
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129914
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98773
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111817
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46333
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 109537
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39446
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8652
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 106296
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 47379
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54076
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132499
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117855
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53821
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27368
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106797
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13591
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 114237
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13424
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 28203
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40780
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10518
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86489
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219264
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73757
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8496
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92866
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93461
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8204
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80772
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107912
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165519
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214542
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88418
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 246291
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51173
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25404
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 174572
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15270
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6207
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99707
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67243
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91539
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57129
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4252
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 51461
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68139
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69772
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181593
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63136
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88862
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 173272
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20459
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10285
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9941
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30301
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10305
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66142
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80611
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114287
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110491
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8133
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57221
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79856
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76738
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85538
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 273376
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127664
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38008
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75369
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28888
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 55900
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90306
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29557
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10236
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 292592
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68996
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50167
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110450
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 309967
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40360
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88819
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105838
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53857
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90831
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66216
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147110
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6672
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 65817
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14167
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67067
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16473
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58058
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7596
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18701
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47249
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79244
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187485
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70446
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110848
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6933
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110785
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71643
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 59769
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44920
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 324404
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78493
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77394
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169441
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117230
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6773
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6380
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26145
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 254480
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7545
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82347
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161815
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91753
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 45577
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124577
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87386
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58161
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65003
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48639
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79283
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32689
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 53493
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51641
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58598
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50550
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11990
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40937
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88501
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259624
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 215520
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126724
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100227
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20401
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87530
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89738
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 258523
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59761
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112810
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33158
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25060
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108875
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96482
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159237
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87512
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 121606
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127576
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119921
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227607
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119220
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67430
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26598
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74830
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109725
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69803
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34647
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231137
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115969
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71843
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80465
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120491
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40245
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86873
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104284
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107838
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74551
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9985
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140230
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28684
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19865
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40425
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14752
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167809
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78873
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26498
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15500
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21180
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 343356
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97102
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88104
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130912
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95301
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 82046
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71135
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59438
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108459
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71774
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68512
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81036
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 145952
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 64475
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174657
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10847
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39116
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120128
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 205661
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22016
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93042
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 106501
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211143
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 90023
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30538
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201505
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21386
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 111839
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217547
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72592
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52039
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41462
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216441
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6047
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 305547
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 86692
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140032
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112954
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155380
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139479
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147281
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 305571
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 82786
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 88144
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24962
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56985
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148172
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 57553
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74906
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 204162
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31271
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 295218
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194404
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21929
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174743
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138127
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73644
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 83313
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 63767
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92832
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24706
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45638
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222318
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21678
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54680
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 30533
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55237
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 69688
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 121377
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 8730
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 158433
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218908
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 158809
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203508
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 145143
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197342
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 271233
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 62289
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157454
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 254991
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 300634
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48448
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 57267
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116853
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82063
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59395
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16034
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17135
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 208561
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61980
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67609
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140377
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70788
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70971
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60412
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 124775
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79641
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27423
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227458
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8789
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46696
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 102475
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87491
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141386
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11521
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144551
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95900
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118560
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82072
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110200
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82192
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29220
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165281
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83846
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19035
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143409
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30958
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112897
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 330210
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94983
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91437
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16726
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27103
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13993
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107535
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61055
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 158487
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219037
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 52297
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95011
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94062
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66421
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108629
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104176
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221404
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99058
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132345
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60552
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 131489
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23891
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19236
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144721
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95105
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220268
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63328
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74931
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101884
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11352
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69520
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 140061
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61655
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103200
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72688
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23732
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69501
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13627
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81074
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13628
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17557
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 123459
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40129
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105951
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95749
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60205
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69382
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140758
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130289
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108112
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17289
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113097
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 41715
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56903
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10265
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55310
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120111
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 138336
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123479
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126764
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22246
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80828
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6785
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99263
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24550
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13594
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11650
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52223
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90014
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88052
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 61306
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31036
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75390
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18293
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17288
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119179
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85323
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88374
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44137
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124017
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 54928
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117585
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 246676
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57550
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19790
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242802
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110554
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74813
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44021
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80519
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208995
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209166
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33816
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 51900
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190144
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26161
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 262988
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165760
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10021
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 68937
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102076
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23804
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17154
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112424
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167189
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148361
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 137446
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8431
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14251
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56599
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120900
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 186224
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55138
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82399
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122641
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25398
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71250
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67622
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45721
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8793
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11789
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133406
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98010
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7907
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76803
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76169
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44434
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84821
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72046
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11528
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67315
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64650
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61384
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66674
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44088
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 140237
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15526
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 143379
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11146
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7293
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9811
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186093
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 48275
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66671
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25272
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 67454
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39150
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24661
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131736
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34127
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5149
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7409
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94905
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28845
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74522
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13699
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13034
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136932
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 156073
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67178
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61983
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6101
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32060
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71721
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61394
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76987
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18275
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13259
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171206
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12957
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11413
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101524
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12744
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75126
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72038
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 170167
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56321
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201326
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25051
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97054
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79574
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92660
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85043
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90720
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211074
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27040
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84504
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6267
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7281
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20583
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33272
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30429
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22455
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66980
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70624
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89175
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7843
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69863
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35864
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64808
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73952
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 143951
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73484
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109443
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99016
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 178452
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178855
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105882
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37377
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15789
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92540
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24176
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112807
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23135
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87883
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22603
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11427
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129854
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14061
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29539
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97915
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8153
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49049
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11426
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40656
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71901
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43209
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 284709
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27176
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193441
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77826
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153104
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 371091
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28161
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188907
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103172
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 69285
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14966
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87558
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101622
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63657
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33606
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93673
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84046
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27051
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65633
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25690
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91877
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225030
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52261
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93633
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141446
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137282
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34889
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22857
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109795
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105320
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11362
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25105
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29888
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82441
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18546
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25680
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19288
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31003
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235679
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23717
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 228939
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99172
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 160839
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109511
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17740
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27039
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113529
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69467
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83147
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44716
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 97487
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208978
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 266305
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54017
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9639
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11558
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67567
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117272
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120304
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107572
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231663
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 85827
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46410
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59726
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48914
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142050
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60278
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 68972
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20660
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75801
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94408
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14039
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 194394
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 171804
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185300
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80014
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80171
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92391
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157752
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6261
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90485
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152514
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172816
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23546
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8216
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115155
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78658
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 105228
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146303
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133638
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 172746
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170984
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24397
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42579
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 108209
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10415
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5255
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70814
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92845
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28041
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5401
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7359
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6255
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70528
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55309
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13341
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 126571
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17730
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31786
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 202391
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6718
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17218
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90331
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85368
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75888
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7206
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85826
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73689
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79846
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107570
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90490
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34526
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61646
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10245
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 131998
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13316
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85650
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106871
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39130
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91514
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101268
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98185
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78141
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46899
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99067
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149589
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99512
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91396
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55299
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66541
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64952
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6689
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203344
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6349
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211674
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68574
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118469
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70174
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109414
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10963
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80526
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51496
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50260
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86122
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28064
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59971
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84489
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10122
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76640
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 5692
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59465
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38106
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47958
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131062
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73657
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225921
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57559
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122836
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28893
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218584
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33928
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105870
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34630
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87824
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67201
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72236
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8785
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95167
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17291
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8639
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105557
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96802
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65352
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43277
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30544
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 114000
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152553
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92459
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117914
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 84956
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137190
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10100
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109551
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169946
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74344
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8103
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32855
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11257
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87899
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125659
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45884
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15958
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20631
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67752
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89244
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 154940
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 64625
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44133
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120938
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110988
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199001
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81693
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 64995
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29330
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100720
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99855
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183774
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65164
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108668
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 103722
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83395
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23451
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102453
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67461
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13271
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52826
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142702
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77463
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97451
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90324
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130160
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77487
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63022
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 103968
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140776
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74480
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55002
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83623
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83749
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15569
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88177
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112278
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 293821
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86368
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103851
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13790
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187284
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72698
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81205
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 100933
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76849
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114826
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162926
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111874
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81374
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104227
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 57500
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88309
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24851
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 106477
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172909
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14237
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14965
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34554
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10296
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65636
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59949
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54258
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67471
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78592
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123517
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82453
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97063
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11797
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131248
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19149
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214338
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38371
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73284
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 175219
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24659
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140480
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 191197
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 278968
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237718
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146699
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69727
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208675
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78461
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 10676
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 254439
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 406463
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31469
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 299637
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 206855
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22482
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106612
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227618
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108790
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242932
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 98743
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66307
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97055
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247147
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100414
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102054
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 111996
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 9611
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105161
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 101279
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45330
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 153203
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 263460
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219029
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 272966
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198253
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173199
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72228
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126687
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 34553
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27777
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157404
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127996
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120794
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46749
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106749
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148632
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203584
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42187
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142956
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105946
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76705
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109874
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 62639
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199752
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 66862
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20617
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179358
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 249462
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140601
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289256
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135947
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78094
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 296587
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108929
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 67696
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189202
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 198693
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 108072
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120786
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 272806
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 130643
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208909
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249055
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 68281
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 85926
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21426
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144184
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 156828
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21164
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 143890
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165771
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 121620
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148239
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197562
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 171760
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 319808
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 37871
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 179470
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18305
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 287827
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39543
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 47876
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13507
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87162
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7439
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97790
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5750
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53053
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74090
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78149
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20630
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60705
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57344
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69489
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50607
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10782
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133415
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56573
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73405
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93057
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6445
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24392
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4220
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4621
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65605
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15735
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28914
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94896
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15417
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109277
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15307
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74830
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71790
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150094
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9442
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 121055
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15413
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37509
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51357
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35656
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20468
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32790
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66132
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114960
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63744
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48804
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11079
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11226
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87590
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 164162
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251477
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84096
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24193
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56167
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56839
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20564
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38724
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26828
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76551
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136931
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 129335
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127621
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69425
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171709
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170030
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9261
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14047
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105440
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100671
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76511
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15627
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15983
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85721
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33820
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123555
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70678
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80531
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139766
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 184746
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89458
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95667
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11385
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94138
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59527
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6985
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 138973
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66304
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27055
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 59370
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169767
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107694
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126849
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92184
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 60897
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47912
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11174
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25134
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66229
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78710
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6574
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16677
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76592
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165967
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122494
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109907
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153259
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30223
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45990
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19607
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101031
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155753
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55999
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67550
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73322
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134190
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28586
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56260
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78588
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64696
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26806
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92218
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21954
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58632
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85341
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96263
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15304
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22918
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24021
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165878
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78262
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57741
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259651
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32628
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108642
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82684
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133083
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171190
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25885
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46434
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54169
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10716
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248490
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46245
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11709
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78408
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 99721
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59574
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62788
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84389
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83256
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53300
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88153
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10780
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 98129
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 74566
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49486
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152139
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57127
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76327
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60767
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127594
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119714
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 51966
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10865
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12538
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 158824
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90932
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9911
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12356
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9691
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4726
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86697
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98879
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86796
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50037
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8135
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12770
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147705
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12388
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55288
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77130
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98789
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34314
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9113
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78548
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21080
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29789
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89728
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 143421
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70899
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17059
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10996
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21331
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79003
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131113
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85365
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11911
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152552
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85983
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29132
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98551
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 308363
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85881
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10228
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5516
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19786
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114681
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55570
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 168689
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144122
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117153
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71781
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8996
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4965
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58013
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12616
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20050
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97877
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164888
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82779
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77464
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6923
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93765
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84574
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64623
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10933
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14479
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66099
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57227
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6589
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22067
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27810
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7803
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100277
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61721
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29898
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103853
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116346
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12513
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99426
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64269
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 61251
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210237
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104844
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85509
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58088
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97983
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186979
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14073
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11812
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74205
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 127150
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56237
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115018
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103011
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24279
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59740
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113776
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114776
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78620
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75679
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100327
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21481
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 64690
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37462
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190558
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 62410
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36484
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70122
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 164380
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101979
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112572
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 258063
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16312
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72205
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51950
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108232
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57024
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101478
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188265
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62782
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22778
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70765
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44436
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189757
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92187
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121917
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 28787
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104058
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147566
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 146446
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36179
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81754
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121031
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116112
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68037
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231876
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218372
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61259
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159959
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 424928
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102629
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216062
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8337
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212119
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90434
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100197
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15903
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 289693
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141811
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84742
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45569
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89711
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9012
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190037
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120559
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121389
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102727
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 342525
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157221
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167425
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95258
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135328
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 7395
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25049
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154232
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87773
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101036
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66397
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 255515
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100791
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76202
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20441
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111080
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29559
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 201095
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16659
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86025
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 240230
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160327
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13901
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97888
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13578
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15168
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163458
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 66806
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54034
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 147714
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111130
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17581
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7105
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111112
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208621
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 278967
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184552
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160183
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 259132
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154908
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191604
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79772
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61418
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222302
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147383
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 62181
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7396
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25086
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234143
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189374
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 168572
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211071
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28659
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210830
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 227732
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 88101
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44751
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 34792
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221847
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183518
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 349331
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 48966
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 204416
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168409
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 179352
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 309830
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211639
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25818
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37070
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21191
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50786
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 263990
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33819
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133116
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127343
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 106496
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255466
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 314500
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211748
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25080
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64866
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73759
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101279
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75264
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75323
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 243501
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72722
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22249
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 150249
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96898
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17938
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50811
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68545
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32213
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97958
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11262
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110512
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80818
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142802
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21090
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7664
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42899
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8897
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119672
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83993
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150871
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11182
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47352
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43450
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24447
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89840
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31007
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90228
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35451
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46018
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64843
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49060
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21800
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 288001
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136548
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 331249
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92250
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65807
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145262
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6768
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118794
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73298
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80267
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99649
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64127
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81173
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146399
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 122768
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88850
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11134
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46884
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143724
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 176714
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20446
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122465
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145266
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76975
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22123
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111951
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45649
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114009
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8494
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85424
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73456
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24446
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49103
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16579
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86281
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 106945
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110684
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107338
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51108
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75506
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96956
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120127
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111851
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6521
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53027
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6445
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91156
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99365
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67843
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84065
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28620
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133375
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115434
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100150
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4245
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118555
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85643
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93745
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86359
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50292
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141407
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101960
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92771
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33179
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67761
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113356
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92506
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71199
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122286
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174273
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 164922
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37803
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 74472
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91989
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81380
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151771
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126422
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67082
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29568
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93441
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23928
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143722
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13500
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90398
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101112
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28535
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5687
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106007
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68644
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69561
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65135
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48672
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85265
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 162385
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92301
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107137
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238420
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62544
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 238474
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180667
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69762
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 9681
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 52977
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 301517
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128241
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36619
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208934
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217734
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64189
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84336
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155421
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103832
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17458
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49154
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16211
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 129496
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10880
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8116
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25082
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33791
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18975
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68701
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158668
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 228982
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62277
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78543
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22686
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119034
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120183
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126761
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116428
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3376
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210506
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79764
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25308
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65349
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 127971
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177228
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73250
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15881
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10638
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9811
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73543
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123277
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6219
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98791
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136117
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93740
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6957
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 154070
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 101564
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 59114
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 178715
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10557
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112966
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 250815
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52985
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100351
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63727
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17344
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142175
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4880
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 2974
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89373
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93665
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30632
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139866
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11586
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82624
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11474
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 307665
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61485
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52060
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94292
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14634
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62089
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123274
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 358808
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48008
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53071
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 92645
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134312
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205989
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 154277
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108664
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96540
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101102
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21323
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14567
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89657
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11568
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124984
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15436
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89646
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101209
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86590
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97436
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83896
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15139
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81748
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18629
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78023
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196035
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39761
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54560
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78201
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64810
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25427
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69507
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90428
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50664
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89078
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62345
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184099
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101514
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 268530
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7554
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74365
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60014
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100013
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40079
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93641
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75577
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22452
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 159720
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82328
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 34679
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128276
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74338
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116122
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83078
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 173678
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175372
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46375
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90109
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67957
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15619
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60208
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79218
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57096
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 59520
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 294051
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71063
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88113
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10369
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107981
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 67591
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185740
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40665
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80700
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103271
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 114525
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50333
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178351
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146385
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99921
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10852
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207036
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33846
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 296674
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139141
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194170
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151656
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94175
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 114103
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92044
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89860
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31383
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99397
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 62453
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72668
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114609
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12293
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59512
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19726
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138094
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66297
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161720
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108882
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74782
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31099
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199573
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 335663
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 105010
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156063
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67564
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169220
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175270
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72099
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140598
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177729
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66421
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299858
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163915
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79387
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 41775
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176114
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71981
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123318
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40021
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 156281
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73135
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14709
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115223
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 264202
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92266
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81348
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99209
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 29409
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8872
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175175
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72391
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98157
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 5448
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85186
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 140614
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 144201
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234064
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227896
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 36348
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74145
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127821
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 137602
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 292068
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 43568
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 232556
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 88188
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154902
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105194
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68212
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35795
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 178721
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46415
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124861
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103870
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189743
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218270
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 336510
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172872
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25158
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102143
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 155010
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 275563
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63271
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186060
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147711
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 293253
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144930
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23179
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17314
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 328945
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209152
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165525
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107893
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 143045
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147531
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 495709
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113734
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 78196
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159797
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230048
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 125945
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242663
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 75713
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18141
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 552251
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25285
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130432
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147596
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 228729
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162035
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247041
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169328
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 253385
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 167145
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 283068
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 355027
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185948
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 9737
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 86640
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35832
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85953
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48336
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20176
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15615
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94776
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10015
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7206
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22830
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58045
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13577
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63509
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18174
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9574
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96818
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72533
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119008
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57064
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116098
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107402
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48370
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154900
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25218
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9442
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7297
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64601
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 198338
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70480
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69889
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29602
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 249181
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49115
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95155
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12278
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6676
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147774
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60966
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80261
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151769
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52114
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87841
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 220302
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36226
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 41709
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131316
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111964
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 188321
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 38527
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19861
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9892
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7787
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65981
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 275687
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21685
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75295
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55563
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9871
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178641
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7671
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 212886
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116599
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64854
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180762
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122057
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25881
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56264
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55897
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91193
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 2926
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109081
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96227
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 122212
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82189
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104278
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96679
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191890
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9708
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70904
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160493
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87429
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130826
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6549
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102783
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93887
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105613
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 301128
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46864
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54854
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102739
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 63962
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25063
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131757
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79419
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98855
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56380
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79785
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126002
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134640
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95496
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 60686
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 55871
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 159904
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88912
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51935
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69714
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168310
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31227
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104137
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94680
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58251
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53657
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95543
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192705
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74671
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167548
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73599
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 76858
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111712
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212021
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89791
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163964
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144695
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99007
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29428
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 277484
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232509
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140761
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83715
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87287
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8735
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93309
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196165
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42808
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15780
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 108901
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55634
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227167
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15810
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83945
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92210
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103950
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93599
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84091
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69387
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86034
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97101
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116955
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 51356
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88851
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51285
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80256
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69996
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117675
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18992
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31128
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221539
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24463
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54281
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78371
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8604
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60006
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47940
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5107
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56265
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59805
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67067
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103115
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70890
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80165
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 127904
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20909
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137234
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102940
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46311
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23841
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98841
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31460
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18252
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6171
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 246899
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82320
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33064
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47579
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55443
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57912
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86840
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89916
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26157
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121811
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98584
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36742
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189646
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22301
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107754
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23342
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 214154
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 141510
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137251
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120197
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81277
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20665
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93068
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82471
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86993
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35683
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11575
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69839
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12307
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108240
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141099
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51975
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16653
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82776
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101221
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66449
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181364
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80314
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77879
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83677
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122145
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4816
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72608
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 270031
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118422
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71523
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 59525
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124944
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81538
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22421
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109571
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54985
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58180
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117851
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78387
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88829
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114624
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139189
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4644
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39225
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75858
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7826
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78604
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114557
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96347
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79601
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77139
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60347
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96447
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99731
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78707
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18941
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74067
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57213
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7525
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77089
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91649
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64337
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19079
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89469
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53362
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72424
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12822
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195028
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27842
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72240
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79753
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183109
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100256
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172963
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112874
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9613
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113830
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117862
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178124
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10247
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99137
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116197
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76261
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158517
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137485
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 123651
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11577
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17103
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121903
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 44401
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27030
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 150264
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171695
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113768
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248808
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164022
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124470
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54891
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86412
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31863
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 43565
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63834
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75471
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34820
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95849
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10430
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73256
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70280
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26724
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81643
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 154569
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66074
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134630
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82977
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16397
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129191
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69090
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124231
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70875
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 56114
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34704
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94331
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259066
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56630
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106035
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140862
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 300927
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77416
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 315821
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57029
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25198
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17314
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12265
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70919
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6746
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72423
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118686
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57192
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123735
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77090
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 142946
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95860
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151128
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 141282
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112059
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 264247
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10274
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45178
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43710
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38098
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90288
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104700
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21286
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104464
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37409
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16585
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 179800
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136212
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 76734
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 142403
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 308314
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 136007
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124110
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185132
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101012
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137520
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89020
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 140321
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59036
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 173671
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17530
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9397
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61987
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74502
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31015
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 248923
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 142667
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 357684
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214113
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 281715
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183964
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46113
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40616
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230998
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106062
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229913
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265768
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219899
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42915
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181112
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235960
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 282658
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 6873
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 132363
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 280518
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200133
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211776
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9699
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 51724
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 61909
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 313474
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 274863
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 3976
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136525
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188271
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 270852
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 75443
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 236521
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7696
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6145
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60903
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10635
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25224
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11964
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8571
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108989
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46695
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44609
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 44274
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79817
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 69198
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11300
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74636
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63432
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12106
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80447
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 78889
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102578
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23393
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26674
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102621
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209231
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12810
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12806
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73043
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128713
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45614
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54542
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10015
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6932
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9171
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135341
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8186
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11271
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69409
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 157818
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75079
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58698
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104954
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99604
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14113
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60275
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80237
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35268
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126128
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24634
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76244
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 62406
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50751
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5919
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69266
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93071
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49557
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58900
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29008
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83718
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10165
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 72323
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 69619
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144595
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63680
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8721
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 318170
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6971
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 121863
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88669
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104881
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66519
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135273
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12442
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83260
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32677
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8475
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73211
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72267
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26206
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 277391
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66359
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31265
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80592
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61919
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5660
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 57788
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45128
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6495
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30691
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120528
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147396
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 67377
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13324
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13400
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94593
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50942
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75077
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81455
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82080
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33602
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 30189
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10355
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36306
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114785
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9335
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111386
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4068
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202414
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100850
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108866
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77168
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41375
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 238241
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83685
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74599
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139859
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 275149
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164330
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23375
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74274
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150536
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47901
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8070
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10881
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64202
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100830
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106045
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69813
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115421
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108317
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212502
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107346
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66694
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99265
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135252
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 5438
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22165
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100772
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 343961
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81963
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15166
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174760
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84183
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76863
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115778
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51160
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107619
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179900
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110132
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219119
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138068
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37910
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18655
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98864
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132549
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114712
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51845
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7159
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 271159
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 295115
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137007
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132151
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15057
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208272
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48100
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 30994
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100400
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62562
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19887
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 41920
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42250
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26893
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 297731
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 280457
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58216
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25522
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 165726
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157013
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 83909
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78804
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124383
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223910
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24544
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88857
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200075
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193485
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227719
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 99328
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 207370
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17462
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 87242
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18923
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229615
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18537
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 57552
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 132842
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187091
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 257365
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 60857
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61932
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25278
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 63598
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132470
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35014
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10012
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26852
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 154185
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71691
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57794
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 139944
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69982
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20705
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84259
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 180762
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23774
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84363
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6618
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8427
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16968
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10527
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5516
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127120
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93947
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9438
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29992
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31812
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7436
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81567
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10805
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75077
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62873
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10561
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9538
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 311632
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26373
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75354
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123979
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132777
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13321
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106447
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22937
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59558
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99041
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123272
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43452
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10357
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82618
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150933
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75465
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168300
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21146
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98080
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 180402
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12063
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11112
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79106
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52751
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131713
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80400
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64753
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52532
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 218857
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9183
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91524
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58375
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105405
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4854
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103921
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155699
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 232835
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 84379
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11779
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 38322
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 149395
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80609
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114012
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28606
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78351
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181361
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138242
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113950
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 261113
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16136
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 13020
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66091
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34919
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 169615
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74504
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95057
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73487
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120730
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122060
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113577
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61723
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265563
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117695
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75202
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 59284
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9884
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106146
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60682
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61899
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113483
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92460
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103825
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10515
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45453
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32444
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113284
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26149
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25578
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69267
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69307
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203446
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90563
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20832
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141114
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35147
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125085
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187167
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53465
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86145
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32482
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 96769
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84988
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101847
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 105604
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 193455
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96287
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 133544
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29536
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16549
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21707
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 47981
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81028
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77974
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32264
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 234078
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15971
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52089
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94087
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9768
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9518
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33989
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100287
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161836
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15180
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44449
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25944
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206119
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80094
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140443
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86158
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 64002
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97316
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152744
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29060
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 64616
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197647
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233598
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144382
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 59690
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72154
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162008
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60083
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29448
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111522
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18202
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 244152
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 150682
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11864
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 6766
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119557
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 147187
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251746
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105879
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66650
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 273465
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102732
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 66703
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22087
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107033
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17151
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36919
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92899
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86932
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101969
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118501
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 258246
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4254
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 127114
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 51082
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 175722
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 68254
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108883
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12168
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153964
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208716
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97986
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130202
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21983
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30371
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60304
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40925
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26486
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 176250
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82502
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 185658
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28188
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54871
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204862
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87615
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158353
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205165
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12682
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12182
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125643
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84945
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118488
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134784
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107555
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76407
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76353
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98718
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80608
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238582
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217350
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10566
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 97846
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123387
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87067
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45789
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109827
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78988
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64109
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4549
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106789
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154708
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 353485
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111941
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 15972
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22093
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151323
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182726
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 326200
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97869
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184991
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155865
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119524
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16861
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230548
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151365
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 137906
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28380
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 130511
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 107213
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159480
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 160558
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194075
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52908
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 272688
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 102514
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163015
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42737
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118890
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 143740
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53138
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126170
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 53173
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140071
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230653
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189198
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 392648
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 210212
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52658
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18820
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 78897
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 85978
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 113613
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251876
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140280
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31439
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52266
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162803
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23537
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 270316
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4803
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4268
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77886
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66026
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12890
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40589
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67669
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 146073
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89607
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10837
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6871
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75876
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19070
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49363
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134351
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189943
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52911
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85183
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 105397
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74152
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21498
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14183
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146007
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88535
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103892
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12652
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33970
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15033
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73156
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22903
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64466
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59239
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101916
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60684
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95002
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83917
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17215
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33976
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 178193
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66170
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193208
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6318
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69988
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 266936
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83008
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76081
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77555
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60741
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80842
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91067
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23682
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16434
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108294
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9194
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76573
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19109
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6982
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 162637
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124759
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10180
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78353
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80489
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6416
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 312976
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7057
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163253
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5524
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172576
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110798
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71873
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55599
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87019
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34887
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101096
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77404
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86629
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74155
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68113
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84232
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 252738
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95378
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69617
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96557
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127717
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 135582
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55729
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75318
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141113
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115852
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131698
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125520
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156521
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130834
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16752
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27410
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231790
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235924
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132913
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65957
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 141310
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108190
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44246
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67516
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199255
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7166
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5958
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201752
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 144006
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43377
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97044
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170606
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228418
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167680
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91540
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86834
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 84154
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18936
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3796
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165670
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130946
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84490
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11776
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95511
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51878
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93058
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125165
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 309853
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62905
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82777
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97900
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 110710
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92117
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 59963
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12201
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179949
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55400
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 272394
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148253
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14563
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89574
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47101
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51727
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25971
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84332
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144221
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115177
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144353
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13398
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36620
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33168
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 51286
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212054
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185479
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109196
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208339
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183326
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177489
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102766
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180905
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23983
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 83833
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 311637
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 288433
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 84622
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 21412
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 212006
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178448
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172054
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11438
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148824
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 267296
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 250740
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112871
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149469
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 90796
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 268772
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 244226
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 153389
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 192151
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 300472
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157389
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 297948
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102814
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44864
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 152651
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50335
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 256754
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 239884
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 268506
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18030
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237204
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28283
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233138
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28880
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63001
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69596
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16136
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 269131
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44966
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161671
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 337528
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41255
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96509
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112612
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50690
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5177
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94997
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63868
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 264260
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223360
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8416
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103913
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55190
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 134425
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 194619
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12303
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11534
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31018
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 84365
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108388
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29385
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124202
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 45675
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169736
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67912
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90239
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79933
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126305
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4777
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 130398
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 150437
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18924
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52185
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87453
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91785
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109950
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26354
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5247
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198789
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144674
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116230
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95228
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 62995
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32843
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75600
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128564
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99709
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79830
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99604
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138757
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13871
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 89582
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58622
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59339
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80951
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11350
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82347
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119370
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28316
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 83902
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11264
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5133
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58142
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92009
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32520
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36536
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80614
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18472
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125362
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45493
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122193
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54956
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88216
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131354
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74012
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21036
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133562
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68510
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 202882
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56510
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130539
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15633
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59280
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35352
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85203
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20792
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102321
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 110102
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77034
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 57379
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18653
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22238
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56943
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125904
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69815
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17926
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69908
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149343
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 279992
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79241
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86816
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155152
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13667
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 111932
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18824
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102384
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52280
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44481
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48699
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139396
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69521
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23520
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84794
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111316
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63350
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93453
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65377
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9521
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76186
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6822
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4530
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61558
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 178674
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96671
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69596
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120789
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35256
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18973
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12826
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68643
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95425
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78393
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6387
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155444
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47109
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5359
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158204
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109523
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188270
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51783
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6452
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189875
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94710
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84894
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11010
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86108
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68575
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19974
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64095
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48055
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75769
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15092
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161009
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 336500
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169806
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149420
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13037
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58459
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19281
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71708
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52615
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178173
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68908
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66798
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34564
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88950
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 260086
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68613
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81785
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70154
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104821
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7796
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87366
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 691616
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125760
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87116
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92616
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62344
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88629
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 84974
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13838
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142780
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65826
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75943
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72437
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34998
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98636
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68356
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 163356
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113410
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92311
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99460
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8046
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19006
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138395
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71843
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31228
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42497
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103850
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179899
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90181
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155449
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6247
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37422
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95140
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 87140
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33768
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81203
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152736
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 127434
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84773
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67317
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22528
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59036
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70298
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121104
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66664
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159057
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81202
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11148
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15229
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121010
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113201
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119567
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18848
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231830
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55389
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62406
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77024
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120168
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56876
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133731
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193131
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41089
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16733
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19662
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 133787
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 59546
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73712
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20585
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265615
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 75389
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8721
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76915
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162122
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125563
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136512
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16463
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176572
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62117
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134714
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22959
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11767
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36472
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92180
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121020
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8865
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68337
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125636
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66869
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148299
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 461112
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20188
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96368
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167318
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111856
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5823
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227456
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174273
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147158
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12091
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100881
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116463
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121883
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67338
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146108
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165997
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 69837
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84487
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235828
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224616
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 329098
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13391
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61083
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54132
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102070
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 75162
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211051
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 164906
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27414
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215886
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 73593
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98182
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176499
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 179325
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 111336
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 36103
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32290
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 289016
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116045
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 328777
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 119298
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236837
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154392
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64859
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116370
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35935
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 193220
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255826
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 126689
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8124
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120788
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 91836
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 204979
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 439677
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242792
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140166
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209871
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157408
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 150783
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 114252
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198953
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 124728
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 9904
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17703
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93000
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98370
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17695
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86942
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 43330
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 126226
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59349
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15266
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31207
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19128
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91028
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101016
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9192
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112415
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28470
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76107
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7870
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95567
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65650
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73459
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15400
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83513
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54070
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96719
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69405
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18773
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30053
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67265
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46472
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82898
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130864
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33693
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105993
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184845
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82772
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83580
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149413
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75886
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67734
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136727
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47476
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83845
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 11488
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22723
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95280
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 54642
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115961
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9076
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11175
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175532
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227809
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118911
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95013
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82404
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75006
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 234345
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114790
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53167
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 148275
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82990
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82645
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14973
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144746
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18609
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22209
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169433
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135732
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12181
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210961
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 160191
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78992
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195520
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85205
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98189
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143972
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20452
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169839
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141527
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179989
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31990
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216685
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 39436
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9970
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 317074
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70673
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89196
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81417
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130737
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24390
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102160
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44108
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57683
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47124
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91361
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14159
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25865
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80668
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67065
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9443
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78548
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160228
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90644
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194971
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101649
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70485
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119782
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190971
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54914
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135845
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6571
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29015
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18001
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 70461
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82083
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156473
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72296
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 209811
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32130
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138961
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153193
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113767
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79409
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87441
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105336
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169852
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117551
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130270
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201737
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182770
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97816
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111406
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61316
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195018
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139678
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171204
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34958
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169841
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198581
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 292764
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 63912
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65016
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108231
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107953
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27333
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197023
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82006
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66156
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75698
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 220397
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102655
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80333
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17320
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24412
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36793
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 54918
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80470
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107103
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95414
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53617
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24940
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85544
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 119363
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88896
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 277232
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92466
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86011
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 193977
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201063
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77536
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154205
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 198903
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126054
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98550
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25178
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9280
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76701
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74450
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 174919
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77343
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102130
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23682
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11068
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7941
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39111
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70994
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19996
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71236
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44744
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18019
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84479
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220111
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48532
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69006
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67817
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116042
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19161
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41605
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24830
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 149242
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5741
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12504
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76356
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86664
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12686
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118658
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53501
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95282
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54606
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173024
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 169312
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10943
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7866
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 10877
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69639
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29851
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 129372
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18261
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105236
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59203
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87723
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 48282
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91004
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132162
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131657
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63084
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4718
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60062
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143364
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51706
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85780
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102018
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70213
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246247
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64851
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60478
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40500
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118451
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149140
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24842
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77099
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55069
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79168
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7066
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95487
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90943
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70275
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154195
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 50262
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 100849
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35365
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265188
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20186
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 51838
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87846
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 292863
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118512
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16776
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195286
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50651
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82548
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47077
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 62684
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7762
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 58443
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72669
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 220962
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211580
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74847
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96022
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77125
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209821
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 48715
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93761
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77669
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165830
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132739
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120627
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198003
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61373
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99318
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 81935
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39352
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118989
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70724
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23212
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80119
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47943
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75387
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94228
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7997
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26779
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58974
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148075
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67910
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14658
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101456
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225439
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78729
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7994
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 241018
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180928
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52565
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127158
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56743
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26571
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90527
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23577
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151465
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106987
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 270490
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236618
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155772
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114893
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20784
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131775
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95247
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 71664
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 59195
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150548
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 221002
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139638
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17121
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20819
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103483
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113710
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17658
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76310
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186338
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 165016
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18025
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145267
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91266
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18766
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92955
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8318
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 215384
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25711
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169180
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95892
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89997
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116608
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113548
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64857
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 267688
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38805
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 48076
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151755
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217979
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30141
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32677
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36255
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 543727
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 165151
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69055
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50388
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14252
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163346
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83080
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109229
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111324
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127471
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23028
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 194983
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183550
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 178932
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 79954
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157160
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289304
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 49423
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203618
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192541
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 182914
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189028
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40785
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115884
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149065
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151132
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 66867
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156829
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27138
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 78098
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 221026
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 264863
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211331
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179320
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 19689
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 127742
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233299
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49035
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71431
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66963
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96399
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170402
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121244
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67737
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 70674
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 100857
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113530
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76834
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156864
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16170
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 203394
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 198906
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68597
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 130801
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133116
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17672
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 293703
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107363
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6676
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 67690
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130538
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54934
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 246463
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76688
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 57892
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 162767
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187431
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157172
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 344871
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120834
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71542
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99362
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 344096
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109667
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 306579
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 275662
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195421
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7960
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159315
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99345
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49872
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177457
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141298
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167665
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126450
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 282626
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 180020
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55799
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 84077
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17148
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 113667
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55874
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18696
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251318
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14253
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 256885
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 85439
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 79831
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186262
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70665
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248415
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186983
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 290656
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 99227
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 35354
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25581
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 70260
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24226
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 346006
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235691
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 266697
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 275415
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 150918
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16920
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214095
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54259
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27266
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84222
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 156586
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118619
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50809
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82958
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65227
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101627
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46021
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123163
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15594
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100312
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82920
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 153719
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15879
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96359
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42022
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62868
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7546
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24696
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6962
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53596
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232394
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7458
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 41575
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157565
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14769
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178502
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83998
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76882
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 132573
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90388
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61233
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169561
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32898
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198267
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114999
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70007
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101382
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121969
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31020
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130155
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15739
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27701
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6412
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153075
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131853
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19550
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66570
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142088
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171383
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160978
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80576
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55087
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75632
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40948
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38390
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71670
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86621
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44985
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8913
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116344
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14701
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35564
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111196
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6946
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26957
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 240574
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6883
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146265
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63774
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94497
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69388
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69237
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160444
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71725
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57867
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94115
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236357
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163554
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58142
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61709
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117815
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55004
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55005
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78899
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72335
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70992
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14139
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110646
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53439
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 263897
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90020
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 157412
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 37151
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26026
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122432
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115039
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85732
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19946
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11506
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94747
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195489
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32266
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7186
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103936
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229054
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111302
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65225
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12468
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 60274
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29946
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4974
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110573
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69969
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75053
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 146644
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46563
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86945
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127935
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64129
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10034
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81397
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131735
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101280
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160175
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51399
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112510
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247674
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 244807
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90274
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112886
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180874
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 39939
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 349412
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34190
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36359
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4183
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 134855
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9692
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8195
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20461
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73839
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92558
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153883
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91619
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130195
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65449
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88322
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50623
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23127
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122693
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40390
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7935
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14283
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104004
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66016
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 325102
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27273
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77425
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14666
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68034
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51306
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83155
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161688
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137437
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156331
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66449
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175293
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21436
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59680
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84965
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53958
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63188
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 84099
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145253
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89970
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22602
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116606
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86788
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116547
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79706
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93124
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143606
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18970
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86269
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79768
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92627
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 280813
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18881
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71660
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154225
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 189640
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77698
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118055
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79071
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62436
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116420
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214366
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3811
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97638
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136633
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9487
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175096
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95776
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 204416
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 198233
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 164677
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11736
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190593
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 132339
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78967
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31923
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183073
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5651
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20040
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143738
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127570
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 259919
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210167
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57066
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18989
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143371
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 260794
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45150
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20024
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71625
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55097
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 54721
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106926
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 250275
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63280
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36636
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 241350
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39396
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26591
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 263251
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 213142
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142381
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238053
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134164
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100235
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136473
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 63237
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 292772
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121410
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17730
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102201
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220948
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17336
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37393
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 56848
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109281
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219693
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105893
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80072
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 48403
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 258162
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 69925
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159627
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 169070
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 32911
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77421
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104977
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199974
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18305
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 60693
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163215
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187590
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76538
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204211
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 136400
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 352555
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17580
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231127
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 94538
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12931
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 352780
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 74017
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 61131
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48758
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22815
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 212827
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185088
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181681
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50072
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 156858
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 287430
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59781
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27523
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106714
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53557
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58716
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76521
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53683
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53189
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50346
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78865
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65510
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62662
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7374
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166840
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5348
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79184
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91640
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91169
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6894
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 145248
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82946
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50120
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25938
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61213
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49447
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35066
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182645
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29428
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46874
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10723
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55934
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7298
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62040
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73153
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110727
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21209
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6252
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39864
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52117
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20715
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29226
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77191
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56241
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196049
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15655
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33582
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187105
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226700
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29630
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155568
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73400
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33146
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11695
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10144
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152965
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255998
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124108
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19807
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144320
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25340
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91292
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 102630
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22606
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174566
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68027
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 60335
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218196
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10515
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 284607
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11316
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 153665
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146648
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24994
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157870
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180447
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12758
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143977
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138101
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52224
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101349
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139223
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113914
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13400
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31081
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179214
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 293053
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22483
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151806
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72596
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193339
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 253335
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149278
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 241081
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 319651
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30115
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155673
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 96098
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 303661
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22196
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87245
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19819
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192890
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 224103
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24080
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 34472
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177529
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84718
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6422
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23851
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63735
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92345
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12840
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87475
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149450
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79727
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24965
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24278
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54035
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15205
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69004
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 255250
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138669
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67611
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5705
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85955
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14069
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93047
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181380
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28311
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86254
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92623
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13086
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29234
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 150285
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67622
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125501
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 329208
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82334
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30892
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122032
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86283
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54073
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 225475
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 56149
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155715
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11881
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80269
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 209568
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101469
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67747
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 47787
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6651
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 62388
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23714
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98902
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83577
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112558
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91043
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28908
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7013
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7257
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13113
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 41041
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48908
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40597
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15455
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62182
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111533
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89635
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27651
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69297
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181702
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87230
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23273
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19074
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37126
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50638
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20407
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113809
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64557
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32768
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78058
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96620
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82924
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14888
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15568
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35571
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125620
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63212
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 43966
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 121263
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 131074
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143019
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64219
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81376
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64843
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132408
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112980
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17008
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 144443
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175415
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48869
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 144779
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125712
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168202
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78287
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 88719
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36380
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 38915
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102449
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 112801
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128055
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60025
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113364
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 262014
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 209937
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54166
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29412
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91268
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12348
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140618
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 267610
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116673
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 55201
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118710
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85893
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74935
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90040
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100579
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103852
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39028
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151035
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123130
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87806
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27768
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86044
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53463
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51908
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147981
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109589
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52680
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86731
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126670
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138556
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65199
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 178804
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 80822
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95299
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46742
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26645
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 41948
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 252380
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132418
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32973
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74092
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87110
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 57130
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5752
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94985
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143005
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259230
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24642
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 393960
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228025
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130058
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22450
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117364
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181093
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122744
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56211
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115195
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 77274
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42404
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162747
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 110332
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62602
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31572
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88373
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117065
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189265
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121645
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 329857
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138842
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195253
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50440
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 118525
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 271220
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176963
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 239531
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17781
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24675
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 123293
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92752
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179359
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 112564
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 212030
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54907
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20071
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 198630
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 296590
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 99198
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197946
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 102875
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34797
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217135
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233165
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 112157
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142020
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 270429
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56343
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 139213
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126384
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19025
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74557
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 136442
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61402
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26542
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38782
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151255
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 63857
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26293
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7227
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53298
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28571
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8292
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87812
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11624
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112500
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76720
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72603
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 197996
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 58768
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 151699
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18870
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80682
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26371
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114302
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41818
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84741
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115658
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101728
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92062
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 264238
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62632
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47795
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27380
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54458
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59401
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177358
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 123670
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146073
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115086
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83242
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96854
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45024
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90005
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8230
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4838
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98479
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61320
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71504
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117447
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 56815
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112768
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30873
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130230
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11249
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106599
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156462
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24724
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8904
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12303
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84065
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92378
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74199
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 203362
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86283
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67076
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129030
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24760
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93799
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65160
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13155
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 65259
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29441
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27681
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10373
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14272
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115749
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54355
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78216
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193502
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 59928
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 70604
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78003
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127839
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62100
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62361
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143005
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150735
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189257
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59127
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32008
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136593
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15110
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146703
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35050
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85959
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124947
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7376
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35570
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19870
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100963
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18016
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132677
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179701
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164425
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203582
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69618
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153879
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83045
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77291
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184323
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89545
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179677
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127546
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78883
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 252346
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61196
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165864
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157686
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26430
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74630
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96206
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 353160
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103654
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247878
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86423
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227699
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126300
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 65110
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102345
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96103
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34001
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57098
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104649
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 281032
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186201
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66125
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 56412
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92109
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203314
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32151
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27468
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222571
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 67203
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153867
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110860
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95155
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116259
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83618
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158248
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211349
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6318
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93662
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 154047
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5944
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90553
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54750
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8646
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91476
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90357
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 343137
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101218
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 73105
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33174
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103068
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92942
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89643
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 198132
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79084
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75849
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9628
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187536
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 233575
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225954
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 58792
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 73996
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99403
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96157
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120744
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166839
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116681
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 105297
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74903
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74480
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93118
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178093
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156323
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220041
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99366
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116869
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68929
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136725
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 30240
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27592
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203661
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46949
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 410715
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128102
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186667
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42337
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98490
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235116
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68699
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58626
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55043
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 433794
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 130067
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17606
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66218
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164409
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69332
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7725
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39811
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142795
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218425
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74951
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115339
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47619
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 357948
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218377
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26051
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48679
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135066
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 305456
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33504
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120849
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44370
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183860
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25202
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172287
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176072
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7634
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 133723
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242721
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 306941
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216453
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 37541
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 141002
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 236624
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 85651
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19214
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61942
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59593
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15459
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6386
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15633
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86883
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 167175
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163961
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25269
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110933
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93142
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10261
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33015
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179962
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115106
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91582
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56571
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8152
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24698
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 131483
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20833
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138592
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124934
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82333
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176827
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85476
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61841
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95200
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26008
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85322
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113708
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86028
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102155
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30880
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12492
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134892
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90261
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40964
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43901
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 233003
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185180
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 127886
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54409
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 205277
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55118
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10508
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4891
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25627
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82629
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73773
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10058
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19634
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110703
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92302
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76015
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107746
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64464
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20946
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93545
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125019
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121519
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68528
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72509
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139468
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88940
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78065
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6104
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 147568
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 324704
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 55114
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45514
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15768
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90872
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60989
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64130
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40946
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111031
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21164
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107569
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73002
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42664
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126481
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54029
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77429
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80670
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32707
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132398
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64496
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85112
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121159
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122977
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87356
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107356
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107647
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26926
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105694
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158466
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121532
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69271
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81306
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81150
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57199
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97337
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181056
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89895
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 94616
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130822
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12208
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65525
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106468
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10588
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107971
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 61034
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60141
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60661
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21682
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43844
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129742
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69201
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53812
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139357
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164107
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14060
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67263
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50071
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27537
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 62037
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39653
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128091
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42580
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11367
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123746
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72532
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6347
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33947
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118170
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7160
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78583
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112684
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167779
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8643
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18644
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144764
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114077
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168742
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24255
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6362
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90385
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53111
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133257
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73515
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135109
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135503
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127260
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48771
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226632
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82491
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6221
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77965
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15089
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73870
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19927
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9762
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75047
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79803
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72790
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141486
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247635
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168749
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236860
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229341
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 48154
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119502
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 95228
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186774
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132080
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195088
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98623
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29930
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95957
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25502
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131632
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39914
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29420
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200206
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117983
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71226
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 238329
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38649
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 257885
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61854
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22192
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90663
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153191
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133455
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202931
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83732
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25332
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44023
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 142171
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 399577
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19877
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156142
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52872
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 161085
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 390070
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99866
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88215
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 310352
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108662
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242140
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185491
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234907
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24148
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 136462
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 308213
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55370
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205866
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95228
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148536
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 183367
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156643
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63128
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50608
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 202724
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 171819
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229538
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154318
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 286781
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 261083
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50158
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 106958
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 10118
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251774
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 264997
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 108077
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 277599
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246564
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 69677
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 291956
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212841
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155034
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191939
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39443
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 233451
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 127575
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 79904
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51357
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118724
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105564
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48528
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117993
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88697
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 107605
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67930
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81512
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11010
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21225
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16823
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70067
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96178
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127115
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127971
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30474
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8308
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13652
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62320
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112363
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151578
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53369
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116818
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179114
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71263
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52246
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80414
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133191
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34646
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19321
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97261
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 58347
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111891
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23881
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49872
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102956
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67063
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149953
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7827
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51105
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21408
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 151434
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13322
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19965
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97034
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27701
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41833
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5189
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 279565
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96998
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164676
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125181
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51020
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20571
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56289
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 56782
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35397
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78750
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23680
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12426
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73250
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7402
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8698
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45230
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 135973
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48209
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32706
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 76869
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97357
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26939
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7976
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73526
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41929
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106720
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20846
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 56695
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 303283
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160666
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71761
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8942
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93594
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193731
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23775
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72719
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5483
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36709
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92197
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 75986
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 176532
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 133263
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64553
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82631
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106399
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139007
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104689
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50218
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180075
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 57419
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 133113
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81233
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10340
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203049
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10301
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137805
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53419
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67415
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113014
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22063
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92106
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18732
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72692
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 253712
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4777
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22045
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64249
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184905
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72974
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71977
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14232
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 372529
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72758
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230920
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24665
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116216
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248802
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37360
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107685
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167695
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 74856
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 289281
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 96703
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83290
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60603
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 469208
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217346
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 121349
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 59607
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108274
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8336
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127438
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235020
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 252669
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98888
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 239830
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41511
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210728
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 240051
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8451
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 117742
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 53333
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 70466
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217343
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242746
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150618
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 311167
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47225
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22094
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182253
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 124443
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110229
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 121887
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53925
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179016
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145119
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192188
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5107
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 238675
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120874
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 143880
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149608
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148390
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130270
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41725
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215657
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179582
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 126964
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36954
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 256385
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55511
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183511
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231832
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 12662
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 320617
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24028
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 184153
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106789
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78697
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37149
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80604
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99616
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8265
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190735
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172302
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71810
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6831
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10901
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7112
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51142
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102781
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56648
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16608
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4648
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81591
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96601
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83314
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57589
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5301
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16366
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17360
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87176
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113145
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95792
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40848
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47849
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18789
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76745
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9738
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237349
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94567
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64268
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14231
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86631
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147433
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100357
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7361
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75477
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62784
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145766
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78821
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 89673
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119341
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120358
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34972
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95253
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83477
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78265
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137238
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 132765
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52371
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28094
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84418
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18747
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125908
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79542
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82493
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4661
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8500
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85050
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 240611
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69386
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49926
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196455
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99679
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23916
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91665
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20083
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18834
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83372
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76035
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24264
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68016
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18819
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39361
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22403
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85688
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111693
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115777
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22930
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50517
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61500
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16178
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92914
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44401
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167548
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60386
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23938
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163207
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101834
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154375
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104145
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 38317
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89745
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142461
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86602
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58537
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 180800
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8550
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26864
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5863
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9660
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84577
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29419
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63762
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62726
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17271
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109849
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6267
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99837
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63866
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73954
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83123
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16771
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5316
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20220
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24744
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16663
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 126580
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38272
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93219
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21281
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48157
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74347
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4574
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86149
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15219
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 99871
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134182
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 74445
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159966
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130558
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78385
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98089
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64844
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78485
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170776
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 272218
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56059
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178733
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75181
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39328
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64767
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15538
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94056
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80699
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6167
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10919
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14386
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63491
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28088
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 76708
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78474
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72942
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 194010
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100793
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26655
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36336
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83252
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24132
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164580
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66951
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18120
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115588
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131821
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127140
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62779
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82310
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48190
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141991
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78269
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27874
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22126
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54486
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75374
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94788
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114016
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95306
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10328
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126288
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 42056
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52005
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61237
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 44796
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15481
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159167
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 284815
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125021
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90188
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72111
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88227
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93909
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116854
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225465
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97580
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102825
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86355
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51451
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78585
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32103
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73106
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87135
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63115
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84981
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 54072
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133469
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44335
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 137019
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 60018
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87154
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155073
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94572
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179170
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71818
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17405
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82801
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 84719
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140953
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183010
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170573
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 116629
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196014
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91401
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83900
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124476
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5898
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12418
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125370
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89022
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95489
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146685
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161519
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7917
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 60961
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139049
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105871
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116560
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32777
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94168
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164552
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34492
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 118627
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123653
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197578
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18701
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121709
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135808
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 276833
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 262283
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111958
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23249
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8586
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223518
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247735
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43252
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150050
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88973
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47726
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 8099
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106716
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49281
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 391064
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 202785
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 343294
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45412
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106045
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147923
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21841
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151276
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 50138
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201269
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 104149
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166763
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191142
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120660
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120664
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115353
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166608
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45069
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247522
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155017
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181030
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 263029
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222990
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 333789
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246642
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117762
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246520
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46566
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 320733
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67373
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20716
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130843
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64748
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 331171
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 361020
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116558
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 85615
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 245251
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123990
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34329
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82328
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85433
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7281
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21258
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16756
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 94582
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 92292
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171244
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50399
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27143
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176648
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 154560
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27476
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87827
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185136
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 109678
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165076
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67935
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97624
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 173700
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70101
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39933
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231708
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 168713
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 288713
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 228427
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104199
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54373
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 48317
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194704
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57595
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39759
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 135505
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52273
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139152
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 137921
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120555
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22898
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225929
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14102
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11053
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195524
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 36511
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 87014
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 282650
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 47997
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 81799
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115047
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 110192
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95520
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53948
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39960
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 188632
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101715
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226244
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249625
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 232813
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 172984
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111901
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39190
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149549
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203884
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177817
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109528
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222603
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 566262
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55140
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27976
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 53133
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 153782
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 174769
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 62942
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 355490
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 369146
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 278654
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 259860
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 15694
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 53248
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 69225
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 109785
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162780
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66895
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11514
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87976
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71202
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66272
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70640
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127242
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22821
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77092
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119589
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80329
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6208
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69693
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112848
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 135512
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65709
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90867
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117800
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14237
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9472
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81084
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 65626
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13425
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46481
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12429
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10741
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63243
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89082
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 95702
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52394
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18595
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148990
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110587
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170017
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48336
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9781
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57151
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71404
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82027
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 131139
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97307
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208670
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108392
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87505
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83369
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93633
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7622
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55928
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17360
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71784
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19716
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17679
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 3642
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86175
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12718
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42362
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103224
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46380
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78224
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6783
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3564
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22453
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85079
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66286
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91671
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136454
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7975
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70720
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74805
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 52466
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26600
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9487
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 89509
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18156
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107755
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208016
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90121
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 255497
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5098
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121422
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137930
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15302
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142424
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 213909
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78233
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78800
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150850
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89517
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43206
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22457
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98540
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125347
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149959
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60817
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40956
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 304820
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12984
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118984
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83830
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48846
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97775
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99415
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157615
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3478
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77542
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30550
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111441
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55476
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 70821
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176063
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111262
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80311
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193456
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123410
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65073
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103447
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171091
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83657
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105237
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74316
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 96116
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26124
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13333
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9692
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71328
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 129473
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126629
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10787
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190629
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112651
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19885
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190753
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74567
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29928
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 114029
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14168
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22664
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78458
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42706
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64788
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12006
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36913
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130757
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69437
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48608
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11393
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146692
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65040
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8110
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60138
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76304
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91093
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53563
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107385
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74360
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85442
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51902
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10829
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21934
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72303
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85284
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53496
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37609
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138227
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88125
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74240
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137503
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119665
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80436
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26509
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 364346
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26478
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31605
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87711
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70629
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106374
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86105
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64817
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13550
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47736
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 156881
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12013
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 60538
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172434
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198303
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132486
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72318
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80849
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8248
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15134
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94773
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66705
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18537
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 210569
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129166
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11741
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6370
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76769
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 173142
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13554
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 81571
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18078
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78947
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160873
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120879
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61263
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 133218
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66737
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110127
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 13167
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29881
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102558
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54418
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 123072
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86276
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81032
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90391
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23380
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84260
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83763
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11417
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 71931
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104150
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15548
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175052
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110950
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11842
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60832
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109499
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 59703
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87701
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81507
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99721
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 97509
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99851
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135242
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81210
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92341
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91632
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58180
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22754
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141749
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32088
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81941
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90723
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90602
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168448
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83641
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63254
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78075
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27462
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229510
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64808
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47821
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216206
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 58756
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18433
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201952
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70942
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53272
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 263693
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153152
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70460
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23653
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89438
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4992
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86462
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18138
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128079
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 32661
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20961
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27386
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52172
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82257
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10157
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 56271
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 261631
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50001
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175642
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12088
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78313
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22507
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 61100
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143702
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61263
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91111
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58655
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25802
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26800
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67942
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93058
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130409
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166552
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76500
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127674
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21488
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140252
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46055
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139175
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42495
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66825
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163762
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 128393
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113710
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70002
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14278
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44764
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127295
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115768
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127278
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22430
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166806
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 85408
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45164
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108181
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24062
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 86847
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153166
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114426
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164330
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104081
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106642
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181778
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33075
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53705
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127173
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195076
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118281
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 215842
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160111
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109392
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197366
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44725
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61563
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 74397
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88357
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45229
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96197
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44122
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85125
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133219
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 320254
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161666
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5460
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22062
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 283878
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 56111
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 292308
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63827
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37920
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 168718
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208193
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 121776
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 250888
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49456
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 282051
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202915
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198841
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234257
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 59001
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16423
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76591
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179691
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 418770
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37163
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145347
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37923
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 145541
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50753
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124473
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 277072
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192398
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 124747
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 142147
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 178650
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163404
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 297509
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41771
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22261
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25088
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 265687
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 386927
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 226613
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 364343
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 257379
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31167
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 94284
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11365
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130809
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66723
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29366
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47571
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58961
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7919
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90679
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4254
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104654
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27495
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83314
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10381
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67583
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85946
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10101
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40470
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19939
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68106
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18641
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9798
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113224
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123019
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216234
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74601
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80011
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92836
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 116762
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115241
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70450
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4175
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60784
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25486
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54381
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92252
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10332
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 253284
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147452
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3608
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46168
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77429
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99980
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6398
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73124
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115141
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80661
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127929
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22643
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12416
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43346
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9566
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73114
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49025
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57075
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54599
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89155
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73656
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55212
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79095
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231073
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95007
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29555
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102133
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72003
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127520
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127134
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93977
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13494
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 95508
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52008
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10390
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100335
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6436
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118114
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33912
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57863
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58275
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99522
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 157585
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75290
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77015
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58057
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103168
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61495
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189342
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6139
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72244
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106574
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20069
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 138910
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95230
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71244
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134283
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162965
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103164
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133039
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28345
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127822
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10110
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100330
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53296
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 233512
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91583
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 163900
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29721
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91226
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229239
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22401
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88903
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14845
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75462
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20256
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7766
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40781
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76004
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67090
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78390
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76297
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13735
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33181
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63109
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25206
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134579
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27624
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19929
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22944
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103989
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69497
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 155544
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32096
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 12701
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163428
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237265
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95436
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100500
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127136
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61342
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163131
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9574
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185461
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42685
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 160867
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17293
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39037
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110803
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68940
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22503
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103255
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48606
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166489
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68070
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180509
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12989
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113845
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5555
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176784
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91843
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 122084
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109237
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19445
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109595
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174024
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 228060
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97081
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153069
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50454
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227358
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104421
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15964
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15387
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20479
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91087
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86909
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133891
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42386
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84191
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8559
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21721
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201951
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8398
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66652
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15095
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51117
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113793
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83965
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147447
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62192
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21254
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85249
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25209
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16906
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183425
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101529
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80632
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84044
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24771
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76990
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203717
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75442
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17026
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145923
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145624
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46676
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 90044
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52166
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107535
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 224679
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111447
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83880
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141550
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100882
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75573
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 59003
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117829
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7364
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86428
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60771
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156141
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6469
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66620
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 267309
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117979
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173560
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238393
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148797
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145886
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6458
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109045
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35549
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 71654
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17849
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21144
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171916
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18017
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 100147
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101282
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47150
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205197
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81698
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206704
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 158651
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67389
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 192440
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69420
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77130
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178257
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125674
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54501
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176585
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 166698
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72231
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20906
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 65778
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86772
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67539
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31420
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122995
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 135585
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79792
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206198
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173540
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51925
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84005
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 78192
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3605
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183316
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184424
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184698
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136922
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28443
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16029
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 70921
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246361
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93188
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 71035
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78825
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 78914
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122217
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97073
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 64429
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209013
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132311
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134814
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28130
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123548
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209765
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 135127
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16056
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64347
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88604
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100071
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 190099
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21755
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76460
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 266297
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 313179
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 57285
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14033
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99006
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81821
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194075
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203678
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238755
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 110892
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130010
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 215921
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193163
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199147
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 338073
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50746
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188021
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230775
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 387403
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41888
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84182
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 119430
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 427683
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46429
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238358
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32138
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150811
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109690
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144971
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 35824
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 220454
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104933
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34598
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 137840
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251664
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 76458
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116862
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 124733
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 81850
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 262530
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204032
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 5515
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160824
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75539
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11155
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 65152
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 76718
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91516
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103519
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183656
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81807
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67379
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31905
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115747
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60498
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5344
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8985
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 28812
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76396
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28384
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121896
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44105
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48432
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73524
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95252
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29946
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72115
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115519
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67134
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22986
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35306
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23801
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55019
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100432
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145043
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83846
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36925
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 316332
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 396792
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27428
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43558
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16654
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34793
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26242
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150935
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111256
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165592
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194580
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 279400
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178601
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105258
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 17567
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 78765
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157824
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 70773
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205225
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 298590
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 264637
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 303696
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 269985
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155333
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115042
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182171
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 111247
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174461
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 194193
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127858
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 79465
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 60563
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202901
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54056
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49023
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93566
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55746
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81039
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109499
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71516
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23743
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63968
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4557
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15489
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48307
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10043
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54293
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48498
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61251
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25023
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75120
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125843
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6030
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248597
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5301
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18922
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93859
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90927
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102634
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71792
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9719
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34574
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 78856
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59897
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49073
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87252
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 169220
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15668
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 145055
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26262
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79301
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38486
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 48897
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64092
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118584
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60187
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185415
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100935
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86680
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50149
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79420
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44154
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17768
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67917
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83216
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64800
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69031
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51370
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75744
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92794
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22837
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90798
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68098
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100014
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82780
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103306
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6461
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61330
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114444
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92989
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21901
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68915
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48093
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115143
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28780
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69129
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24132
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17051
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138387
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99879
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26645
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24548
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77311
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70825
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115825
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14265
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66144
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72802
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127868
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 38099
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104174
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108529
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94915
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95990
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114925
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84678
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15702
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8717
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78368
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137452
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124059
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13977
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63777
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75789
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 38229
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95708
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 343227
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22004
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94669
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109340
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92872
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78127
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99765
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178989
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8770
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8802
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8491
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33600
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78829
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114305
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61778
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87525
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50431
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105228
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11312
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7242
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24147
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84298
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118129
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85321
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13852
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146429
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104273
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109198
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15960
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133854
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75698
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68511
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 264933
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 147949
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85920
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12823
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5260
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 118933
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157520
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90967
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 38579
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119515
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72480
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76918
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103712
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 344119
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55470
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100747
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59308
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89729
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79532
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170456
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102086
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10539
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68130
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 69044
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6448
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71864
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117762
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152008
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15842
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6772
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19109
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65273
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6252
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89011
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155973
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179536
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74572
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 160600
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110040
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8821
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147563
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23606
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55731
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52815
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94713
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101571
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20776
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107673
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7842
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12765
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76907
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65798
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77888
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143725
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73593
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65369
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60706
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109355
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75397
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52138
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76776
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10135
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51915
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 50316
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130235
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98113
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25688
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9914
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61652
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 71308
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142170
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101885
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10298
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101802
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10778
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120390
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27847
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79447
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19619
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109251
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151127
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182729
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25368
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115774
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114863
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69553
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19811
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 57377
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81238
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50752
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121294
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 215444
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7762
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109015
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7922
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12136
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103101
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126582
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 156034
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110746
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83834
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49335
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43534
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98286
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84284
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60375
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106069
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69721
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80227
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79132
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14228
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82584
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92847
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64035
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79615
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11602
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 38518
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97162
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22349
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11754
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23515
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80467
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36189
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116624
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 225732
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99815
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103671
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122484
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70841
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62330
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36745
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20085
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26675
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4504
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86837
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72280
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27462
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33658
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54180
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125477
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112573
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27913
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38050
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81991
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106942
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85678
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35563
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115554
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152791
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215866
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73527
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23344
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158240
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63867
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196997
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12736
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69229
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67881
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119091
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106044
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154435
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22354
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84704
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92914
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22050
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33763
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117962
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101349
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190725
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157478
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6368
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115395
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66677
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91759
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120595
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84877
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 151714
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104676
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53289
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189111
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88831
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12375
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88562
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99015
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100822
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 131740
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77399
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98972
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100828
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88219
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26919
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203778
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39303
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81164
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7204
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71223
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216320
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69977
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80850
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136082
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17461
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72547
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183341
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 145442
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 103984
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39046
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32526
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201932
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137165
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154819
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175934
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164124
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216954
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53640
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85763
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166197
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 172329
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25883
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146085
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 336467
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94408
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97594
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80920
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28851
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116808
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62048
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 32739
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5939
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106747
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118247
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 67939
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13271
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218429
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163379
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299631
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 75195
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189899
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106983
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120317
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10578
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67730
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64488
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83343
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 140683
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32900
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74429
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93405
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104485
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 135080
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35514
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82944
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68687
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50844
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21155
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28206
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95135
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89217
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125587
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26256
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56451
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90762
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88318
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245022
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97924
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51799
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76985
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98724
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96061
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8179
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20259
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171718
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231051
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75186
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199430
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74035
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162005
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10301
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99096
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218666
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185721
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152682
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191013
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 14935
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178976
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100594
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 70915
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42518
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 236306
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55227
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139565
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192753
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87939
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19761
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83237
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77403
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218970
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 249947
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177596
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77044
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91602
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 362858
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18501
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 176683
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173853
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 419733
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105774
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34398
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102698
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22135
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164375
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84892
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 338774
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14944
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182567
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98491
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75479
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33118
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124458
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26632
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 322442
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237779
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52623
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149465
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102620
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76487
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 201972
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 45993
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 105411
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52929
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12770
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65586
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229799
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173582
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 41570
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8548
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60030
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 81886
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 371164
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 230670
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161974
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21584
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 130418
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 98543
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36661
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19430
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 150499
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 29196
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181680
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211738
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22076
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 78076
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 232053
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29174
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 38528
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187409
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22952
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54106
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3583
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53121
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94966
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6074
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116444
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19436
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97164
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81474
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83832
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 50011
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25625
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73023
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115440
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127530
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187944
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65471
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4482
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222622
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 2789
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95622
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100397
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90094
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78509
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 84999
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61287
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121088
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9686
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67578
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10012
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79604
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4370
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11037
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99720
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9207
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23491
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35099
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24080
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70323
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149353
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 158482
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56259
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73356
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82917
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9932
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78000
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 154954
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50351
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33829
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110932
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43875
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27529
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4942
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58129
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29316
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92050
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72809
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56469
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19996
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115178
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9049
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21754
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31528
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10180
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8957
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6702
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75758
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40873
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58656
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34401
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84095
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186195
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114483
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196978
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82062
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31838
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11008
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80180
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91749
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10712
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113374
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42258
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60557
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28738
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24584
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95068
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70426
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76958
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97609
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106114
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10938
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251804
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74653
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9346
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67844
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70153
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72698
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78539
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71954
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188618
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65500
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33854
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165800
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65717
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14104
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205656
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121757
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164562
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6715
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62884
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 226078
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 232178
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199132
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136368
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104539
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36830
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153947
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109374
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 224433
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111855
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191633
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43775
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39411
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195877
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81317
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54461
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33374
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35942
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43984
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134134
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71616
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174200
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98648
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 2935
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184508
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67361
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21780
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27690
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36847
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59710
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230605
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120625
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68457
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 292036
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100640
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197814
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 58685
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44873
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94848
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 75029
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 207064
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54460
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13200
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27682
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166991
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33892
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31709
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83739
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 100441
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60652
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69002
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 83670
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148999
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61312
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112562
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 232649
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255102
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142870
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37631
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 299865
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234347
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148428
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74759
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 160658
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105430
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185255
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161140
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136121
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23148
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40408
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 244142
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229675
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 152677
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8961
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183550
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175453
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227976
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 114783
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116933
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113800
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 83208
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 200198
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 87204
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 318139
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 266965
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 278286
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122670
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 300818
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63013
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67039
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67086
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105511
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55881
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74777
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64931
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11799
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18004
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41822
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83537
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17190
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66559
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29216
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52797
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26957
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100503
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5836
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85564
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91681
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43470
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127951
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101327
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34735
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89536
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84426
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60495
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20192
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24591
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36530
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80682
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7034
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52343
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101878
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26874
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 168585
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79114
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5469
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142019
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20742
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6675
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93296
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 259423
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7558
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55373
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215415
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9196
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112503
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67676
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 57557
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61648
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79098
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142981
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112501
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112464
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79969
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155566
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70351
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54906
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24972
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85902
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78209
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16090
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 6901
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211410
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13063
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229492
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75216
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 58294
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64331
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170520
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4516
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15571
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60843
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144411
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63541
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108440
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86782
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26574
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39579
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72392
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113381
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82819
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85577
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86918
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109610
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64092
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25018
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63096
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4516
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144806
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79574
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129747
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109318
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17382
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14662
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90362
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 317231
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28845
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149711
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25926
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 136725
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164887
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49977
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 232258
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66094
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78352
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7838
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154350
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89738
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148442
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46995
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21028
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37007
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86294
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102470
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111619
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11999
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18042
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89260
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74660
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10646
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112589
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9285
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63271
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129623
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137919
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37195
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20768
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71097
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193402
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5301
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11450
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22895
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68680
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156163
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66824
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56028
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65119
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36521
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82812
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116870
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24270
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69110
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52417
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10436
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77410
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59640
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104131
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4601
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78404
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20620
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83615
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63803
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23130
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84651
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97130
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 46908
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23822
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134805
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70489
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106815
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29739
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41834
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123173
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24156
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 151749
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158194
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78561
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13255
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 50150
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25148
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60717
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56365
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90008
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120784
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106714
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67796
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78208
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238013
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9739
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57791
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21536
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84468
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82296
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22870
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185671
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203916
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89408
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71570
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 255823
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 70334
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81424
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5751
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134895
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99667
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216575
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105066
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141681
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162224
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83078
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19408
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129490
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22672
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28343
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111438
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122279
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40995
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12771
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147565
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77983
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14279
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25486
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18826
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14182
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112762
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 417898
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49065
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101857
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119419
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112016
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5291
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118300
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131405
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23837
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61097
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80124
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49208
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251619
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103835
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77998
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 385216
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6693
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 290303
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10565
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 294352
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109668
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118611
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49667
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210048
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 87910
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8138
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7080
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225578
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94133
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88662
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141946
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32241
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94968
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 276545
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195097
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 154704
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65242
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 107385
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95787
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25035
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126689
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147606
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 297774
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182545
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 206118
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 289193
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 150429
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113467
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27693
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 202713
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53787
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138683
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116516
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44474
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 21045
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 113085
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180029
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116651
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 169082
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97000
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 190218
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116139
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 115018
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18110
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179281
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16997
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141908
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88085
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16649
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175579
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 254199
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24853
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117213
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 343238
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154403
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118786
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112436
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 424685
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192321
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206198
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 305224
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52918
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87422
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30816
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16858
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89580
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4830
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140081
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89374
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111994
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34349
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51243
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89792
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10484
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136532
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8040
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23886
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8787
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190153
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187540
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85813
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119564
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9903
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86119
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12191
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83289
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101156
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109562
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182345
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7676
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 115098
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 89865
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119781
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227627
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20827
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112175
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255054
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85659
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 275858
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70192
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141648
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 295862
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95568
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81701
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72407
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47544
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13571
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32552
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26178
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43837
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10629
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191384
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61683
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96447
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93278
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157160
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26284
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22363
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217603
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88617
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74277
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120594
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111701
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 42737
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94727
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19105
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110327
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69187
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131473
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16812
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 263572
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94719
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20832
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9294
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32206
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 160808
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112911
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194070
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158056
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149593
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130555
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 123651
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171094
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12634
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 263941
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44678
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84487
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71757
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54841
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 29266
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28693
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186920
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25468
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195215
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 264016
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 41533
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26381
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 161218
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8959
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97126
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 11677
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35284
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35794
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 142558
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28168
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 346970
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 134678
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 120920
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 37352
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95143
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 287809
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45189
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35924
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 143104
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219509
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 151579
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58271
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9729
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98478
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242518
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119347
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191369
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221580
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56410
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 212138
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 370433
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 144672
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 401922
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83198
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18800
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116867
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26994
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71426
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 296151
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224125
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16213
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180367
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198497
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 238260
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 215448
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225809
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 143423
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 212253
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48247
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133672
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69848
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57164
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 36044
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 263798
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229214
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 341216
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15160
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 34865
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51989
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 98249
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144972
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26650
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 135963
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 309593
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 341855
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18589
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160023
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182446
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159414
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 103072
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25670
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75215
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89452
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73201
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96509
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54425
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18159
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15893
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29150
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 142089
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112145
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10353
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66626
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17138
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18193
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88698
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138999
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53969
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14974
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118388
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110484
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62391
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93511
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6823
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9665
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10220
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19908
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23538
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4321
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141127
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29491
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74044
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89681
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22614
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116879
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 199601
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74383
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63932
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118250
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102558
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34450
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10476
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67043
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7393
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19679
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 89257
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48365
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 257909
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42327
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 262297
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63530
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237090
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41240
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15655
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75060
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93799
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18981
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75184
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 154956
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11550
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124114
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15700
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87063
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48278
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117161
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9292
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88013
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87525
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41322
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116015
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29579
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53549
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116380
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105572
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7005
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 256070
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92290
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41415
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 248098
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 166699
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110407
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103241
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79966
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40292
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14585
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73395
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82185
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7717
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100570
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151968
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58381
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 175099
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57904
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81387
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188833
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9055
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21415
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78412
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31089
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103017
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6777
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72305
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20266
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55231
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33295
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8788
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83712
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88339
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 139810
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 273690
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148967
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57709
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7740
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96038
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5525
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104007
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127074
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63823
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16824
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74406
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156207
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56441
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36985
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16144
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40052
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16069
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97734
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20618
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30232
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114785
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 43225
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23758
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156903
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73173
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143488
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82632
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158593
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8950
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125740
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84431
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 262515
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 244783
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105856
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81766
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110817
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83338
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115054
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8673
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150786
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16330
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155892
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23460
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112335
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28727
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 2905
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105338
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111926
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99097
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 56481
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 123635
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16222
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119236
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136448
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189062
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116841
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22366
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107937
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128362
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203286
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90382
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109615
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40369
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91792
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73239
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134811
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28845
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112407
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6786
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25745
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29163
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 45454
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138851
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124640
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11442
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11895
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178263
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9769
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219236
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64478
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74007
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57886
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101155
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17737
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77157
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259014
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184731
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20156
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82478
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16680
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53317
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75034
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58349
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36251
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93429
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82791
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142421
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112713
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 128212
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94541
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161334
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229832
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45039
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91186
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146503
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182253
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104408
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132374
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160649
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22276
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68459
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136858
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16730
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 49281
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105723
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 122596
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82473
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109804
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108335
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140973
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83314
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40576
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82682
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89527
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61296
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86256
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 304725
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157764
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200380
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 224433
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 270342
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164718
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27995
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20810
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63023
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122799
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67555
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106816
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 207300
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107069
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175900
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181625
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94788
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138228
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 203761
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21307
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77438
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62878
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142957
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45911
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91842
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66894
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51022
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196577
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72193
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 38943
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145861
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60089
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 154190
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76169
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154201
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 55185
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 126879
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7604
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14204
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78930
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11224
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57086
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98481
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76206
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90969
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94689
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88488
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185805
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 77445
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49813
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75302
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121879
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40705
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106040
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5434
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10798
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40640
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88418
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74441
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 237534
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27043
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107057
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140170
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181472
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75133
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67517
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96019
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92881
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6573
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12020
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19303
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21433
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27605
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122022
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82249
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79442
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91474
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140747
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63406
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 129768
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117422
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36731
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7698
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82166
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142845
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113023
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78148
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159540
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 38834
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157773
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 213569
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58640
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 214895
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99929
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55420
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164542
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 149928
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 49653
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180097
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51736
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24540
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114310
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104350
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10201
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9144
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111742
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128827
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75387
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89687
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80680
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8863
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210464
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70484
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74157
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13487
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93482
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92697
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17963
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76617
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11021
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158542
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81959
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58965
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 60101
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40217
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 45251
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96897
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126325
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57006
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29413
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 209969
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202317
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23691
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126235
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139600
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76917
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140384
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94977
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198994
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 103481
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 58308
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7097
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16976
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17202
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14082
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 82096
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143492
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65371
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102753
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99153
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82332
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 8649
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 42120
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99181
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7218
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23188
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 143157
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73655
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185595
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19636
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59976
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 296977
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103855
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22125
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25742
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157645
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187817
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193449
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66867
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76440
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 280742
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18640
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155593
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113227
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180543
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121697
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84534
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32544
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108263
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109830
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59471
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82578
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78281
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 283540
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 100927
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98746
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115958
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159639
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19526
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78437
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 260920
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52678
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86840
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91596
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 125805
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99023
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 279864
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179473
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 60918
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141836
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100408
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72032
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101173
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229094
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149700
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203733
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33692
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127801
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 70340
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75803
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10871
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86239
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22562
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12079
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 195756
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167105
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 60699
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194528
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115671
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83582
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 153537
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61099
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 157526
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34164
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154576
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 188817
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160254
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117034
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96187
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229896
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33316
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 91924
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55740
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 83611
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 257827
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208326
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 261114
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18671
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186450
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202716
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 49978
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97351
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25282
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82911
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85218
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85168
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105623
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19457
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64247
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114807
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64520
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79173
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9890
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 99470
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73090
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112573
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48687
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20450
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101152
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78360
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 160669
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21786
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74039
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86797
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39775
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92776
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81556
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81002
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114719
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64576
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77573
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93018
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58590
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102388
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87051
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 294003
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 127735
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91997
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24399
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161439
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55204
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 366240
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87649
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90053
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55557
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125263
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54351
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114857
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208180
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103072
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22249
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61546
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70917
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189999
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130840
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64466
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80947
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83594
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12698
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59009
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98030
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88107
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110025
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6615
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141004
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98439
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51983
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44737
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45040
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 129819
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8844
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74086
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9299
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 285446
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53790
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163208
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10048
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125373
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168840
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69455
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105779
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105232
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153523
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11161
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25479
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78653
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91692
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151217
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48008
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 129173
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56035
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56062
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 59702
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54031
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90263
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106898
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77688
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 281506
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33618
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101752
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32305
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19764
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109683
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23312
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43853
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104659
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28322
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136813
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92186
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22127
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131738
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64957
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 121109
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75418
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51978
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55240
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 226844
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146976
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133777
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86346
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157398
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44089
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69783
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217803
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67373
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 119838
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137413
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 335002
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50562
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18903
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160308
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33223
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87191
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109970
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25010
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29974
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93165
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118950
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58442
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52444
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13580
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106609
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31374
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72429
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69266
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 254887
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157723
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6483
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124835
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91745
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85419
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171499
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155706
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21146
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129251
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34818
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202242
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224656
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184363
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218097
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134259
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28950
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 83706
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86994
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149808
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144395
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112770
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57656
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126986
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 53914
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59862
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36901
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82008
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112985
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56179
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196807
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114689
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 139841
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187315
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 270409
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178983
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146628
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106873
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39392
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 126847
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27496
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148299
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101626
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 19673
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196032
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 280420
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112568
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 264422
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140259
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221333
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215943
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140430
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193025
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24612
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86276
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123344
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 309055
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 315499
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17788
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12793
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 298634
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225578
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111699
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247385
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46489
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15649
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60436
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 240021
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47377
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22470
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18672
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9938
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73789
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64512
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35457
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49454
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 107880
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9077
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4018
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129248
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111663
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7067
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85115
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138352
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16296
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97987
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 59689
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11120
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28209
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92083
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222018
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124202
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77413
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61443
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73388
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 179070
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7669
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37770
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6989
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22443
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20598
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116401
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13368
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16278
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8809
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171419
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13267
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 126086
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61221
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58546
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142948
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55125
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111399
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195795
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 224207
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110850
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55025
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77798
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156131
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109629
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65790
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83586
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70168
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143650
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 75844
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138441
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24186
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71323
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 278442
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 178950
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248818
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90551
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115841
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173566
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61631
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47330
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 205807
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66786
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12648
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93755
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67443
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71207
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 134091
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110031
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23886
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14744
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11422
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117415
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54301
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63313
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 256385
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116148
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14837
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49061
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132365
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68248
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25343
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106423
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25031
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98411
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142475
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116715
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10747
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12026
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219924
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117080
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 61747
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22457
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125720
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130498
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34037
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15511
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14665
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108689
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94043
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 142855
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54788
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106827
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33539
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 359462
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166869
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124230
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89420
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70531
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66315
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51526
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135336
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53290
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114975
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92511
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65531
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20155
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141238
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37111
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98880
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32584
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83929
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121862
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69418
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211329
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141722
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227271
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159096
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39497
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117603
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109533
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73180
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 244500
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23849
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192495
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 173372
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204252
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55053
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107038
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81734
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 11284
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132942
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55362
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 333389
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210601
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104489
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198050
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49827
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97040
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87874
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133353
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25433
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179577
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35331
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11122
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172919
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 97141
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101988
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157138
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197524
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 122779
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9844
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115499
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84411
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222660
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112849
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 130735
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 278416
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175078
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 308813
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65117
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214904
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 250468
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25932
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93029
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 19725
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 308602
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 78326
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 272950
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 32128
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156577
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 464937
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155164
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104114
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 188239
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 112241
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 68963
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 112524
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69213
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231949
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57413
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 209256
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 81986
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 232872
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201542
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117044
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56363
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76783
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179698
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117124
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116968
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 73171
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30840
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 55146
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69636
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6518
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45977
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 14628
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 89583
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226944
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196255
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73535
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 244091
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 134955
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130673
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129681
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120755
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33793
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 201994
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187941
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 303640
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 288337
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18639
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46879
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26058
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 169987
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 112975
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138256
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129402
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20868
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38107
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148344
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31018
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193735
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223620
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128103
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198685
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 264890
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177857
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 202656
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 109267
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 151319
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114887
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52034
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 257175
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185179
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47285
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199709
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 62835
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 88297
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121848
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186364
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144394
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45273
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 102332
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 209537
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162531
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 188116
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4425
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 44085
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20295
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119595
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96354
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148969
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88387
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68685
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4863
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7930
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34489
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157627
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77847
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131748
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10373
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64669
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88647
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16064
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73934
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19370
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172801
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71604
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114919
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151223
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 5881
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 88926
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109291
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11988
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92435
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13765
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147116
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65682
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42290
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66669
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21139
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96813
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92909
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73738
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16053
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103451
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 141754
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60241
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109744
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34339
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24782
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8805
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29645
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117927
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9354
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176864
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80928
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87303
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133630
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40500
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8746
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114888
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7251
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210012
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121980
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 133662
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79170
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20901
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114027
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17649
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 62461
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175048
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9280
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86405
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139092
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20735
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26219
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 203551
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90005
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 43656
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21033
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15908
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71135
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8768
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105283
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79929
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3472
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 56198
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86357
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81989
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54516
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150354
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76062
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106092
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93570
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13559
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31760
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127144
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105907
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15423
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 140067
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56999
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113170
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26197
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89344
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129020
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15523
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33669
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75766
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134964
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26429
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 47217
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101565
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48581
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116356
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98226
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173065
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169815
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14106
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115673
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 56731
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73629
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102118
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119917
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 212246
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222529
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 51449
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124132
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21361
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123222
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166429
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107505
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79506
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203355
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154529
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 224133
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124378
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68578
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68827
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64181
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105865
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23157
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 74418
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 124339
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57476
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 43693
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6679
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146845
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120748
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 153506
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 268354
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10507
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186828
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 154304
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 333029
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 123241
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 114213
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35273
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163916
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8821
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155588
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172484
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210501
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154365
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 90177
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199105
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 140685
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65921
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136889
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 145706
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 238785
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 378959
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157150
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 310408
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196960
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140215
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36116
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24494
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106001
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81825
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116555
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33387
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17559
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84341
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82571
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12415
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21017
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97796
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 138530
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98854
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32366
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10286
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 180973
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70595
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95661
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70881
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22944
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 49371
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 130571
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106009
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124389
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92646
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86627
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111303
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82944
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62239
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 155238
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78726
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82093
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46198
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3719
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5106
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 260975
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73471
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 70157
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67954
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3960
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136189
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83771
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 275053
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26856
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 151129
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152704
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217302
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164984
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99464
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100367
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11452
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114628
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46601
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20073
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133504
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19896
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68926
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94131
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25632
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 207318
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39489
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76246
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112432
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81474
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 174269
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251001
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97857
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99619
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7761
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 50580
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73469
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131001
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12448
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24287
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25126
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36156
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135519
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38081
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181582
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49333
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92562
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88389
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119490
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 222382
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92154
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94046
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101068
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79281
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47958
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6029
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26828
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47759
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87458
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222351
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84286
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85675
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 123727
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 44494
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54952
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47588
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123886
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59914
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159673
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6118
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92109
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100298
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 375555
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24807
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36172
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159097
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51795
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86034
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50381
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113160
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9131
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146478
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79510
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 143790
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55108
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12552
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184392
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11534
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72761
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100764
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32149
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85304
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130693
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169237
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162271
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14580
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8564
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101892
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199298
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55139
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 104220
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96298
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114985
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143634
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88866
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78184
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93951
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22724
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24605
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79395
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17710
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52734
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192841
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62660
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151155
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95545
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79194
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24061
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102170
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164101
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127647
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19600
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62475
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124858
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94413
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84771
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87712
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83929
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12615
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101159
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118853
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103027
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 314293
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217490
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 28228
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101510
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181485
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 194169
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 154150
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135309
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 257489
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123996
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84014
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31811
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103524
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209125
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 361802
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37839
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18225
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 48529
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27683
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102340
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20210
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 54423
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227482
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150570
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 209312
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134576
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 113352
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 316645
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24303
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98262
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186382
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230219
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14719
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18109
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 126835
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 241722
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98853
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 76589
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235077
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 294514
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124384
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9621
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56483
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 89327
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90203
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255951
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24301
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60495
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45719
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 56611
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139714
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 125900
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157891
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 43289
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 147187
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151333
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28566
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 261528
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13000
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112374
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 84313
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148577
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167099
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176594
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19890
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46106
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65111
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99891
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55100
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66525
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38862
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118019
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86800
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91738
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16734
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133326
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121754
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50346
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 237069
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55279
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62824
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24329
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 209049
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100717
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94986
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65091
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23495
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201604
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125503
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23631
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73855
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137702
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19045
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85519
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11857
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150871
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33781
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93095
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11536
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148643
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24522
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107221
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 142102
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8272
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64820
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 64160
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72750
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197199
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50865
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138467
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18929
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5869
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208630
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43726
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 53414
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111933
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16760
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60591
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5254
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47477
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85366
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116667
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52085
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23648
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32748
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43202
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69885
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30417
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194239
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7113
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14658
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87860
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78159
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95229
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109995
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21911
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81315
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114137
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49532
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9827
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115218
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97383
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 89193
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172905
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73316
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60474
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17272
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70478
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20187
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32605
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15814
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12464
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6174
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7863
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54318
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8636
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82040
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31263
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12333
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108732
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91605
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28191
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51012
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94927
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78024
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68951
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78887
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72207
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164077
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 63336
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78007
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199351
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 60130
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 51192
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17617
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98244
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10818
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88529
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61030
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173618
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73169
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100046
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98669
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22903
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40215
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152024
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79220
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 41520
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98114
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21672
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95272
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120241
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19834
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114436
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21741
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 176797
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85147
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68750
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76808
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 143868
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121582
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14025
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104851
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13904
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 195929
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21216
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184235
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100262
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14957
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19287
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105542
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127686
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138261
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7467
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60892
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101548
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50138
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92156
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72408
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19620
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 54771
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 188236
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65490
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12424
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127857
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75404
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110816
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166245
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65476
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61939
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53498
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179591
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10474
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96295
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97231
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9759
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123446
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8467
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24397
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86571
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141198
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73642
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24258
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5520
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 46844
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108295
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 203876
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11752
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68833
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84655
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60335
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106314
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186710
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90748
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113986
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9201
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63262
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123853
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67705
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84161
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106172
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114990
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51692
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97036
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73353
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155378
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133919
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88433
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78385
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98024
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69716
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7756
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 43195
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120829
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170280
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75723
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28197
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 287470
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47351
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73885
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12747
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106563
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62649
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130972
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59240
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98252
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 372072
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4486
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91477
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97290
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24519
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78939
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131213
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95725
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55725
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139864
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7896
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175833
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94792
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85033
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15080
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27342
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46755
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102213
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59643
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 260022
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101657
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32334
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 186376
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175803
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82833
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47914
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77376
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11764
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94025
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49036
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10663
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13829
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10224
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158655
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171242
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117807
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56389
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13493
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102857
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100435
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48186
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61673
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95902
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59591
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24428
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 200281
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24087
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72877
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165907
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134137
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85009
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91968
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81941
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23842
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75578
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89414
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30971
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 137873
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12182
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85087
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235334
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14727
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77656
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71573
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6136
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63759
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9677
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6421
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12554
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69665
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91892
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191849
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 46653
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70225
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158301
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13221
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124476
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114529
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76663
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83974
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115139
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 254002
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107493
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76976
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106262
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91595
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122014
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25138
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 268958
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105495
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20518
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97688
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13915
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131868
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18315
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17661
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 130460
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96419
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13547
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172259
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46951
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64773
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40910
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104073
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122068
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163559
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251914
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88735
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32960
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47389
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40031
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20750
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98560
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120858
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66919
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114722
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 46477
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86154
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139492
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129610
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68101
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44277
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187191
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148874
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25585
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69795
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90133
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82929
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 136063
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13444
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41824
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 115074
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131730
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 146184
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116148
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 58097
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6514
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159564
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51970
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57776
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145634
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144160
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151643
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 357450
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29646
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 205162
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 130871
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 129697
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22465
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 208447
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 308690
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21956
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130789
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 82815
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148608
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 128102
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 140941
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259332
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173602
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 152182
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 264382
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 182391
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232173
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27142
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31017
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30706
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40573
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51063
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 158389
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191573
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129383
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16512
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183968
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26239
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28791
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102359
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10222
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11571
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45192
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130806
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97536
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64893
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10278
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58815
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 129494
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134263
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 220759
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57206
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47375
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40509
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193942
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148110
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33123
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108190
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18497
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97740
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69405
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5733
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 133447
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85138
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12866
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91368
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86107
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134058
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6451
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98601
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23131
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122232
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22775
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27008
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34571
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5971
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96450
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77218
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28682
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96131
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61633
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90304
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33205
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14867
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91805
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 310757
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158457
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82404
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108416
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78482
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17067
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19261
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62517
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35690
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85755
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7757
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196874
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111534
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9775
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51692
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140558
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43228
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14042
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24797
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6273
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79709
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132958
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60793
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103549
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26562
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15897
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43693
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24838
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158651
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57780
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34168
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27427
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16322
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141448
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65414
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54858
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 123819
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74491
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 166535
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64305
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40248
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125991
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99094
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20807
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44697
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33080
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52625
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38940
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105171
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27373
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87753
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94557
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113065
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71352
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36433
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101625
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10969
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13922
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88608
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100051
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9074
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21912
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149581
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22215
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24899
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80115
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129767
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81817
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98809
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85402
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17814
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124545
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25342
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11460
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121669
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86285
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30122
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70652
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128831
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74879
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20860
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20032
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158528
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124295
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181361
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58256
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58086
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63122
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 241419
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33335
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6123
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103416
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13973
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117175
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113861
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 67209
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15938
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187785
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22760
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72170
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6644
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71438
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16315
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199251
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53756
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26118
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107054
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58559
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61490
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72645
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106196
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40491
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87414
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121066
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78550
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 136415
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80574
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 56916
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9976
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20403
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 322337
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 273383
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 45261
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217583
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65875
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27068
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180061
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79210
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137460
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15218
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47536
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161397
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 276745
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 95243
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 38496
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115877
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204706
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33670
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 288462
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20662
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179081
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95613
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111526
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204273
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212164
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 269983
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232029
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132578
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149898
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141239
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17995
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 66364
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141657
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 66160
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31656
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79544
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9041
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 139041
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 14976
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 253038
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170076
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 15436
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12845
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 241449
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105999
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151335
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133636
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 199750
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98515
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 241780
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 82016
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 61534
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83239
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32354
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105994
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73945
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86915
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121309
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77421
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13688
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 8033
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 234492
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21186
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 58828
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 58601
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141801
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 248676
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 264722
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220850
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162088
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 172718
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160016
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 343643
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122102
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182326
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 254203
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 243342
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 126291
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 263361
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223018
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162330
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 173901
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 125591
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 73667
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 666091
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194293
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 163425
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20401
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 123552
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 99644
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55127
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98304
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54721
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10496
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 235866
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66338
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132066
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19524
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25689
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53928
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47473
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95412
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9667
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29710
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71586
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102070
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45070
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124719
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84224
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24139
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25502
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34221
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7843
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 238508
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76917
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76216
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154453
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75230
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52905
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52340
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173158
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91661
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122065
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23512
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90319
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 164845
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8318
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29428
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 47036
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134962
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223684
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65466
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72419
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59361
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 60960
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31633
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6834
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94420
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18252
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95418
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66146
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12889
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146262
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137514
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30313
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46890
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78809
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130113
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93071
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77583
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4897
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148809
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62072
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17932
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68738
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29277
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75992
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96549
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89666
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57924
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17960
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72915
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128833
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68346
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108854
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67114
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17965
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66306
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43675
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10298
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186550
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44942
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23992
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11742
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 82982
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10576
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49220
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 182390
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69568
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112701
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66490
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84017
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9553
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69004
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 254816
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95129
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127931
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70268
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27982
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 45024
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198611
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116335
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63791
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202430
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16950
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19905
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123511
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75600
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33442
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 244811
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68132
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55652
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117355
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112812
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112553
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25299
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111115
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6819
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33491
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89809
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100030
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68296
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108616
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128582
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35714
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82395
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17742
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22414
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72607
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45032
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71235
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 248395
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78944
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8039
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91529
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43028
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31343
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82981
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41905
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18474
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9759
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80116
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9863
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14650
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32711
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17931
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10507
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10395
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67875
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 250517
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86970
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127327
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13320
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50827
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110691
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76706
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64803
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25700
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38049
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19961
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106847
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51190
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72868
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76995
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93086
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94639
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 48996
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26319
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 315822
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88880
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85270
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34117
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3613
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9478
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53131
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88092
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173301
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107755
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83147
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74555
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145744
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71458
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75488
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 162372
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16112
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58586
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158946
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22722
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229633
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 315632
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22776
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 263188
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6982
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54839
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72081
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104192
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187865
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82270
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12460
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28506
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98575
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86699
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80653
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84100
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69009
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146414
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56178
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5911
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81321
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129723
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242488
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142505
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24958
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95232
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53217
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43349
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 93764
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51014
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7813
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15129
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97093
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56344
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 28739
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122136
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 168570
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128299
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165385
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49686
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77946
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56885
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150048
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5382
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74007
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10242
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93569
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92434
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 138905
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70780
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23337
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69615
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84760
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73832
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89187
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120637
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171079
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6185
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 331469
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 77085
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125426
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118297
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68588
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 293202
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169043
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85677
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74975
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 84004
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15851
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103898
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127050
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 96942
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10413
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20029
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153569
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84752
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89535
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 94859
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14434
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242270
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109730
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23429
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148415
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45911
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145792
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124819
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86253
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62108
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72566
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83723
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174250
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92380
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 65776
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122086
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126188
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156081
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 135564
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31670
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85001
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96404
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9605
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20050
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149533
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55589
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93795
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124753
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11323
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134175
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39509
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110949
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149897
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 73372
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227564
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89282
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14231
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 91790
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20036
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 164878
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44141
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121394
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 151356
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14778
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200576
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14800
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134552
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 304210
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14891
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100550
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164980
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68103
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18061
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128363
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63332
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118064
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169084
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76876
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141438
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234825
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 252743
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134053
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80055
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94365
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26021
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82008
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52929
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84720
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107438
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179766
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115221
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120795
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130780
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 147518
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226478
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26862
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56113
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104088
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167977
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172110
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40357
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 61723
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77185
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26294
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8060
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72678
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122541
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95667
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18316
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8147
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77230
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 60199
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181160
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106601
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157159
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20373
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 139115
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187904
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89052
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 370067
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68550
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177122
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81121
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142191
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21727
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177440
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193697
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23136
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50942
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163398
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129816
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102707
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100630
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49867
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75672
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33053
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33987
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17761
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192953
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39716
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133905
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265418
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9283
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 98056
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94690
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58393
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10639
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130986
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130369
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40499
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13751
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127827
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66666
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14143
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 121951
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6879
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119734
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122087
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64841
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102815
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20335
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143214
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135278
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75124
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87825
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21998
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14728
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 163996
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26678
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118207
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 58258
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50125
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169342
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18199
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22074
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235084
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99315
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31233
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167231
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148851
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203476
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41811
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 13604
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120023
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 475840
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 173842
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7002
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110650
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71857
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 94096
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77698
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122108
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5565
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189225
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 241200
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47092
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 290320
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9776
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 258328
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32138
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223277
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 145146
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115596
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 165779
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28468
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191602
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 127224
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199246
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 128994
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33974
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214669
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129865
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40077
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 300703
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178692
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 3798
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 202712
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 131388
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33252
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17183
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189935
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32355
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 275915
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152570
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 300525
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22282
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25433
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79509
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78326
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105783
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32703
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108506
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111862
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104546
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109346
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14059
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27737
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115995
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53763
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29303
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6108
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82906
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162756
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22031
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22873
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106167
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89639
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101033
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95030
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19342
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 170467
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21292
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68078
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50877
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4564
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103536
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15691
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13747
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79946
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25749
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60191
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74058
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114140
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7064
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46239
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 157793
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53646
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36139
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16936
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56505
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78402
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 43862
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15433
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98064
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 150218
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98406
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10095
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70072
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 267586
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60558
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88122
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78568
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37732
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22224
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26455
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14793
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 131946
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64814
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14093
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106433
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 56141
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100103
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100622
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158645
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88128
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71381
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79146
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 141264
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45288
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 262820
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239408
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81413
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41102
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88803
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38807
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60934
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145123
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80718
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129866
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 95890
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185555
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57965
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 313065
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60779
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138255
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90410
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106763
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187967
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 259327
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106582
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21716
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 57053
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180619
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25643
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35468
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 375160
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156780
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155044
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 91541
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 51401
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 288502
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 289170
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15985
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 65625
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55268
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 265753
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47890
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 80121
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 279240
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259120
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142104
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33473
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 91622
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192875
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35468
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 110163
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242722
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161040
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211162
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 63987
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 135139
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44860
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 87420
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52602
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78789
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12434
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139079
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 151480
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160144
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100681
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93217
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90173
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74194
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52764
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18021
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97127
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10640
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36594
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70345
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 200241
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 156190
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98342
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56494
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50763
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12513
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100525
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47633
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69714
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28698
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86822
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 256087
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76981
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138163
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111022
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100682
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100802
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65636
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32691
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6334
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14634
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73026
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12999
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 307823
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28772
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62053
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160112
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68946
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8525
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19905
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198502
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17296
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 70640
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201724
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217705
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82452
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34462
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98105
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117471
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63446
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46651
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110492
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160411
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88614
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58979
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34814
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105273
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77810
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7416
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42621
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40221
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116063
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21942
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106895
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28039
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184866
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92583
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84738
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61403
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65417
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82472
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129771
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 158295
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222276
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112917
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46154
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119217
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61617
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10718
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85005
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 38318
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54850
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123735
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98017
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8080
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62378
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88893
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96129
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115721
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10863
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 152374
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104356
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7180
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192613
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47325
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119439
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112974
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28585
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217388
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111379
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41770
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10971
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68008
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71118
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44141
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27476
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177790
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69742
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99720
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21057
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25502
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89209
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164850
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103696
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116692
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16516
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 169812
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13007
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19505
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191468
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68750
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122318
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42720
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 46285
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116631
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90535
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107250
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138166
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77067
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10914
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63926
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169720
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24971
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108946
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160339
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33763
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14881
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234413
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106949
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 38471
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87426
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8468
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174227
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91322
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74167
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78231
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101258
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25855
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65496
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20936
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75751
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 75182
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18408
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146961
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161042
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104983
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33834
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61192
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81176
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 99534
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61929
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72291
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33083
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 237531
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15824
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71138
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14618
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142999
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55970
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5429
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14227
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45466
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 10141
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 194501
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 130368
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18747
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31116
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47980
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23192
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185551
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94041
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 275940
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112535
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 46576
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133791
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6095
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68832
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90203
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265026
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114088
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39249
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 385034
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 349325
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184207
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75613
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148906
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171873
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197255
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29780
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59728
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 137831
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140555
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110963
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 13340
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203978
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123333
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203816
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 85710
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 159168
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35117
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35266
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21396
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131586
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67960
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58604
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 275239
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132798
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115369
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83231
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 65700
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 160031
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20765
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208665
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53531
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149775
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33165
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 232981
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12130
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180704
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220736
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 101764
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73541
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 177472
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 206232
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8834
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128309
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 43642
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11728
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46838
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170673
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221302
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 118616
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95330
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141162
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197275
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21845
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98544
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37037
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26552
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 276457
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 320400
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146633
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36916
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239600
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157489
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31368
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122775
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 569675
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16431
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 138860
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189114
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 412214
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 370757
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42446
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123423
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125915
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38501
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219817
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164289
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 15007
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109465
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64752
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61627
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9544
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116655
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93065
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59817
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19232
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12755
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11457
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 116461
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11149
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26810
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88793
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64115
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73721
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71707
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63346
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9691
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192609
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49576
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20038
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8363
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24347
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19651
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108834
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84962
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11628
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72596
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65319
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88869
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101955
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106633
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80154
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57301
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73803
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76432
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139750
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121670
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134106
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170988
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19935
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32708
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 59477
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9728
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120747
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175548
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84168
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6355
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55872
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29291
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95468
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79575
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59190
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100415
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9013
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86828
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127845
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18910
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188840
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74767
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 207870
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 362805
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26044
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 153910
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87530
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70855
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196843
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25871
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89359
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171795
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87004
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 67169
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 139405
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92772
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184832
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 202553
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53957
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 376006
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214009
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 267507
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141398
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 172783
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 461784
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20631
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 429979
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135791
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249371
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18489
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 264300
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20808
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 89497
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135049
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201402
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 294214
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7334
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61073
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14053
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26191
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91590
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11400
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16685
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38251
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33546
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5781
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 50823
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27764
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8799
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6733
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81791
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94350
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104468
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50772
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68919
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21401
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28713
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19236
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77728
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 178958
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96640
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17700
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5483
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90702
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145526
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124466
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13531
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 99722
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 135769
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48889
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6914
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 51496
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29698
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127725
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 116052
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34924
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71728
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 97114
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82754
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79639
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72956
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66852
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35803
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75721
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60493
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57481
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 55695
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14095
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137191
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82708
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12696
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35895
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40971
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143156
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123831
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 51584
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31472
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 200998
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15412
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12032
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23820
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18053
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 39179
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75236
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 8850
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77289
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46595
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77573
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7183
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24253
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100178
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26111
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12388
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33801
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13233
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110320
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 296539
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60354
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20278
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124970
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97816
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12855
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97872
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 69269
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14407
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79120
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20742
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19760
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132910
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72009
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73823
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 141543
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67224
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120118
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85440
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97138
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52855
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89849
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71004
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146638
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119611
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93653
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7304
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27403
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89594
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104883
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143832
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46831
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7851
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97068
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124190
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12423
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88094
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24455
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 162790
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128560
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 212936
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 282538
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149645
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176656
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7363
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58027
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147699
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86789
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70507
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164649
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79278
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 246346
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 149573
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76857
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8850
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63307
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75738
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186551
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77297
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223376
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96136
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5449
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75497
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80234
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105761
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137140
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212349
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 88447
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41451
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10296
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 142658
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77332
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 80880
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 202672
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114329
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103168
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141950
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 258872
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8185
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109145
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175151
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10995
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99577
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77792
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106086
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87166
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109477
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105359
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179287
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199866
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13423
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66403
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151778
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 344647
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 73941
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81018
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33356
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62518
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142510
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70375
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130416
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50273
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68564
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23383
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 62109
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 37911
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72719
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61529
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31663
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79967
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98070
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105748
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18434
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7014
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87584
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25539
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99552
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 28765
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10271
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62880
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90187
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135611
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127246
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47374
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8061
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84802
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65032
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72823
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76156
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8877
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64341
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124149
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23539
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64787
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 153758
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20429
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 43732
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69046
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130625
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124487
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122736
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73202
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4688
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92695
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111569
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53116
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73276
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121327
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60212
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18186
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 248183
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10366
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20598
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182540
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148094
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 159424
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104782
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131671
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26680
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69173
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99672
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84437
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8139
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17203
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13613
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128711
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7148
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93713
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 315852
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27648
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107345
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151552
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131033
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97511
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191080
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80679
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17346
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5195
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27133
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83517
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55506
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78187
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9893
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126332
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81352
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25165
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126029
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20855
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56819
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 254670
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78506
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76012
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70367
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14914
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 258457
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7457
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15804
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22453
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138897
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67691
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 44265
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70063
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84966
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149267
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184627
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42720
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115656
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31601
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204473
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78349
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 45197
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92595
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113026
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193217
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60330
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80879
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90346
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33433
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 193524
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106460
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104292
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53998
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165440
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 149983
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163002
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60699
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155051
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89424
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 128279
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52979
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45558
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32110
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56771
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123276
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113171
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87494
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51597
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 267747
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30069
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18279
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229848
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77936
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20452
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72182
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143997
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88833
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72563
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9994
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183308
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132352
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187281
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90626
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 272544
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71592
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235626
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13764
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 262069
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15753
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9265
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48802
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64502
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123475
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95765
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18736
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101857
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162705
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21308
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217778
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180705
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74923
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8249
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172560
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10688
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112670
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70619
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54768
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181084
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 108620
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199858
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120121
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101376
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 260553
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130794
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91559
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 286133
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35823
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17763
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 129767
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28960
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 159317
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91819
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50529
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 190056
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59460
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156523
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 278951
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185907
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74265
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13527
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53270
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61246
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77026
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 138881
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92488
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138319
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45451
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50060
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8069
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44430
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188604
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31794
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27696
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133141
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 84671
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106647
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104491
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 4210
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62794
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140263
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 66662
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22015
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49998
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105709
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170694
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77413
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 150686
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 106849
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19748
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16055
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106541
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 21547
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21303
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 104718
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 63194
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96851
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10638
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208081
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 110527
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65144
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 32892
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105322
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113121
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28005
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205180
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 8234
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 158974
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48845
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 206633
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99352
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145316
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216202
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46537
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186888
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 80673
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172262
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 333374
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 73722
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 200579
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224097
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225687
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37200
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160531
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58872
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7616
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 236378
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182652
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 352045
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16029
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 130049
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216664
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171670
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 86515
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239740
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195552
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 29164
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178664
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245731
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 135346
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 253999
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 90901
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231853
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 82808
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225562
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 145750
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52684
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211102
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 426360
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 107326
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 243998
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33171
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32675
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77321
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216980
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76521
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58977
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78766
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10523
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111854
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10667
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 171613
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86043
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41816
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166600
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255296
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57246
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6829
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167455
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69330
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72546
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 146780
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101877
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80940
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64552
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7722
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115501
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137748
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66451
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81779
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132644
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13210
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14802
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46746
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75505
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19371
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92106
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19804
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29824
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32215
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85475
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 142065
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60025
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19229
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93448
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56760
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74826
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 41239
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 206963
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107144
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 42593
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20690
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10788
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63628
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11797
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90475
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73424
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8515
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89719
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134748
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86208
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132864
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66590
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162365
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98908
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 60661
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40361
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33368
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21043
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63599
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89802
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16594
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85283
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21276
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86449
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21491
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29413
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 205894
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108524
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 97410
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 111911
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143382
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31745
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46342
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56475
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10970
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66248
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21260
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102453
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193615
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94183
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18764
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75256
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55762
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26951
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111336
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53121
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11396
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109033
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8316
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66480
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83384
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102673
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67840
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127809
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 221199
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71595
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216066
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96989
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14560
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 73625
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26120
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9496
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49308
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 90978
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105499
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41318
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198327
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97539
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51953
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8087
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102237
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14926
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 259635
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174904
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55625
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128332
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85362
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170442
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91155
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191860
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42313
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129720
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 104231
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193232
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 80006
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176583
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237280
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 263005
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 280467
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 265255
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147970
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149967
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24236
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170550
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 65705
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26308
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216871
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 41905
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208994
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 130764
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40218
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40623
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180313
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 80108
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144799
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162710
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 253482
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 15642
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46310
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 282325
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 59792
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 312181
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 117863
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 101497
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247456
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8852
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66010
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98503
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36794
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86014
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56642
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54360
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128047
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67477
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 104205
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47079
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75842
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83483
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86261
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15017
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223098
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 2733
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49526
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117861
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109964
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79085
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85321
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13101
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98212
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14435
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8824
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17050
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151273
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80216
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149575
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27873
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118307
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104391
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81110
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133834
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 193040
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97714
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136628
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194671
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11991
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119905
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 322964
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86285
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160056
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73895
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 161590
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99042
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103587
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86701
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 250518
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228115
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70640
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34357
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99376
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152684
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4910
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62433
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47481
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136586
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24837
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79448
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193867
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75726
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18181
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30600
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12253
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17310
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72873
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145902
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12490
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76172
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100929
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32973
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51556
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87898
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111347
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113488
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69400
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103797
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26165
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 243655
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118545
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30495
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125500
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 212381
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36285
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8765
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211809
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97620
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27566
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8390
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20348
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23588
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101839
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65597
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 154899
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72508
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184317
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70859
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101091
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18253
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65373
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 263584
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67367
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19932
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11115
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76922
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64010
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114310
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67089
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80961
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104938
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 130953
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173399
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47639
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89199
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 337868
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18934
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125504
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15080
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44242
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35858
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124042
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165800
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97670
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193780
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115460
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83678
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248322
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 133901
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 211966
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15646
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106720
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94511
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96292
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87972
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 63634
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12789
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63702
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24645
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102184
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167909
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23676
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31002
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47176
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28016
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98300
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103376
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15983
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164202
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 126110
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94865
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 220595
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13973
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 110725
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135786
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7596
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17466
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21707
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 45480
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121124
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7479
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51582
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51250
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27032
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23501
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173840
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42012
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 47059
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42790
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124001
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21833
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167240
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81221
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 86077
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26820
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117341
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32871
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116560
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22995
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32425
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 151842
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93649
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128109
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75799
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26675
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139531
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110372
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67207
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40053
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108371
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111990
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 252748
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9831
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10411
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80636
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24400
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33540
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34718
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25489
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89504
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124618
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104071
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17803
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 150094
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5464
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28504
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15129
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94301
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21034
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96344
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59394
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63986
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7709
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131411
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12370
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49494
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 62088
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182936
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174363
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198600
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159301
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50124
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84240
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79157
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38820
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11733
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8590
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198658
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70830
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129937
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77844
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140571
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15794
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189679
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26322
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 139872
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154115
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130101
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102282
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113685
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144863
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17299
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63600
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12771
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 85003
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135243
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47026
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6621
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107163
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162649
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10549
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29751
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180911
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100211
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 127946
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33334
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 73213
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13875
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 207218
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128014
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 151008
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68670
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 81058
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104734
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172627
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109700
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 96111
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 404870
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160283
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25389
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88132
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 38522
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 59065
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173119
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 288474
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85208
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187094
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138797
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175437
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222698
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217401
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207030
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158411
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 132283
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41547
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25309
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5234
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 89667
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16349
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 275282
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36048
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26512
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25573
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 169012
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28107
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16933
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40523
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 442779
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170032
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174046
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150198
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133423
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265775
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53838
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118974
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 6011
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26356
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233535
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63653
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138211
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 66147
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 262162
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 300448
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111781
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 53494
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65314
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 221717
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28175
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107772
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57707
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22239
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63145
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19187
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55240
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101770
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60515
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10808
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67117
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 112887
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145792
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25209
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 90605
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 67917
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44571
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90961
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147906
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78428
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85647
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64758
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 277595
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166224
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201645
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93176
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129538
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113500
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11542
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9575
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89964
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34258
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12947
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197717
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16023
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156499
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124597
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90493
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135634
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 136799
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49307
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11009
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99100
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64539
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 218750
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57771
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36002
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84239
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70689
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28809
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74649
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87136
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76098
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94855
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49998
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10632
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88015
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 257724
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 91182
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36651
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228373
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188476
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15531
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239392
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82392
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109742
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117107
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32356
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 292938
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133698
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138568
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 75053
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91366
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246590
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55077
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49372
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 182106
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20063
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219042
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 334775
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219094
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46832
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119607
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6220
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 166376
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202989
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115949
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 10989
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97574
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16752
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58202
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 286109
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 107632
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181006
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 110150
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17737
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 426490
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157521
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4089
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79385
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72276
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28732
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33015
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217584
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 195401
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52931
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16146
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230208
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 202894
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221908
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 97898
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52973
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 71099
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221643
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218844
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 88227
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23766
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174187
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 13955
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23422
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28885
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 490688
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23599
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186877
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 315693
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65144
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52189
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8060
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3958
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153350
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93631
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47369
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 237195
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 3532
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86222
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5526
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132519
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55102
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84199
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61012
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33637
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29216
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78101
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109889
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 135404
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11476
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157964
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54585
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59627
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151268
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18839
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7070
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13483
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10369
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116843
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206578
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65484
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 67836
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10637
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205180
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24108
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133160
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21168
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45742
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163036
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15515
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75253
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35427
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125214
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196824
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96253
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174315
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 174242
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121753
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88844
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94462
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16152
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183392
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17327
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72239
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 5891
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21336
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27116
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11055
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12237
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118900
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 203667
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35241
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20757
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83399
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50299
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11385
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128719
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56056
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97881
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101976
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16441
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28308
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114006
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194560
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88065
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91776
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6791
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125028
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236995
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60696
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12644
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44431
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 183598
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77479
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29164
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27520
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141040
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78474
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110279
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122824
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10116
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31405
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71713
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77622
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 137447
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 433804
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130925
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84031
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47636
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118391
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56709
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156889
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79316
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98369
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65854
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7469
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58377
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227843
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142978
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 213313
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69554
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32737
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184080
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248037
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89699
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138511
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8331
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105080
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215195
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114668
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126459
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38107
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29542
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88726
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85853
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95720
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26167
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60178
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22348
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 49728
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92805
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98752
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85047
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14542
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75744
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76801
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37908
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58255
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102914
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17021
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75382
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31277
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12608
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115056
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209697
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47689
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 57456
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18966
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29809
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76912
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85962
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47634
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222096
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36129
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105309
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31875
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69492
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6556
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122124
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76996
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 278440
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102102
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6831
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 217111
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91862
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70293
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89650
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16696
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 204090
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53948
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86501
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187451
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103815
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127597
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93735
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171179
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56666
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17712
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115063
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12650
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77037
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7595
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116359
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17137
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15998
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32907
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 194700
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 95797
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119267
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106220
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127824
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10577
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83948
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93812
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6660
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 45802
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121550
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50144
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133893
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53005
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22719
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97392
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92357
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131253
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5824
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81157
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28833
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88277
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7939
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 13048
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6104
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93041
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71271
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141505
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90329
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139406
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111219
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64509
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129729
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52098
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86431
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120351
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135762
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122500
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 62568
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58723
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97872
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121152
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18972
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85448
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 130498
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88982
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119189
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 169466
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64235
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6228
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 90847
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255056
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 138334
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70472
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130513
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25795
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21992
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64856
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24041
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140788
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79497
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106544
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13054
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 82461
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191494
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99371
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176780
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 11696
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229921
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62290
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58157
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66678
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97648
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 338922
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158729
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74018
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64641
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169760
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164439
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 81639
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178570
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75127
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101059
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 298439
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31547
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140825
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 177873
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 94462
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 63754
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115421
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12429
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 77202
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179975
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186446
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39952
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82314
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221141
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109709
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 64454
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26225
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 337747
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 122951
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 117536
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197355
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129523
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116865
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 143280
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72517
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 79755
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22612
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56505
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116531
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 19757
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 269746
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72324
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107615
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159792
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 88890
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50951
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 117715
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128875
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185775
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35343
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15192
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 21300
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 240211
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16659
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 111002
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 141002
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 147230
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 262135
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34432
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 10485
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95258
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6584
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 200018
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165523
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54876
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159830
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189921
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 166107
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225448
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212174
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 271829
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35832
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 258291
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 78830
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 94061
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 65182
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37101
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 133804
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18964
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 308609
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69914
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141402
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 156105
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15866
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 168729
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229335
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96368
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94025
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196220
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227642
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 152470
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89963
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149748
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 320803
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211684
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246606
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 86455
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54107
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107150
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 133260
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196974
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 79667
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 307016
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 140018
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40536
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 167823
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299802
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182233
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206223
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42205
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23789
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 271252
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174723
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166399
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232126
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175658
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55436
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246155
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22415
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61711
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11282
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95264
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15503
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11121
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69336
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9280
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228660
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26665
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83413
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6577
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19333
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24162
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110467
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11403
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 284378
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102722
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 45157
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66128
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 95095
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96119
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9489
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142020
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29298
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 268829
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71029
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 52633
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70977
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16352
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77687
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61582
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114838
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164522
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74293
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8705
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77496
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 308665
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66192
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5316
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44792
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42752
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131580
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38145
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60626
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18585
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54850
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 238500
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 78894
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230758
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5530
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100969
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94234
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16614
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47462
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39986
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50226
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161776
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106939
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31428
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5358
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 147135
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 50380
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101028
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142196
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25724
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 52178
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77789
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153245
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 59390
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 55253
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184687
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97930
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30150
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79794
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64269
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68797
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39850
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124005
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15276
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96516
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166532
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5186
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29177
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 45723
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149864
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 135233
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3512
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67650
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 165215
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 192340
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102713
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57046
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112196
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121115
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 102044
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35676
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25051
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15217
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72037
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79832
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122423
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56494
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109321
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59845
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 425774
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229960
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114520
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111033
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7625
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12370
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192225
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51163
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81632
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100877
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68318
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47760
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127489
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91373
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149187
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95502
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 305345
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33875
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132668
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101989
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11239
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 162548
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57838
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76464
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71206
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129593
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103611
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25716
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17958
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 266463
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110612
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 137961
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127414
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219526
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16832
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46030
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94546
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218349
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133328
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37669
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184591
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90321
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124095
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106556
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102662
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 91819
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97693
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131816
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79809
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 135664
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114672
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57190
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131404
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 117905
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18752
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50101
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94837
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11275
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58498
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187673
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 228235
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18254
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 207968
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10026
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15274
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88285
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62734
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151492
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111240
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22634
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91695
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89731
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58527
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143837
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44953
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61220
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11220
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15273
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23072
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38223
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27330
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 55714
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67563
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27369
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68185
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19150
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32114
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18027
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167657
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41086
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182939
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30394
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72264
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126084
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82553
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116393
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87186
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52854
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73530
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89945
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57579
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3467
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67985
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222485
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37402
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 234676
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33188
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80906
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75185
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93643
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7677
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50763
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 218607
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100566
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83111
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85633
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18307
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37251
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66549
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77324
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46920
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72621
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71723
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50157
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15898
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123787
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140830
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 61704
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60335
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10416
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 99094
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165391
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118877
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59009
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126229
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87736
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89766
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53667
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83762
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151147
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79063
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89763
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21831
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77090
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59077
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90220
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75636
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127522
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 139045
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104364
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73283
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77365
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140847
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 114471
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 77089
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120472
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173684
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59244
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20571
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87926
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71794
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17555
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77447
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114926
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134953
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120409
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100450
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13652
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217247
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39919
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 173489
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4190
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88820
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28181
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 51798
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73932
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92468
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84499
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15812
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82592
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 263102
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111811
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 59935
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 47657
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123703
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104639
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21061
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144551
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120484
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68941
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22535
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4475
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152650
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19314
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 143606
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147796
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65807
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84814
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51664
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159102
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23734
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87708
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138738
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51758
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127865
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 128440
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64218
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24802
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93612
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178542
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 46085
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105402
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98458
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113070
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25364
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107468
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103895
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103873
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71252
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72297
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178187
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75856
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27333
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70876
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131655
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145474
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174876
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72094
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121229
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 109321
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51515
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 288037
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39783
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135899
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124094
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 102591
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78438
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49239
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50371
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251370
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97377
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25385
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 48049
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 48838
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188749
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14083
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73490
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 314184
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101755
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99699
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153079
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91185
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65210
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83784
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73664
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26359
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47922
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123020
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83622
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94521
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188119
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103110
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72763
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 321803
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159917
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55724
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13634
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44170
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112193
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 101852
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23851
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6546
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102485
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 58649
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6053
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 106641
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119467
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85554
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82685
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191563
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49895
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 47479
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108252
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140828
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69777
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63237
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122694
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117244
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52224
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20479
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 275182
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10657
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135163
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62573
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24321
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19580
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93815
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 119308
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11565
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75380
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40675
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180276
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27972
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107192
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72663
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31297
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100746
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 127273
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134471
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133204
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31908
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88824
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129975
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86835
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 195669
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 261181
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147752
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120222
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 274271
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 159637
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136413
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81254
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21809
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59457
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27446
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165256
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 201471
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29058
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45301
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23932
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192952
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21206
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 73161
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162342
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83666
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62657
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187677
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72256
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47492
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 244168
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126740
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93402
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68753
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 276518
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180711
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31638
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32292
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 325433
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99592
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154388
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 202879
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 262586
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 143421
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99247
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54546
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 73328
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158554
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 243995
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190396
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 213668
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 91929
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106393
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 119771
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 228408
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163350
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 361094
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 67434
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144865
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 322944
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55029
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169001
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45769
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155830
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246030
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 245295
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118439
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248133
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 309678
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 114481
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68077
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39289
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 156455
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83897
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66612
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35922
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 207644
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10239
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64966
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41301
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12067
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31669
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26490
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23043
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59999
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158944
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36264
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22031
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46388
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12753
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35032
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73062
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98469
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169534
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73739
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 126678
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101640
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9334
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15185
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18865
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98654
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13067
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21614
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84914
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51959
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48169
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22192
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47861
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15684
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89136
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187265
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 131421
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172777
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 133737
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17809
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18855
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54130
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 218649
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61976
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99944
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95487
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122418
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20399
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60405
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197751
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114106
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47774
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55054
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79035
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17998
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83986
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99296
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41327
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26510
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72430
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93118
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15356
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107871
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 96541
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59333
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16572
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89595
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52541
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113925
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218722
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87856
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6793
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69150
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30149
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 169489
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115917
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33223
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 162892
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40092
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105177
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71527
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 232026
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80260
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15461
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90855
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18244
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170020
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17877
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110418
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14305
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9391
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27585
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140912
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88629
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97620
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 303893
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158313
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 131132
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 359439
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15024
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 152883
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64008
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97186
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 87641
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93475
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26146
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14530
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39578
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 291501
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157897
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 80373
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108483
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 156954
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63336
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109539
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82265
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158929
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53307
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156105
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156867
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 47634
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 134394
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 122105
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 137005
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175299
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 87840
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134291
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 446174
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26986
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 263772
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222369
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56981
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80376
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206006
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232101
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115660
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13198
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 71020
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28740
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193613
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196727
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209730
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148522
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 8523
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221265
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 102803
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140658
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68629
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 104773
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227044
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 188044
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138850
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186704
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214088
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115032
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 123219
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152165
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50238
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119475
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53446
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26839
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7122
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100730
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110010
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10757
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10432
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62794
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63125
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163883
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8279
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 256801
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123993
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 220499
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7922
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 60533
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 177854
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101642
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229412
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68193
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235770
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17338
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70299
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 363222
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18538
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53917
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53961
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58113
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20771
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49646
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59042
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 166559
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127555
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68521
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59233
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71200
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42099
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93255
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 177004
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18688
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89115
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22974
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7324
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 199017
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74489
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84547
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204049
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98309
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7314
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92193
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24868
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239461
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56724
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45012
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68503
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63111
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61107
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61759
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112909
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106920
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67367
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 288506
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102689
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128313
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45376
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 149425
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113341
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25549
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83115
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122226
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93779
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131585
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126600
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197008
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124664
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9790
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 52324
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67225
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57874
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69322
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184629
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54588
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 44682
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46448
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 134906
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84897
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128518
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94405
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96498
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112723
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 215094
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18315
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6452
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7989
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 362454
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83844
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128846
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24387
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7618
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62248
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112951
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86415
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76151
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113934
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99109
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101083
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67381
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126256
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11461
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61427
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54200
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7482
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 189683
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35239
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58385
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 128119
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144967
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 134090
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119237
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101065
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60227
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251753
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55812
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8392
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13957
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120218
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117146
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259899
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 275598
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90672
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28224
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78918
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66785
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37367
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91091
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165423
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 112930
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172651
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 48688
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111558
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89769
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95019
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16900
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143349
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61940
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188383
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 156908
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 75457
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192185
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 87318
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20454
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 61775
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 312498
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29447
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 135620
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138365
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 237934
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185835
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 266262
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55096
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 200302
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 104340
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47617
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23746
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39544
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60655
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77552
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139498
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 263846
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101851
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74887
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85799
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78330
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83878
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74057
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7200
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47492
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77622
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10649
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30403
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29408
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86147
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61950
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32969
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20906
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72477
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23939
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84102
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81166
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83577
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72730
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 86769
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111463
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14961
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83184
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 46239
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51355
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86316
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28840
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90288
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76802
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122752
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73671
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115647
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142856
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21744
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107902
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 58876
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68149
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37927
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66803
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109295
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85705
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68674
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17880
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73872
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8733
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 164926
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9809
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49371
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29223
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67143
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79768
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28918
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90614
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71129
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142344
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60688
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76008
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143264
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16943
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98897
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10231
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92982
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130468
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37703
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 100247
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75590
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118654
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169113
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78449
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133755
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86769
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90249
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91699
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84365
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70079
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188517
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87210
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165959
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82987
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54391
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106776
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23550
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 38588
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40005
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104472
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214348
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49503
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24301
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49266
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 207052
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7936
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35372
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82146
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48196
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33312
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44444
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74329
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28721
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58441
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93243
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71893
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25632
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 330734
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150212
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21881
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153888
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53804
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186863
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23141
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 106950
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107409
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13358
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126378
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30487
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87563
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11116
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90468
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72658
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74606
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17040
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70171
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63120
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95768
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116549
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16219
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64283
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109333
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 115871
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39085
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90130
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50836
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 274590
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 286950
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147848
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94649
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116095
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76938
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7320
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 208018
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161845
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 124903
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72315
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185794
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109272
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30022
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172340
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55730
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62503
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112822
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22008
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55904
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140181
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87184
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7476
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101351
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9774
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73668
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14976
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156925
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69437
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101877
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146812
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102351
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97764
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67000
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23892
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11094
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 134830
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26472
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181333
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299764
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98524
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34688
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 157258
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247043
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129317
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162525
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133127
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108819
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107130
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65000
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210186
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 72659
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 241967
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113139
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18954
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42692
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81345
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153268
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 224002
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19061
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86238
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 72268
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 306828
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159352
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20391
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177853
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158298
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 280427
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26038
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115620
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 177229
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161099
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223921
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140977
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187995
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142667
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5677
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21062
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15874
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 130694
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 5601
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183483
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214777
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99884
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217803
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 341330
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 125222
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31833
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246186
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259163
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 83534
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 119458
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251847
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 103280
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 268988
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 343810
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54816
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18583
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 306743
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 97745
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140812
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 388774
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28621
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 286723
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 288836
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91825
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3625
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40091
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40442
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58455
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130708
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79224
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65075
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183721
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66583
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50115
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100359
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79661
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 183345
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120072
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43132
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56685
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 144746
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27142
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9479
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49527
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75042
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49507
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104075
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51322
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145942
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7220
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128470
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119515
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113301
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13312
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6990
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 159829
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108523
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36758
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79958
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56587
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72550
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73635
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 46945
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93216
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76751
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57581
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82866
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78856
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52076
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124599
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74767
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 261153
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58586
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3111
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11304
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81648
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92358
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154384
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204545
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94351
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215389
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73847
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98504
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 47485
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96476
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4880
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57590
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 138661
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173082
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5591
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62659
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58105
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70092
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78257
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127188
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12890
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104193
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104916
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172867
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18361
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177222
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64507
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92135
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14304
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136573
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180681
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119274
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88538
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126745
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67397
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96863
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57301
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107952
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 245432
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70541
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85969
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3471
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21797
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103535
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191822
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66827
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102144
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38003
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 152090
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28286
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133231
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140512
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35650
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53349
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19115
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105381
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72775
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91466
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40649
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 213105
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53345
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138749
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14553
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67878
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107330
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32246
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72785
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69703
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39637
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115697
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91597
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83698
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113816
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133540
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111411
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106201
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30599
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103016
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6274
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101852
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77794
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99997
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104103
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80728
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 53411
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79934
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122119
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88396
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61302
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26562
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69267
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25287
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121718
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23258
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129189
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193695
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28842
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 85183
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 158512
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 262927
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105532
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 86084
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 368997
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18218
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 59857
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163596
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 617424
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40904
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 132499
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157012
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 48634
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178254
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 787623
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25440
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 21445
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45228
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155390
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173922
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 301289
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217007
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201658
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 245876
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46232
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 324982
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 91795
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 57476
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173252
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118488
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17050
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23679
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98042
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16373
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46709
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9749
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123576
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51633
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48555
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16563
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13071
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22094
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 45797
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 162124
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12106
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176241
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 168506
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189689
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76074
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26181
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107942
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 63110
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20758
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17863
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138752
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120072
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66877
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53572
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119718
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81528
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53325
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88250
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120061
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14889
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26032
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25976
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11843
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188841
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75146
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41352
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43291
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11740
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119056
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49260
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114947
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76739
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82381
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164515
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182835
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 145077
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99533
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118062
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7180
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26470
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86295
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15890
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141462
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21099
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11878
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78750
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58870
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92628
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58888
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69364
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119854
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 4846
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3755
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8082
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26271
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77514
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17500
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84621
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124882
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170498
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23446
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71387
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29079
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 206259
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70112
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16862
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36135
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8342
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16948
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30569
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11916
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13253
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96773
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92942
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96611
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24414
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139054
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99195
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146405
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 133188
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16663
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44864
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47716
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59784
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137841
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96247
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33778
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156563
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111624
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58000
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12689
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6103
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169459
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10934
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75272
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68486
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11771
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 118708
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99970
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70268
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 48090
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22219
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15203
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83613
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17700
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45243
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72476
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85521
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 174622
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50960
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141422
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98147
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107496
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64167
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44813
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67230
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67971
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106747
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11057
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61337
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105996
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107575
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91749
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 49671
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69641
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29126
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22308
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106382
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145951
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89537
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147612
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7035
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24302
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86347
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26691
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188232
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 70819
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4085
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182123
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8304
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40974
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76103
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80880
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151485
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24430
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 91454
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192667
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 60181
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109313
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95789
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 159667
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108684
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32725
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 55280
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 168936
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25765
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122041
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137207
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7774
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 80725
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83856
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17744
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38401
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34397
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 141875
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83132
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45063
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 55253
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14332
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65760
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95707
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82273
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188181
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15058
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11387
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18105
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68531
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57054
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90785
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89694
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30531
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12440
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 132082
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200621
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11414
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 121608
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63059
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58192
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108702
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63031
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 62608
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74387
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102073
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 160002
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 130350
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16876
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79753
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65551
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50139
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43631
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83988
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64601
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68134
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88453
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73038
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7286
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48256
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 41666
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55833
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108766
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49743
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80518
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102678
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92263
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54771
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72220
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18397
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13062
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 41079
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 200441
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36757
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48783
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70050
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61259
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11182
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9381
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30401
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 135464
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59752
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19128
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182268
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119953
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39486
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216386
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52520
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8307
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98773
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50441
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65142
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8962
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85135
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105772
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90783
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11580
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50461
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26693
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54608
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15287
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83303
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 212463
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 244895
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217106
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56201
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82941
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31898
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50467
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111410
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55066
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92568
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64242
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125508
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116587
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17861
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66042
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 219855
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106651
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 150015
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15857
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28962
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161829
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125261
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 150533
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71479
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74901
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78059
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12158
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53511
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62339
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10646
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74328
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74806
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148932
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 283369
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124379
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242749
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14242
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 150786
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15295
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105884
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106476
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68323
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17049
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23146
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15624
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72286
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88573
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76654
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112763
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158670
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140131
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181082
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101340
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255573
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119184
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198917
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4643
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78240
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116047
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130330
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34100
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78046
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142152
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74687
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108931
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82665
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50549
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51085
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93558
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71803
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85841
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 131898
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 147293
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20363
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 204913
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19722
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164262
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25025
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77760
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46291
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139147
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179488
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84707
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140479
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95007
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 267427
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178120
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17393
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185376
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119739
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35826
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132765
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36947
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 112816
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 119361
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68733
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188187
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203140
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158741
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57397
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152245
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 58485
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75947
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 362841
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 296740
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 292857
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117205
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170985
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33722
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18914
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99408
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 367922
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 37030
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 342147
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 147007
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139564
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196091
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 174706
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120331
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50018
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175076
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93791
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152567
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 29185
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165537
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185327
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24509
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120195
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299714
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104580
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43280
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167018
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12867
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70362
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45870
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107790
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 140429
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102564
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54925
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230775
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103560
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89185
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 195640
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149926
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97731
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136479
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173048
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116996
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136071
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153343
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192100
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176790
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103817
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 70766
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 85800
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16597
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 100276
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149593
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255797
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136100
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143417
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138436
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 89273
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 8659
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10535
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86272
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80880
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95878
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12189
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 9251
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 92844
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130125
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186989
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17479
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120030
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 85604
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13791
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181519
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28766
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116772
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46244
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 184822
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66569
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 118192
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33679
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 271233
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12935
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 51143
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 175247
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191520
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169436
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 117825
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 128783
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153170
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178978
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173844
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149400
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235100
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227698
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37787
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 257412
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197710
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 317120
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 337271
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 245200
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 312572
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 11756
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24044
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 143727
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 101845
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 110932
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197241
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 276554
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 123292
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23320
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 171718
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 178905
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 61608
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52147
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73690
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103041
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66071
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96347
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184016
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10530
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47643
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17859
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14468
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214385
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122775
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9132
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117807
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107503
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14595
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98711
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80867
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 204463
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65767
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29116
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13842
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106495
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 132016
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92427
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88173
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19884
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80898
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15080
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4917
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105607
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29919
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57582
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18160
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75765
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16658
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26841
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112336
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72600
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91265
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61446
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99223
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22398
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90348
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69360
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129478
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220553
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38442
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78996
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197625
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 138622
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61757
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11139
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127676
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 116658
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42251
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82093
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10926
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42105
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79299
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144200
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92817
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78080
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64425
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9287
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31024
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88291
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12369
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89083
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5710
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17724
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37952
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51471
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62370
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26426
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94992
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14461
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63998
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 108582
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 81624
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134337
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87343
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61442
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64693
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148126
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12865
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22713
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19511
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26063
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 57522
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 41475
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 319435
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5592
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 151558
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62399
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158037
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8893
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56426
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17248
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53323
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9867
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122811
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76286
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71871
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107644
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80909
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16626
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 338764
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72013
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12377
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102915
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176938
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255666
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72930
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87376
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119041
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 143063
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12923
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191558
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24712
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 359351
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28317
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70513
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56303
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 28317
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 76319
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 175411
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265838
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247024
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 328755
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189334
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 213827
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99779
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 179158
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 339635
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120486
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 58914
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 244988
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98653
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116913
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55979
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49214
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 85217
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104591
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14704
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188698
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 3236
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43672
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 317012
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 48633
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22139
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 76860
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202542
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110845
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 355548
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40379
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37618
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 105084
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206735
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16928
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178408
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 188540
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 88230
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239001
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174633
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 240315
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 306439
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137288
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19604
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10029
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126055
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25951
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23160
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93764
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87803
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51332
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50672
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10754
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 161514
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74711
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25898
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 138738
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111233
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103962
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173602
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63118
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65536
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18515
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58348
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38498
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24408
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130696
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68256
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208065
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134625
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 46739
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7959
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34745
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7007
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78956
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184081
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50428
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37947
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17166
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7363
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38397
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6263
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82745
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47993
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19048
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65203
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133013
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133684
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39273
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83606
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74423
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 53979
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 96390
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21054
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94736
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21195
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82189
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21058
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10413
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82561
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55632
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7293
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35633
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12892
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70078
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 43108
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24431
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27577
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23642
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24158
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28815
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89722
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50335
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29678
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12260
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96914
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165479
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 14955
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40637
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139355
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103185
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98883
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36388
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145321
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129408
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182048
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18155
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 173918
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 203492
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16439
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66230
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 91330
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54788
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63960
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127552
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87082
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36066
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70652
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110756
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14920
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116660
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89087
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 154881
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25832
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195680
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152041
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18298
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27777
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10319
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21884
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169954
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 250643
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70523
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15888
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147021
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 44163
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27966
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 308610
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99516
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75171
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7856
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123330
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60685
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102601
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131422
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86998
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113362
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49010
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173943
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86065
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27217
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19157
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 58959
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18810
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24316
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90492
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20203
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 30643
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91977
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28261
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13566
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47930
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74758
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 236962
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 139306
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112476
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17342
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83095
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79608
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47290
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82319
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 42428
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147571
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57942
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60824
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 179753
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226009
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15401
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20042
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10467
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81651
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63490
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6775
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26702
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28054
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13412
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42105
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 116706
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88383
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15515
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57817
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75149
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15928
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4102
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78508
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7386
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7760
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52556
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80802
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50886
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96023
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7116
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24256
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6433
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 159249
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 130839
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72898
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54855
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23371
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5847
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66667
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 180057
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140388
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11758
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34012
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109399
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58612
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14119
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44445
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178216
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90748
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159239
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84450
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89748
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18237
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 354453
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187615
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66467
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8215
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7519
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19171
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89611
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7644
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78637
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81932
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48779
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64031
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124814
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5115
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69342
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74953
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11097
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77690
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46146
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85930
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63599
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34746
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155187
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97437
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128858
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28552
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134494
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140006
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6317
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8896
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50407
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 164047
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78104
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78204
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6309
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 121421
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90151
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24583
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131907
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80795
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 80920
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114482
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132506
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40313
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125608
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85495
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 129784
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 198866
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67847
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187038
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104556
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 294744
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70545
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16078
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59534
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58922
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99083
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60136
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120104
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10434
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 322804
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123088
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77230
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9760
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122013
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4641
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125910
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121923
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151803
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65556
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 241433
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123446
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150602
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 200318
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34348
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214591
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98745
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 30864
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299381
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105182
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25344
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113716
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229175
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 163050
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25043
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242888
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 86665
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51294
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15402
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159139
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109567
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 92753
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112154
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120878
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27027
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151275
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 177103
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45114
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58241
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123660
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106625
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160339
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24448
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25342
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110676
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65219
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8104
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22386
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 83188
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109866
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25256
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132547
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88880
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 74713
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80589
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24740
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80679
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170165
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146724
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 56702
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67491
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12865
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25255
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104721
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200826
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20419
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67569
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80106
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23484
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29239
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171443
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200128
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97353
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147612
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236044
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87727
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 46835
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40675
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147233
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59493
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190431
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18773
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72194
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42320
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97311
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41582
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188435
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49593
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159584
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187382
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165629
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125127
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11667
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110955
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128053
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172484
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242201
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 83929
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123353
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 114288
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206398
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 248115
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229160
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120007
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 123986
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 179365
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122594
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94504
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22855
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51457
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45300
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39338
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125620
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142001
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48113
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17639
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73823
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 219233
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61207
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87979
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72462
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71301
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53895
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 133599
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69445
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 206416
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85501
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21127
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113812
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105319
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16838
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56378
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65214
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57479
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99396
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112304
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 2788
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18677
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11851
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27740
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20756
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9256
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72625
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56121
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82906
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80931
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10347
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18024
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85867
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25115
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42257
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12350
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69996
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 95491
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118104
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195947
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23479
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 212991
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13901
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98409
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20165
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4818
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79014
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7332
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80024
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12151
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102523
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19756
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58967
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21219
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101319
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88584
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117957
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10547
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63352
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57561
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101397
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122868
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191256
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32750
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90958
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166218
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101049
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68345
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120488
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69926
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83477
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91144
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72796
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72566
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197803
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81436
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 55110
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16305
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57813
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65090
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56460
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143556
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76917
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 47093
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 37710
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 213740
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87520
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24959
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76264
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117031
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47712
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185600
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178123
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13595
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91462
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130166
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182178
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7139
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10485
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 295513
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 54438
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91216
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86681
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55323
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139958
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 220438
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90087
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68321
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129012
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89835
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112725
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18446
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13477
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101499
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 145908
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128733
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117370
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110374
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113959
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94208
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93233
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68018
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171177
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133035
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201697
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91177
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81091
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 59611
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30684
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8417
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144878
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10485
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 224702
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161328
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112068
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143454
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99665
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43027
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92864
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11312
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 91585
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41503
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82097
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62961
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31880
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101645
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103665
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224479
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112955
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 72210
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 49373
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103804
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184088
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41662
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41897
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129003
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126388
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87058
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 273655
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18909
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38265
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48124
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191368
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148951
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70240
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 220721
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211977
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12828
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175953
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 109011
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 59032
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18955
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46446
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201737
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 381253
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167386
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 209532
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13281
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100986
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230182
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 166232
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26048
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236590
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 3866
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173745
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166631
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 169276
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161296
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 204541
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88138
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187713
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179783
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21346
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71840
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9065
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101631
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118217
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129252
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38199
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125192
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88180
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96256
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92004
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19926
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8962
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14003
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120867
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105105
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157529
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16190
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170423
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30866
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28659
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81271
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 153961
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71387
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 44895
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56497
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158741
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142512
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79323
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112346
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20221
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130960
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24584
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79752
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 263099
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 39646
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84154
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104443
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112555
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24189
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 269112
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25472
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 143435
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242812
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89690
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 150997
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111901
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9537
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235482
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11283
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 267109
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225343
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58229
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153790
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14782
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83385
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63939
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 89694
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104034
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99330
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7840
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102677
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143759
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231953
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37323
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217288
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220833
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16548
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 295915
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95729
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 76738
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 85430
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216076
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98909
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153836
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180698
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159950
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19522
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247290
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 255438
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 113919
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209117
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106502
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46665
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 122074
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44018
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 64122
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 161947
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186579
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12820
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 63643
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211389
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221494
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 73312
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159180
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25175
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 337814
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206653
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170122
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 135088
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 103637
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 121642
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140536
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 146220
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 75622
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 91412
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197890
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223535
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 138085
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98822
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137263
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19336
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90073
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107483
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146029
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82029
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164695
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64158
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35964
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 135516
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198825
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176325
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108627
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91535
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76368
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9689
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75760
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87385
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74920
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53424
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187355
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114731
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108175
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89173
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 103835
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 148920
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101186
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42789
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 71477
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78454
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10992
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181286
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23240
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189918
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93005
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62213
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54585
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176467
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102062
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84333
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70202
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122617
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13215
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81533
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26224
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53188
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 202795
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95758
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63196
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130866
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234050
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106219
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69145
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15679
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92701
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64114
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11144
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113191
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132834
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7081
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7161
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72576
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 467416
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36264
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28783
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42271
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103188
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92354
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45320
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9440
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23626
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72603
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89872
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 237603
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92750
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126060
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76347
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12051
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27849
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42463
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39228
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85016
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65743
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 138240
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235652
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70721
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100600
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112679
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73130
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32575
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 328678
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121453
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72759
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233636
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 174460
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 127357
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28320
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81235
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102673
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 70915
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170077
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64571
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199134
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65676
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109038
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195530
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97739
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177071
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234946
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75758
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194094
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 95394
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227472
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110484
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34476
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153143
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28760
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111421
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18308
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61635
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 132926
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 83889
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230736
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68746
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103889
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 173016
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51975
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235787
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121407
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75558
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207663
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 30722
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81357
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118257
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85202
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94796
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131958
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 7961
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156124
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 287269
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166279
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93613
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 309956
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83032
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26219
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 75001
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23745
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25949
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 275339
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131350
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26369
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 88170
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37839
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 137030
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 254895
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 334549
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173893
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157585
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 248556
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45087
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 386084
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50544
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189188
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198244
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196212
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52304
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51672
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 317451
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 158356
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245074
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 11746
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49810
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113874
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40791
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14180
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 45204
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6109
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57660
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15102
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23031
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79994
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76596
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89395
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25672
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68171
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91413
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41469
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73016
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9784
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75107
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115657
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78841
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49335
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23540
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19803
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113967
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106382
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97627
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157598
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60939
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101066
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 264601
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15988
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112728
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57963
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53658
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69673
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20173
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26179
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135631
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130196
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 189438
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111119
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69625
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24087
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72614
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85190
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113156
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61786
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107669
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56264
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90495
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13867
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 168420
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7747
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32642
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 51452
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78264
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21489
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118256
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74070
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99926
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 133161
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11984
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172459
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12126
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20143
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96019
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85808
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96713
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123634
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47110
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9796
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107979
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73574
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7843
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15976
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81593
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6284
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10042
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124643
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242625
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92680
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108264
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54712
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57958
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99352
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14577
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134839
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51258
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23279
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5391
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108127
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10791
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133250
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7024
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75007
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95267
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51938
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32187
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119730
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90338
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102927
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186410
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8485
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91298
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4161
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29977
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49791
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89011
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22330
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10198
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46486
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 45915
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 161885
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145542
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133896
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17079
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125432
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21032
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106652
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211572
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97426
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 100744
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95517
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161856
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54749
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113063
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11502
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110578
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155930
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119088
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27129
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19251
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17949
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112267
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 171401
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53346
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14764
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14811
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55286
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90389
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43336
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64719
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28144
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 140557
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161379
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80430
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8570
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113739
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89371
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 57821
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125840
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16092
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15006
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95964
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55058
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5598
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 139345
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 38943
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95048
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 131660
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105354
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73820
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6086
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90225
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95728
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42308
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40274
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106067
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80805
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130247
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123641
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181823
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87735
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73652
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165077
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56377
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114306
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6623
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65121
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153840
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78940
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84667
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38486
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10987
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169093
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 185161
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53155
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64734
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28580
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46953
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77559
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12241
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112188
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 146661
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 149867
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44040
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74822
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6677
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54406
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6149
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40509
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109273
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67643
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114315
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8573
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204055
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85501
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 146014
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82482
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 56382
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74787
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90767
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60755
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110422
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9467
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11862
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107252
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 65081
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13844
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214729
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38935
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123000
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82640
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60577
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25212
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35873
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8974
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114760
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136727
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 55019
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26796
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95857
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93673
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63207
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96919
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8232
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88884
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86452
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 122090
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101259
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140186
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40161
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98433
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 132817
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76553
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74044
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121295
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109149
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23256
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5905
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61872
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49019
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23275
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103703
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58174
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103895
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101236
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57785
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133117
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50701
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13236
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121325
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9310
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26384
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89555
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164934
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218620
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91763
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47290
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99124
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87740
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129687
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58072
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16319
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126408
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119488
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176344
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97682
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185204
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159866
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137891
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86744
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83826
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113718
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61709
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7186
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196905
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118525
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102505
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10521
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70500
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225172
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129132
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183939
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54013
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81897
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81269
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80220
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137705
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115875
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150826
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232967
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 129785
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 47765
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98246
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68755
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20263
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119824
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92650
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113498
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76226
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14730
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10893
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65951
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11396
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174124
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 426567
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197533
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101713
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128619
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224738
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6024
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117949
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 240260
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16187
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166616
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 138343
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82462
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77847
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28003
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163514
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 233461
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14223
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163158
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13203
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116763
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54867
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144952
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 92099
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37928
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126635
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 293783
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192365
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45694
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 205595
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140745
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 249413
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141371
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 307777
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32132
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 277285
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186473
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 316255
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 494171
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65029
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124253
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 59762
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196611
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 135496
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 277179
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52081
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53935
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 118243
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 132804
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31843
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157296
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 79525
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13034
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169093
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111069
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71166
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104005
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139957
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24877
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62369
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82726
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86111
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68427
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40170
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35217
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102621
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96315
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49513
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22451
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46941
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164381
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72941
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59863
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41894
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117553
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9507
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106500
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102904
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61414
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29591
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87487
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78315
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62377
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107542
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 228183
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71685
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71081
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149185
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63658
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21170
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6159
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 12280
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16756
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53289
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52704
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51883
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9503
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143030
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98345
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219573
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31066
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15295
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89329
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80267
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129314
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73917
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153626
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73437
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115754
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155837
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84920
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39616
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40689
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17257
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77244
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19848
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27350
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5989
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29635
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8584
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89996
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56728
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12187
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9182
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71669
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15856
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95333
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92930
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86575
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58576
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87733
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10053
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74925
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28938
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28154
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67856
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101537
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58694
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15547
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11439
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62381
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216578
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191694
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26155
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55489
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86349
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24361
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27871
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 48247
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151160
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136617
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145697
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96789
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62486
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102338
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140197
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10171
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88223
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19773
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 281923
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 51811
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117872
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 163400
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233276
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74729
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66567
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24471
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176277
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94970
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69045
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99602
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25935
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42487
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68969
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39935
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104582
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141909
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56243
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116854
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9708
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26532
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139954
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120626
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47930
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5481
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62089
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72323
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69973
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22323
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68802
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34384
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 226172
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16400
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 97924
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86266
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152702
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106414
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119133
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19246
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10733
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122889
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18214
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78828
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138811
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36006
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 249485
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88466
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6036
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121463
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92634
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23457
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 42462
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19673
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64928
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 67719
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 200210
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63543
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75229
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72194
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5155
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 171583
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100580
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63274
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94292
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120190
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128200
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45555
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5146
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12392
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34204
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23780
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18312
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201186
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12555
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53155
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35374
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 136642
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7908
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55636
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81691
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80430
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49579
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76330
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68933
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 171247
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63255
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30147
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79754
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23152
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61806
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6287
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115052
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 143985
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89199
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29769
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6817
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13421
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73072
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67452
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50368
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12561
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126222
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 83418
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109693
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88297
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79884
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117518
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43155
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223260
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30610
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24969
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81889
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78773
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33116
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18639
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27991
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31830
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28956
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160897
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30939
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130021
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145258
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172028
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9467
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17324
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 75791
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21482
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93976
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84838
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8807
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62803
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32009
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18625
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53329
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52080
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36105
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37718
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26750
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82456
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96494
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116634
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118599
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194958
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 65207
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57600
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53798
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26701
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54205
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91611
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117362
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108146
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 250095
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 60742
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 52751
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116446
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67619
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180294
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148007
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109658
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176508
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65869
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8655
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60739
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97059
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81634
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28173
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122732
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108923
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34754
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8514
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90703
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113800
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137126
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180382
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126476
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 199914
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171450
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135416
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37947
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 253115
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106175
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6814
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131402
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119463
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33925
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81185
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65648
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89872
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35821
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60187
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10519
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5405
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117863
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98094
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210063
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37357
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9549
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 124557
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145177
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11912
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37412
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125918
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148270
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65130
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94136
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31013
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7796
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93718
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68084
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118698
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 59580
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101759
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115547
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59032
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157588
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145677
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91690
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199121
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 120813
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109797
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97501
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23876
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 69897
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97120
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127319
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39138
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142930
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121405
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105412
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86271
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83923
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19293
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299073
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88196
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137826
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192514
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58840
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113780
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139453
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83824
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156669
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91255
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130960
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85654
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98311
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33454
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7681
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130077
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91561
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173370
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180555
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82072
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12451
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 192499
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89572
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119739
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 159910
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232799
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 113283
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 163516
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191611
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70887
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67292
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87448
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91735
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86787
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7735
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 4363
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30751
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155636
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125789
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82258
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86541
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98377
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160981
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133413
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20570
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123736
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27695
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43331
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97037
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46353
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61321
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108988
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100693
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 267663
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7276
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39692
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9401
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 160900
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 108958
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73592
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117176
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129379
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7308
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255913
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 281123
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 64771
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236275
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 130443
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62618
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 207418
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13952
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 51248
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148487
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12302
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12489
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 6929
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33342
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26011
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46301
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159489
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46060
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73981
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 254512
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10377
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 200519
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95870
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210135
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131350
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28677
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54479
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12339
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180823
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75874
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 204614
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 179742
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 266926
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200311
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208386
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137762
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242551
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 469498
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21227
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 156033
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 202131
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101287
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16748
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156035
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18636
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134892
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120830
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125447
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136734
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11811
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40190
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180117
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 276431
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18247
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 150465
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16919
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 73733
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201209
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 243874
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 11711
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214203
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44160
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 332470
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145071
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 382361
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 154823
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 132425
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 259942
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144003
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211828
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 260077
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26104
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69769
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8639
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66876
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99303
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62176
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29467
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62195
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27995
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7220
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24636
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24626
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10355
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35807
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99619
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131603
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20419
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87374
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81811
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108064
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82968
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11158
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68309
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6385
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20778
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19091
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115913
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4771
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119715
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103403
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92765
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16554
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14787
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95270
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67815
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19052
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70971
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81302
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54441
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24773
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106946
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75055
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100252
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135941
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64413
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13011
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71199
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88631
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5978
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35887
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86245
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 140155
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 44266
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87957
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123141
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76872
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20091
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96435
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64053
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221719
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81430
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188790
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29746
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68202
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109450
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66265
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18600
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 143501
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 126685
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49695
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120359
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93741
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9578
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13660
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77409
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13250
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35628
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159835
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117347
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89514
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134879
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251989
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 264195
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102094
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 156650
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 39919
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110670
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61137
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103044
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62193
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38496
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 44711
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57845
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 38788
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8982
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25440
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134863
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31401
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 54095
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14810
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 276735
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107413
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65359
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163876
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16520
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130204
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36667
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36449
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112860
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61922
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131147
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 68901
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33532
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95594
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61402
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81308
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52808
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5242
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31805
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127916
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 65115
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51218
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 206498
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63830
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32570
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94597
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104921
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25840
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 53843
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83193
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103629
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 95262
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 41561
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3236
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43068
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65113
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50148
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88711
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9898
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197710
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109104
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180411
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63778
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60054
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90932
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230980
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20905
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56599
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 53138
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107206
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102513
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225449
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94918
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44903
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97308
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175035
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 4515
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103797
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59748
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14768
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 91967
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27743
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49701
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129078
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94070
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94463
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 205403
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178355
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96326
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 233180
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86337
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117111
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 180544
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29326
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169298
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 101748
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8736
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73809
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 116649
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235836
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117099
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 82108
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158706
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158044
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52547
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179053
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117624
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 132298
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 155138
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49893
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65804
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 303109
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138949
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55005
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 89506
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194721
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 350456
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 105879
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 239880
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101675
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182688
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205677
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52580
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 201190
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93907
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 72708
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132598
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 98761
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75466
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 355533
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19304
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73624
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129061
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107569
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16347
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181952
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4142
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 94119
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 95269
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231185
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155766
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70893
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 127022
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 175451
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 164750
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12018
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 287356
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 62247
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124740
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36833
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214644
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 107919
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 54305
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 35312
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 94528
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 103244
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 90774
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 267365
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 95500
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 254781
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 159225
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194158
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 41385
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84274
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108508
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14702
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 63519
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62074
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105940
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14058
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 211063
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37555
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63953
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103322
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49331
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 8048
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 96570
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50050
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11198
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104249
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155452
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15007
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130493
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111156
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19927
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22506
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75032
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78664
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76824
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 145021
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82029
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128022
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148496
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85705
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84986
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23923
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112653
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90071
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20460
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70816
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9520
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17940
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19762
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73132
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83523
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38293
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66685
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215061
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126134
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77039
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61322
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 133328
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60112
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 278785
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 99416
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145280
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69381
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10341
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5643
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65938
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132598
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67775
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129766
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24140
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76756
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112851
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165996
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 57932
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 206097
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24374
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99034
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71651
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87049
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39395
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44460
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123118
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51553
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58015
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7942
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97424
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85588
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109645
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7742
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169578
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140375
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6895
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30932
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98560
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12393
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100105
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118733
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119637
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182696
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62884
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29649
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58293
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53749
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181637
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99990
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 326302
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73711
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56300
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196240
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45432
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9217
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74163
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132517
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83954
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13490
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122568
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92735
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 220293
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149239
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103732
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 285761
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117712
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 8949
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85839
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201104
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57136
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95614
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124671
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154444
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71782
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104481
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20530
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8303
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123319
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166377
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85703
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 213626
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 168176
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19632
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13584
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 94087
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219874
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103734
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81055
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12123
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19086
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 261129
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61528
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95186
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25305
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68961
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33966
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88557
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154747
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50112
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81030
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125316
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72343
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 17782
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67769
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112855
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149028
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96243
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100473
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 204585
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 82523
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45662
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62400
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44362
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76745
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92660
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94679
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 243032
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214666
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9908
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82976
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63773
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181427
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 226001
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19519
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13558
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184033
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21163
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45739
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163224
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70945
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86921
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63518
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4537
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 5303
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225376
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 69160
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177180
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 243596
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24377
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15128
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 119040
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 205664
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126159
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234474
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25175
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76517
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137764
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145653
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89115
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141697
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23230
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221561
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31762
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173973
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97595
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289495
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59678
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235681
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41016
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31407
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6470
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62910
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97344
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23413
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187682
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94423
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101975
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 142090
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209814
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 174453
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 280397
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 264291
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28359
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177990
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42611
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29723
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206842
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 339997
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14841
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57236
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40702
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 150718
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118500
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 80487
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31208
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 194758
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44458
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 48408
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 304604
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 294847
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 326139
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251827
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92262
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212261
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 127646
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6826
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4516
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75518
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96277
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70733
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10839
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101008
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86504
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59837
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 47110
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68158
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70233
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49351
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94169
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36019
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45118
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14998
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77372
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49372
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95829
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 96438
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23396
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22356
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63609
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184439
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19778
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126941
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27257
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117677
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28227
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15445
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72346
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87842
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79469
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83933
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106632
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17291
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71355
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110455
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106984
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57531
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7298
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64472
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124695
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79392
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138347
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45307
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39877
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289275
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 622420
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185719
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98215
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200789
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 237348
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 90743
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 415561
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15371
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 14024
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211815
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 51329
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 163386
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161510
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30175
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 291992
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17687
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 296457
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 62831
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 118249
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 126026
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 101412
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 150390
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163622
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93461
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7529
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 100432
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70125
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72353
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64477
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8158
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16243
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65082
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60896
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93532
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18973
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12231
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67112
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53053
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60142
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7821
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23559
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100361
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32966
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94619
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14046
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76808
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 48064
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23805
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23153
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83228
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22018
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99768
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81468
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21499
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99760
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57512
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51598
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 71531
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101744
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 163232
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101126
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79527
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42855
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4781
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9157
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 276160
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98355
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61136
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124618
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134629
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105736
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114444
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122858
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101794
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26856
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94378
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33350
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7997
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126591
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20161
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94680
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8551
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101702
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5145
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16825
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97875
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 42886
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67509
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12960
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90841
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55467
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66926
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36444
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21153
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142769
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149931
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128272
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176589
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48384
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70023
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128979
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77742
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73185
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56893
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21946
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84092
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88797
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79459
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132871
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138724
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 44014
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60166
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19831
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6944
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 38412
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25584
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21365
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85076
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223740
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75027
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141550
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204061
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210446
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152495
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13649
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128783
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99052
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15652
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 56219
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42351
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17661
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108553
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97883
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88342
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68889
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144602
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 11195
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185452
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73468
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26167
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94541
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124918
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98562
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115262
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227205
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26356
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143012
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43563
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100431
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25395
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54794
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289170
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57917
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110020
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95647
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127122
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21354
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111263
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 129282
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6903
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19582
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193840
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76366
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25067
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124316
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113091
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20944
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130411
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10882
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48033
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 182309
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51315
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107501
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94426
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26865
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121313
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20274
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82793
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93909
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59737
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9316
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4545
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40047
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187470
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95382
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190054
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48657
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119566
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101093
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185001
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98735
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82315
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66277
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 260590
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20218
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54314
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110636
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14827
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23686
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120637
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118754
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255480
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87818
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145801
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104818
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 71675
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119625
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170494
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75057
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135053
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 38158
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7281
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217835
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 172000
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 325641
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57263
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224088
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154767
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180511
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216615
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230929
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201220
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 8962
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 188791
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 85319
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 220524
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197088
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32129
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 11991
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 13134
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239261
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 273126
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202876
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 248441
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34924
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181360
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19245
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 404665
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229765
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 82005
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 172892
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 156358
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 104591
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51618
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34700
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 137414
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 89258
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139049
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214683
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41329
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18471
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14523
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80729
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76865
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88586
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26409
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69982
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62248
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88679
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87566
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5752
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69761
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52090
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70854
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47807
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36012
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15248
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 147756
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99367
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88288
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25583
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 156962
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45183
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14102
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 46023
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101959
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13221
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170035
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55526
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100638
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95122
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23397
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56543
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69908
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6873
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96209
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12587
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 57592
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88853
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187546
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10361
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74500
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73698
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14309
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149885
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90254
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29209
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25182
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188125
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69046
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97489
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11606
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70551
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205191
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 202553
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 238397
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74512
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100866
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29151
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84958
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138401
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93640
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 160866
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 45589
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94473
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111198
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28419
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9747
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55874
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38366
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8014
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 231742
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90182
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16437
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92737
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125970
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39829
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108550
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9905
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83605
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95537
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17663
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19690
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 30151
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70425
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52622
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87018
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 222925
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17921
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112649
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90720
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96757
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55280
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10151
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93727
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73992
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87381
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34476
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161827
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 76825
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192893
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161741
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100404
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78955
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77262
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74814
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 352295
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27623
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26013
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88343
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65098
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12131
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98037
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77419
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 64763
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 45736
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73121
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76857
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88924
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87872
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40831
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88899
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140834
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110578
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104480
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118055
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 51527
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80784
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35501
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49854
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 158886
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58408
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179970
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 106758
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204801
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 83982
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100725
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41333
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113164
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86188
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142256
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103574
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94040
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115122
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126219
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33343
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64641
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195772
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 140153
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14226
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9173
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172385
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161604
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131894
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22416
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55432
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27605
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8091
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242741
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65825
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89395
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180570
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76212
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24876
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 96680
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86441
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135225
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69580
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77403
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66556
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65299
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28849
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6594
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 300175
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23250
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9386
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112085
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17409
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140610
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39109
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 102624
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110713
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9151
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93869
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73397
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20584
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83566
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59232
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23246
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 90690
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88973
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30587
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165162
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107483
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 133259
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 76547
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55398
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136345
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142877
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23462
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18544
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42353
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134465
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99689
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117804
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74742
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124709
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118357
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62857
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108797
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127320
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57216
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13219
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87895
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167962
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130299
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36117
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134355
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98597
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36784
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39587
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180009
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109063
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93842
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145051
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69463
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155318
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8008
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77836
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18766
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64067
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8613
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7902
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105362
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11262
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80949
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75855
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62022
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9972
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64086
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70670
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11229
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157329
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44128
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78481
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69836
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116273
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11740
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112090
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219680
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 321811
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 56131
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200643
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12667
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4300
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101686
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77021
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72577
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 228589
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62282
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72462
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207258
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28567
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40382
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31361
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83213
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67080
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68310
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98544
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24243
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137862
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133369
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120869
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 155738
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 76133
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31081
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35597
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144302
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68930
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77132
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23253
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98048
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4924
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153722
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105952
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 63022
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122082
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93842
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115095
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129044
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59160
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29076
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123674
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173732
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25824
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12051
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 266913
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119977
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15570
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251007
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35070
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115047
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8764
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123986
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 241122
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217031
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214491
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111637
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 71088
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 172596
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89415
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 120329
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13085
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134934
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233281
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23674
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14853
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34274
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14910
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223789
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103618
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83954
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 104934
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208321
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 372169
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148359
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 84082
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61872
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180481
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80490
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189837
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 52277
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149321
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 173736
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33007
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 30918
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 207479
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166999
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101782
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123143
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 21150
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 214812
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 193018
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21383
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 195724
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178035
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 270629
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51994
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92837
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183220
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 43290
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 75692
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 366346
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 45485
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 234269
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 292754
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 205515
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22231
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37529
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9910
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 91766
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223144
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214793
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 128323
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 284685
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 277061
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 278160
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 37807
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16534
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 161997
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24889
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72186
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148222
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74839
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58412
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129637
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33745
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85495
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24045
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101246
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150396
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7096
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129400
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 174679
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177336
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22871
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102713
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138271
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13686
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 377405
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112550
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 224791
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26356
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85417
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97673
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150910
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42922
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 51737
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67982
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142732
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 169204
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 237289
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125271
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162290
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 163779
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 220765
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74455
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133546
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289210
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 30442
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138759
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 310806
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 133096
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 168772
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176538
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52860
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153212
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22512
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 98709
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 147133
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234205
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 287446
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19497
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121957
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56221
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 8351
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 75841
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 245156
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101411
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86093
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222728
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 149249
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167671
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75599
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 3917
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 348404
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165064
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217880
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 127521
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 293969
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 226840
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 73728
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 269042
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242154
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 63495
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 37088
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125981
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186289
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196335
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 429173
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150639
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 364429
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 54199
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 387583
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 21384
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 58599
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 134689
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 158272
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165492
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144075
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106497
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12256
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13732
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32709
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214384
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80704
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 79144
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130987
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119372
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61614
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17770
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69110
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144913
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 128761
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84686
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76769
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59935
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14783
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26491
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85825
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125503
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73842
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63440
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 315296
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82374
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125632
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26069
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21130
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77976
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80053
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10963
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22462
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116806
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54295
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5216
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98175
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 126284
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76164
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 173025
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77368
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54677
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89141
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133524
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75644
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74290
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79309
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139795
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101649
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125200
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9885
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 49444
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72656
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55036
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109446
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 229307
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17031
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93981
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 191849
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25210
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153321
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94255
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91871
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24299
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32853
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248986
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71951
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14802
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149201
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289212
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23208
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77589
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180177
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74412
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61141
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57945
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89857
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5677
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105223
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149557
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149144
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55909
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88142
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4571
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10931
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130208
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11253
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47901
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77115
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98368
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47108
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72276
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83138
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21116
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180211
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10045
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89326
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145325
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124690
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116627
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90152
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21984
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137192
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88496
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64211
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116242
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119494
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68894
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52319
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109868
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184015
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101998
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12266
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10445
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100709
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221185
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 70812
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101713
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27587
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 202713
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36843
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99354
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 88346
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 163384
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 69905
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145813
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58915
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9508
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53696
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85075
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170759
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80056
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14130
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107466
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115232
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223681
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24623
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81241
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7289
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10189
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117974
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155117
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 60630
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12634
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113527
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89650
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78825
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4429
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34726
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70746
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78196
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33390
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33399
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82143
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 61317
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127519
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94184
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26513
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14419
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85225
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95611
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98373
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14570
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80861
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 166672
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34863
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12003
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86784
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138425
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86497
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115344
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76679
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62591
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75488
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8990
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28334
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69982
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19086
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47657
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33994
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45030
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120916
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44291
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62647
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105130
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60410
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60267
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 156947
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15246
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8877
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114956
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81371
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94432
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174137
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32204
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42029
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7498
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152306
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113074
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150562
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97778
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81343
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11504
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 62879
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53820
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5087
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90631
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93712
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122658
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35487
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 28247
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38317
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114246
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111844
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10619
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136620
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9437
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73885
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28830
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4469
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170138
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233548
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149903
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69724
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6055
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116682
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75525
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 292794
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79825
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38532
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54691
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73523
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 76126
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127550
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52639
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17069
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16938
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31211
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10038
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38894
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75150
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86685
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74684
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136539
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59906
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72418
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164693
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69487
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15980
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7576
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78329
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127045
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85971
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32536
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99677
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83871
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10296
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44606
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148411
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92604
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40987
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76333
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173297
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103584
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119548
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 252937
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107695
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116034
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22599
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141191
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55388
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172390
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170754
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 61875
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73132
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107855
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57268
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238085
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122304
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56713
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53122
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22583
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78636
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166694
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 72463
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153880
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 204693
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21921
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70507
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26502
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148897
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196032
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74497
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110518
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196411
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84380
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27829
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104242
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26405
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124797
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75743
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 103749
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88654
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20851
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99563
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27868
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83963
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71284
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248151
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53185
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249960
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187695
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 445956
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8758
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110525
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146196
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9301
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97789
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14366
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 300115
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63852
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16677
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 142274
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201694
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127658
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148287
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8875
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13975
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8126
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8206
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107241
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71680
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130860
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187046
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96953
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109490
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98358
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 71984
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20070
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25349
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57098
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136609
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76526
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 81715
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114836
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173278
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122458
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14843
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27088
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4956
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119325
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66023
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247400
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17661
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25225
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 203789
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 160949
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109224
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19580
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135675
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28453
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131009
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44015
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178958
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 58406
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183855
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53402
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 275889
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 194501
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9372
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 107761
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102656
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 64249
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 100582
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111082
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 113840
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 162197
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34438
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 260945
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183402
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42290
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230614
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32498
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152675
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158734
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96961
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27915
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 9790
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 152716
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124428
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101948
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249690
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 521473
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265823
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55613
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 385117
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28576
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 219416
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 98309
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216258
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159459
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202128
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 122494
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 215525
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 220436
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 244944
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31309
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44977
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162853
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165589
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221954
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 441334
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145967
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165248
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 205777
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25057
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161078
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 362015
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 254376
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55814
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175556
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115157
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23844
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32184
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66851
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44255
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88317
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4832
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3567
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90721
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251891
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35359
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157424
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73794
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79738
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110466
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7762
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73125
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97801
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141302
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59551
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11815
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10539
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27102
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60566
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109913
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123672
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171071
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66579
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 233605
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70621
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13406
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60919
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29074
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107236
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72736
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10275
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75429
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82534
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23315
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52567
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75358
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82217
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159679
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 199122
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66508
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 49078
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82310
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18467
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56034
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108341
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230599
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21624
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66379
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71019
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59939
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17373
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5749
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87612
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109475
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73133
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52784
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13987
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68355
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251979
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11332
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74733
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64287
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7798
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32089
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94156
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15442
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 46307
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57595
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91608
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137323
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24227
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22971
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 45030
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81414
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9769
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113272
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59773
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29322
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74085
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11155
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16601
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198952
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65549
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81509
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100656
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50169
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179743
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 212435
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102960
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53405
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6203
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71121
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95934
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104851
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169323
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13013
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105447
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115820
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85716
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23025
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 121066
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93635
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 139348
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109137
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39151
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64462
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146564
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104015
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 173620
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47857
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23168
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62191
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247104
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35106
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 10091
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126763
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94596
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224014
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 273899
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115718
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172677
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125992
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21326
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209299
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66014
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236016
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67018
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 119540
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 261208
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26070
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44189
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33137
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 177713
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27308
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78699
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42006
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196001
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110674
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45325
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 266081
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164428
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34810
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 97837
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 176714
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 391209
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 232974
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21010
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44376
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191504
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 301070
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161343
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 547043
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193804
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164092
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223235
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158917
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 269187
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228768
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60446
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 174772
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 107375
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 153630
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 19034
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42819
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69903
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 477783
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 240503
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78197
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85669
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161787
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27057
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97160
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46284
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 141220
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65403
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102823
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11475
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91904
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46960
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18888
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129195
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22127
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82279
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85911
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99892
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102044
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83321
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29014
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183303
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90722
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15402
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55093
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68442
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57815
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 140362
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6707
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32320
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92483
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4589
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69218
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36201
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113513
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117010
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51829
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76248
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32060
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111658
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119503
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19494
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112691
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245776
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28860
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21267
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14133
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120586
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9155
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17705
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88381
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6537
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76988
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8754
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140513
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119756
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67678
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91164
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81410
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8893
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24215
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79366
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63250
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14767
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18726
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75908
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97911
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27463
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59971
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83201
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23393
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49084
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95212
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 11395
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 270900
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61055
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67423
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88207
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86023
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88095
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78377
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15903
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81520
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74413
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107550
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235944
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127756
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169340
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63901
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 129073
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65560
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82501
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126361
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56651
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84527
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137676
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120879
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75295
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70365
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11553
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53874
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21660
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15638
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38025
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 107837
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72863
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158049
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 163060
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79057
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61641
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71914
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44603
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 67303
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64848
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 274514
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171430
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88284
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 63939
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184726
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 286473
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143014
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10384
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8758
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134391
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 166999
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7532
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170378
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69360
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16867
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76580
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220986
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 112904
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45234
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29443
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28361
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226633
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71836
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97362
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217734
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9851
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 92120
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133377
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 61306
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72583
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39345
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57528
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150953
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204970
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169185
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53914
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80046
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232757
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149988
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163360
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25438
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 131379
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16639
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 264900
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 195330
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 90018
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17613
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251366
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 123102
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16914
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 146857
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 133943
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222792
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 110374
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 309219
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38691
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146664
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31185
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136741
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38165
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 100829
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50217
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221316
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33459
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 72140
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77972
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 79626
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 352026
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 328411
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 110411
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12582
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 62776
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91580
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29472
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91512
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 165873
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6984
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80665
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53392
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136701
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45596
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23193
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9381
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60143
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91901
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 49915
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60770
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19913
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43058
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11700
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7121
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34877
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28778
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24479
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59500
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129421
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 37963
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62084
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94857
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76223
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201091
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77721
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18825
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9206
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16968
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25889
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53802
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14868
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17082
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125782
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60854
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92978
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50692
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60178
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84063
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23019
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86324
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94806
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5629
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76580
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129610
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78147
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10827
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9354
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90190
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93070
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51932
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114259
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124586
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51115
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109606
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74989
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114884
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70714
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15168
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97015
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112347
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27993
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137921
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67091
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16016
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114225
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16219
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9494
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 318462
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50547
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29923
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104814
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131852
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71945
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15035
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174672
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5482
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15193
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175158
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159454
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78551
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63168
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121470
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246983
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 65591
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75018
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91668
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12309
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144961
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76079
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58129
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 157452
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127967
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 273710
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95958
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58724
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21766
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10637
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25557
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80585
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16248
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59467
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 46194
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177763
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35883
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107354
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76896
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57108
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21621
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47179
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 135886
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92611
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108022
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41454
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8354
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190743
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4099
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 240707
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216410
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41567
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 130960
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18448
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177913
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69296
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154914
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 212689
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82101
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37706
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223785
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16233
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60538
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94100
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13764
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78262
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107488
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12556
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124783
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65173
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68659
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14686
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46355
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48171
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99763
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156886
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194660
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56433
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8196
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 69099
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94449
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65997
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125564
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74429
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118703
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99985
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16072
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102844
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26913
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 120204
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26685
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196496
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141887
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93980
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74659
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81333
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108024
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 308755
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67774
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21772
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27705
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190739
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85107
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 253137
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131161
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185691
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79572
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8793
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182390
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27921
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16225
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80144
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9491
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21664
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101092
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100653
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12954
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126388
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 338134
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46846
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19714
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50856
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233188
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190247
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106432
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122077
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87847
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37659
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79350
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82727
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 207860
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32195
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185780
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139969
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50499
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190488
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14595
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62200
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81090
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82083
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99939
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90660
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92707
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83036
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218618
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60227
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115147
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18590
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105277
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 128738
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 135827
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95162
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42888
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 151207
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19445
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98523
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 92607
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 154611
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 318713
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167501
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22037
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 100504
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 300593
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 32112
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54949
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 156610
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 147241
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30463
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77598
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90491
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193212
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189438
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126442
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166722
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208127
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219406
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46497
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147379
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135733
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 192811
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186165
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12240
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128220
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18786
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48994
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151731
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179157
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127679
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 311385
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 128803
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 87808
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45838
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 78665
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 128354
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203602
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221193
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24583
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 288622
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84371
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 392718
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 58465
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42994
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 283172
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21555
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67946
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 361399
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20953
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158405
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137660
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 106194
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42880
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221134
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16622
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161453
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 295044
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20354
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174219
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 322921
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 339700
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92560
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87938
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8809
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12111
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51089
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9137
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18345
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78158
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20599
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5256
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 158626
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113006
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242907
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16545
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27747
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16375
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20073
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6709
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132665
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10232
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68908
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27564
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104815
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26132
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5543
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17177
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18781
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91941
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118134
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35889
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35513
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88219
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116022
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57736
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97137
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112659
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81649
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142978
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75863
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33329
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6377
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44216
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 50290
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105606
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14694
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58904
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 45202
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146146
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64194
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102754
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125063
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 213893
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57601
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58687
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89173
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57103
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44815
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76411
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31405
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114816
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27096
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14724
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100090
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13559
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16401
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114483
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81989
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 66031
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27159
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86099
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68889
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19567
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8739
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67556
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91320
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16688
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78972
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102367
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77899
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80527
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100730
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113222
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62825
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118287
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88293
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108317
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9280
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18789
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95687
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147995
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72412
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 168218
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113426
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78108
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88231
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 220771
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 49446
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 69769
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91433
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 60917
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25363
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142890
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79286
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94399
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124174
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92071
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62527
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110187
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47968
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14603
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 309885
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 189857
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54282
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133824
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103796
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136431
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167859
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114132
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 70854
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138402
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73360
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184445
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168325
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63787
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86816
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 172506
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 302759
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55844
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26624
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 70634
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106466
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114795
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128214
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130385
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122480
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22749
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 74244
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107656
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218566
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17111
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 270342
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13790
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50047
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 64102
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22634
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46333
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72307
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86396
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118677
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19415
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66260
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7887
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34123
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74669
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105849
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87578
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12354
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 63708
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 51018
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 64495
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89538
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133780
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79266
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58406
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107123
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69037
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6478
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 45234
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192482
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34211
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103680
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10755
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91174
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 53564
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112367
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70161
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161175
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 258918
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45958
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72824
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32579
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 199595
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189838
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49955
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201757
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131714
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39353
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127115
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15980
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40849
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18369
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24767
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85100
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83027
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63473
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85407
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97981
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 260412
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134609
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24288
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 179594
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 84135
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165840
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76766
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67887
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16232
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106396
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86022
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165711
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9900
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26758
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139438
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187229
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107801
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18995
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 38600
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 344879
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 133631
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101247
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 29008
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97834
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14618
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 210725
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 136870
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14946
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169326
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26232
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146637
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 213348
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200078
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 89059
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139704
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35420
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 256821
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22738
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 126871
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39844
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 439798
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205979
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 110059
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 111349
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201853
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175507
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33975
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 237529
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159787
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121795
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 37587
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230149
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 109925
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 311074
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41247
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 36244
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167818
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 36302
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151656
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 282531
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 241471
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 128594
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159036
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44771
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16417
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155238
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46653
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82723
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92862
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95761
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69589
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14491
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 150968
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19266
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124431
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19681
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86719
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9215
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57103
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66190
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57275
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 63442
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71168
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39287
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7197
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10101
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93856
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126190
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79693
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169023
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72708
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26891
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 175361
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119930
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 220972
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 292152
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52391
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 89173
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127703
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 261849
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205291
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190457
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247299
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210613
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171763
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 342436
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209908
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 64647
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 90045
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 165551
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 193105
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 384179
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184238
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 105163
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17646
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26184
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 72217
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 273654
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50294
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 81009
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 37103
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61622
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24883
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98853
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16207
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97256
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32700
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 101483
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58468
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96086
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 136231
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57848
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77023
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5505
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11960
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10548
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29245
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18979
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79640
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25456
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106693
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11719
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53484
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19071
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59877
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57752
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97315
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116041
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87517
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56845
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42583
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163444
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61717
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65350
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67301
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64086
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20216
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76119
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 146645
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160353
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78060
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67821
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88398
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7474
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92982
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71734
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72807
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6799
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105149
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 126710
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60406
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86413
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14671
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67741
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159394
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22647
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29877
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28389
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98072
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175790
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70506
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 142722
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48940
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 137602
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102302
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113316
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 158588
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85328
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159957
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57215
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123874
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86220
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18385
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25799
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13616
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110367
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160287
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11160
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80480
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72354
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 112054
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80538
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81338
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 306539
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155834
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12414
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45370
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82300
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21388
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21156
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 100717
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66349
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162861
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63378
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52232
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24001
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60732
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84580
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 157397
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99077
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75352
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95495
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25399
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58256
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81901
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69773
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 53824
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81716
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156885
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16792
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221090
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68680
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24906
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11569
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182179
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66172
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 106504
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82252
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246455
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94746
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18026
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7062
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137676
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104610
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17112
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9617
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64877
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82791
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52506
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91892
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 85762
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159661
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24984
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70083
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204881
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79973
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148196
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95026
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76839
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136247
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197644
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21762
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 224341
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52392
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114172
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79218
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109339
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29865
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68154
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106693
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121797
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20877
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122375
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7145
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80878
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20394
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79840
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72079
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7253
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62317
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76419
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32143
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 100275
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123937
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146626
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108468
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111749
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56004
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40483
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215973
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136595
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23170
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 71025
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 155633
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82020
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 66347
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207384
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191799
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138169
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38563
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28760
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134807
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23907
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8780
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197663
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12821
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48025
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103778
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83408
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86793
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58094
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 294646
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86262
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75371
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 226584
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131971
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126136
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 38521
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104454
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43774
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81694
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25462
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85048
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72051
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108930
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110984
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14830
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 53605
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85725
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112704
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8464
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60112
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119544
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111532
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67286
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18219
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 43808
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4089
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180074
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51149
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10068
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111962
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117841
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164228
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7123
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215910
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115855
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32166
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 166069
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 240053
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91481
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20537
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154301
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 109703
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 71363
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 68925
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45727
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196112
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85337
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106988
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12978
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133334
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170403
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19224
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136252
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98659
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125477
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 400294
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242024
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 220103
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 118828
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96246
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 194365
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 48048
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 336910
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65586
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51422
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119971
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114994
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 140938
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110415
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 250775
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87128
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 94186
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23275
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40247
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138647
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63437
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 359068
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 159680
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103330
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220773
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82104
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217863
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125522
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134076
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 269035
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61118
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 167401
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151702
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 285790
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 256534
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180274
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26451
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 220766
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 283002
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229686
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173627
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 155903
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216559
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148755
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 418382
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 55333
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47429
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222055
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14184
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 177291
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247200
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134244
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49499
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109187
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231179
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 126616
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 108420
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 159739
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21578
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13694
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68620
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91017
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177930
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96878
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56252
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92551
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 3758
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25740
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110718
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91057
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144918
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75614
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85058
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85009
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26536
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19938
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75090
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107912
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10646
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70779
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68875
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9991
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85432
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63115
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102256
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80110
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11215
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25847
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10810
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39428
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26798
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77701
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94647
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44646
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23712
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93662
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77464
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 123433
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135866
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22045
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77282
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90471
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8528
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109091
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177109
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121049
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61271
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79550
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140815
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113234
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12263
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54588
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104227
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9247
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84918
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55455
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11691
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6705
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61024
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101935
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64868
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10193
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176431
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140581
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86615
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155388
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74833
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27398
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22289
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23004
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10221
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68647
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23782
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79326
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5527
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30995
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112034
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19321
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 254735
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 59229
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 54095
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 121828
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212128
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76956
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 274898
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55354
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153121
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67097
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31878
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 182484
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62272
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171285
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35691
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 77001
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3943
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24964
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78353
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98025
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96724
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27721
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80146
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165144
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 256928
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152440
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133477
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55376
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67691
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29422
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152742
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164305
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50397
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 164729
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77210
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197139
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215342
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155069
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 63970
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 253639
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 128385
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43043
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56200
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46504
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27066
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 342294
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 303203
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171769
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227698
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 310921
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128645
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55132
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56360
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166932
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 167873
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203886
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 190273
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 400114
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42017
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222768
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 295139
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 317405
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216876
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221568
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 99681
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 7642
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 128018
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 109312
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16903
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12746
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 36752
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 309153
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 281091
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 177925
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 97613
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5334
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246906
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84901
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 42419
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83526
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108370
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23443
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43285
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10127
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88364
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87265
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76384
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22884
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83430
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60855
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53115
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83379
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34916
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85026
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60424
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79322
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30910
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21946
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50058
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 114057
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12307
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97430
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60563
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80038
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59710
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31295
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141818
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10553
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129226
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143885
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93667
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8987
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58560
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42613
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 42649
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 192124
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70692
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143185
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 42241
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26983
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84801
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10269
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121088
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25448
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111406
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100638
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55604
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 298762
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117674
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64141
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163207
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128919
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 38568
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84477
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69194
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137996
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47913
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54836
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161214
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10568
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66521
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14080
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71241
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 28811
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104720
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70166
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76670
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82806
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116486
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138754
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22170
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22415
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4759
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64967
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142279
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81904
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119443
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90935
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68230
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7485
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167353
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174358
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 129219
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56075
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13005
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9908
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19754
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15847
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96127
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61960
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24178
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113716
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66600
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 284505
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 298859
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97272
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 89963
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25943
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36971
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158528
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18049
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70731
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108792
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102958
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140538
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 97647
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104980
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 64222
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23049
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35579
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 224567
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 58343
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123111
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81547
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6425
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 138474
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239713
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 102818
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98162
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115411
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7891
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 241241
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8740
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 99748
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 53649
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44793
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33783
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87529
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109981
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11087
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117011
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96232
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87691
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20749
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7768
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35676
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201624
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4543
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112967
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30151
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132649
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9721
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19822
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79061
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157987
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42846
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 286459
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117369
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 205056
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85736
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161960
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98529
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75347
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18223
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62578
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19691
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32560
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114761
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 57453
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9603
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59981
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104857
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9532
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35687
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66258
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93869
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111941
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98571
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79603
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145223
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127483
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101451
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137482
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96802
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79798
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9553
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103122
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111613
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13838
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48954
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176802
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80100
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50736
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103841
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14476
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14838
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9851
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93820
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12533
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17718
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 174700
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66794
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19593
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127820
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 86305
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87125
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 140145
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84003
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6305
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159988
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96947
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86812
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83128
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79853
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39319
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78280
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97228
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3451
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106191
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5822
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80870
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149133
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 122529
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117079
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13203
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 46752
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7170
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58181
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251310
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201786
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17226
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 255119
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124290
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97728
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126852
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115132
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101415
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180515
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94798
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67332
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139610
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 45289
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24121
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87613
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116817
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 48693
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 96202
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56745
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148376
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80069
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84791
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69965
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148247
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114221
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72605
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97708
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120353
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89021
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222822
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78675
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 123442
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61348
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97685
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40456
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151130
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152767
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88470
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25892
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8542
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 213411
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58455
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 291456
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7613
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34547
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79339
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95378
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120136
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108269
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146100
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82176
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86089
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56627
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17389
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15988
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 10168
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200359
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36346
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 48535
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196385
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12225
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49312
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81583
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165892
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242743
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180984
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 283720
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209561
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 143564
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117869
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108989
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86537
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75265
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51694
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37490
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104105
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 207469
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15616
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65635
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 57458
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222981
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88650
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77765
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39938
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116267
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 2818
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99009
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 224147
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24069
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 322531
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 58370
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197925
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42080
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93405
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 59436
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93768
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138777
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81910
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171872
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105170
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15266
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159891
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 73732
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203148
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23463
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 143716
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80343
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94385
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 53941
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202257
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 311843
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117678
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220852
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165839
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81611
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 319078
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47849
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 295036
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98981
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116068
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10737
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92239
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80099
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165825
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112203
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27207
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103061
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109699
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93799
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117787
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64039
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84777
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86218
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124002
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109825
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110205
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 96310
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72625
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72371
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29793
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 60660
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90567
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 273786
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 64157
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 72926
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 47506
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97115
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 142956
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107334
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140588
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106572
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77014
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 87721
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113625
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43742
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152297
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127450
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81233
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17028
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8171
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139248
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 159399
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112635
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30418
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42473
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95004
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247389
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125430
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111950
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 79386
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27974
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103567
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54027
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19615
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79678
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133974
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109908
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10590
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216573
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182025
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33405
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177288
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116670
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77888
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 241269
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147723
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 226871
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99295
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87703
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222862
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31482
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177228
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 329749
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8154
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 59761
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 242362
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 8830
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86014
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 280387
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 152463
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90039
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124957
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34432
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18960
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40328
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31138
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31718
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 216525
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118209
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88997
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20653
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34305
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 124828
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112694
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46584
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197098
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115324
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38853
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11106
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44523
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 278182
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18861
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16746
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 301770
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 250578
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34102
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 294454
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54867
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40036
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235754
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 353935
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174041
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179693
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 100110
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249840
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131621
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214791
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56480
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177374
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184366
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225852
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 304994
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 215284
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26664
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177456
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 171518
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22693
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17383
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182259
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223659
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135083
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 112011
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 200286
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42998
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18799
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69085
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100983
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40240
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99816
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63179
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191282
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 215349
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7313
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8353
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13747
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82011
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7027
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72231
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23716
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104239
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61347
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81980
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18310
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35023
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90800
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63777
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4033
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55586
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52602
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43937
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 41557
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65103
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61803
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113699
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9647
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90562
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79837
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16059
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9858
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27421
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56917
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45724
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75296
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71691
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130015
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105384
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182886
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97642
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68710
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153854
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23629
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210552
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112437
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89054
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 145584
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11924
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198192
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112743
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76468
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96023
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236656
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88412
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134394
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 255758
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111221
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62053
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123611
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 43831
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63594
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69122
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26161
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 229852
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137838
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22480
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223827
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80929
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143324
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 218639
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9683
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96662
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90725
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95284
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136550
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89460
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40064
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18766
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59934
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 234641
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13639
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 2867
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23266
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198648
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 128864
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84578
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 247452
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130160
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86515
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65261
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135624
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146310
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93196
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159716
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50502
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15164
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39106
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114271
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72095
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111708
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 57411
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65531
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187901
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143621
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15846
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74863
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49842
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 67686
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67328
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53391
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69519
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95986
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94152
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123728
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246906
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 151522
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21368
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22950
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15139
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50117
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78992
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77730
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74222
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 304883
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 281170
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17442
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 43019
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7451
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 46329
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 43799
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127999
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89329
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89488
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92770
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 50018
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49671
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59303
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80921
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111226
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29679
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9163
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119273
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83113
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148463
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 136649
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22833
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13015
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 88656
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144567
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120707
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142952
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73964
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42649
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4752
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7220
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155767
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71398
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6916
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98994
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 17713
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47227
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105664
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145371
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63625
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 134166
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18424
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217156
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108497
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8805
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6438
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11992
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139142
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29286
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67641
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56205
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 48746
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12950
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29650
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54953
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26782
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72694
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91351
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201193
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 260171
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205851
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 337502
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57474
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129597
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15758
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83947
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47021
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113695
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109932
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113985
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57358
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216160
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210652
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72144
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29730
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 39584
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 42216
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 66220
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128883
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98547
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 63413
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73060
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 247385
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63063
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18677
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140175
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67275
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9117
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 75318
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 8649
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25032
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28781
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44920
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109078
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299496
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38033
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 256575
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99655
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59033
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 63064
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73606
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 49872
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 123305
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48983
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11695
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99646
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65634
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227160
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 182056
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 100681
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 295363
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161690
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13542
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9891
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35294
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 32123
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187789
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214931
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248084
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63590
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40960
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185958
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55304
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236273
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 201445
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 117479
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157841
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34153
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118888
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 99365
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 11061
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 385995
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35951
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 66636
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154485
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90291
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209399
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229336
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177063
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77368
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 266887
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 234120
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 335244
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187728
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233377
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46114
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 79735
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205321
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16294
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103568
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 113881
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84288
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211176
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165505
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217965
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88244
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119485
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157868
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 228541
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48734
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159537
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131736
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27608
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35555
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164923
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182755
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187488
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 115092
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73592
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26104
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156377
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 127221
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 256866
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55547
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 266427
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42919
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19338
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42206
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 320207
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133250
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203561
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191002
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115016
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15436
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195942
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31109
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 4011
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26723
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 179234
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 364526
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54520
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 211564
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23320
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48101
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73368
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111400
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53350
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40888
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91915
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42356
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90856
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8595
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72948
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7864
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24042
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17871
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11717
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111564
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126590
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46075
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8753
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204703
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68469
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15951
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66523
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72774
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18360
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17306
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17313
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111320
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203110
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33833
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49047
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94152
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143028
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 173902
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16346
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23621
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137671
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220031
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79937
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43692
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72414
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61408
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18193
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36527
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91655
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88625
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30450
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132330
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25687
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89944
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84959
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103126
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27088
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10920
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13361
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78849
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 58095
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21799
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 65056
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112101
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4205
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62200
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6837
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37272
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99182
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111815
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78981
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40795
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93465
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33402
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102760
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70226
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91462
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65143
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48899
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31571
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81964
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77545
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16829
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89102
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64373
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91049
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81618
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5898
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135787
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125652
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13557
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 46115
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78928
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96316
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 225640
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93540
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100950
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140793
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69130
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54223
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157368
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22094
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83144
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81696
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81706
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235833
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124135
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13026
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13853
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52338
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 97074
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22576
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60026
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117296
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99293
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7811
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 168316
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81994
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57190
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66831
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26429
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126001
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8800
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190496
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65048
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83623
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 11621
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61954
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14231
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 69087
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152311
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19917
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91072
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137346
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151757
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143358
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 49843
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121316
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78677
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 74261
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50326
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13426
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 250147
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90222
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19144
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30941
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81887
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43136
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63811
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170871
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79578
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64047
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46394
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85072
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172369
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109884
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44638
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 71251
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158969
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13343
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98042
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28349
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 158463
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73325
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177619
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136416
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54964
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113897
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102103
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39783
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 224804
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 82555
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 171158
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 200463
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80721
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 87399
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225916
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22473
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96885
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138096
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170343
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71889
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91960
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114043
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 262301
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 301910
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 44966
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14594
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96880
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109538
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139645
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 261694
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 331210
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229802
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 81574
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108717
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58792
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207510
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110513
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83828
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 157655
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41114
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 50425
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182022
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94048
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92704
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 320399
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16303
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21090
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140692
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21755
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 56188
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 262837
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 398051
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 53958
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211993
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188706
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 129455
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133514
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 307747
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12773
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 233060
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 169960
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 158296
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226180
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153711
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 71896
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44512
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 220607
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265627
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55337
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148569
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 342245
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 49142
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31552
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6694
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39719
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247841
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196638
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26815
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126163
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 162049
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 87758
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 237404
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 106717
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 184411
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 59501
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157409
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18020
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 236150
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 301351
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89983
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57951
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47085
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79355
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 65209
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8689
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63810
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82073
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101835
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128290
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105760
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46329
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134204
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22604
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59038
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22029
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25179
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52417
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92123
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242643
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115153
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158352
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58181
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98750
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109674
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10147
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139138
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88394
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26207
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126519
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20119
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165765
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65286
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31821
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59103
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130820
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95149
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34129
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66899
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19227
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 138841
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24291
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92731
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15283
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9953
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64555
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52858
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87101
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64540
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22952
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96184
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133087
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197304
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 137591
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 94065
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 295869
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95401
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9774
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38469
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148154
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6053
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11815
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66002
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18157
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79158
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99577
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20468
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25362
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8156
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 167333
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74991
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49083
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129471
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79203
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12990
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13694
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79290
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15381
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66405
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71109
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83659
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16312
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 41435
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107037
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74913
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82352
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118968
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132761
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 43586
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109700
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159567
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73409
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59915
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48252
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152365
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88200
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130799
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60133
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 172835
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25567
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54329
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8838
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51382
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 52222
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47538
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87028
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40619
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106381
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103912
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95850
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143960
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74136
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161170
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66860
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68322
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14775
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52308
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89271
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30133
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189741
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15662
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180299
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70396
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94073
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 278247
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87440
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 41462
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107686
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 248367
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11240
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 85645
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107460
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158463
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119189
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17078
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26692
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 88578
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121278
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33000
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76206
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71640
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121905
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17722
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178423
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12332
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134914
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 148315
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74979
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7458
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147231
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 163312
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 101758
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80447
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132652
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220168
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62300
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127674
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31093
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7802
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126302
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101172
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87520
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41512
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6378
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67109
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 69383
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174772
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10205
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83621
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111832
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59156
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97037
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66111
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108916
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 259203
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142724
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 243109
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22158
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67199
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208541
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43381
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 379074
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131267
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131219
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101202
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 363998
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 77536
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126778
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42740
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136099
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66578
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 182531
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115574
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71980
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62823
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152063
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 228250
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 71384
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 90165
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 69611
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 385794
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230586
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157813
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64246
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220311
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 172722
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159640
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126989
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34119
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231440
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 63194
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97254
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 159432
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182390
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52876
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105443
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81768
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 98252
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 47869
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64493
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83670
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11630
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156999
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 284853
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 15744
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147588
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215444
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 70060
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 6965
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116471
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90042
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61995
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66593
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25102
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105966
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14434
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84390
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 208655
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152504
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60659
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122256
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114186
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12323
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52601
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51348
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68981
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107979
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66168
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102153
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54177
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10100
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24829
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212343
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57272
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11854
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61638
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15302
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190171
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21293
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93231
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56343
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129723
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59449
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93424
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93321
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90180
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54474
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94229
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13589
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120513
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34732
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14469
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64655
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 58414
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61068
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13987
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65443
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6737
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90701
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76007
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42351
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 49850
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26235
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71375
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33017
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216666
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14665
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10531
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101764
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11227
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30485
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95526
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153695
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94377
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55791
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115685
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80733
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212910
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7513
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115625
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47264
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13542
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78843
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36234
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46743
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14083
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15920
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71921
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75125
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78091
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179881
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29009
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64479
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38546
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42047
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97546
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76344
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90662
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127994
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109330
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57438
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108655
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42803
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18697
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99014
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52879
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114293
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 103242
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81049
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31771
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6983
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74161
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111078
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163182
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49103
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289620
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 291898
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32057
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 293313
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 91670
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141185
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 307201
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141738
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6590
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71504
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54637
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15125
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125567
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85562
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75441
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22624
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118415
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18228
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 53129
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86429
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10576
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41006
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26393
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57617
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78856
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12054
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164121
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214054
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57915
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176503
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8024
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76295
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69623
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 127811
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9672
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92268
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84861
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104797
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38970
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78901
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146960
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 228374
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40854
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 43135
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10488
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74427
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44724
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116563
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25290
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97683
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78287
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80338
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94270
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17837
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38129
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88650
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70779
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102927
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134836
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12107
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106962
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17279
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37389
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88106
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69357
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118359
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149810
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64920
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6196
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 59464
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5326
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 108777
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15883
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106189
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47137
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18480
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53233
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 144693
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121556
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29154
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235759
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78694
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71778
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226345
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98923
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103397
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130153
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67449
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45237
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25548
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6552
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98240
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84629
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 5643
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98523
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64504
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62167
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16012
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54291
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122602
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104334
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24085
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77967
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110465
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146714
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120412
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177161
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17120
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75524
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108697
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23172
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131391
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47929
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129733
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99679
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51280
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109102
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103987
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57563
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31179
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12166
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79341
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95475
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20260
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76068
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96225
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25116
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111455
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8162
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73782
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 69192
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20163
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29279
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93738
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17466
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67058
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146016
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60216
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86628
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12527
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7724
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14828
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 51402
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97799
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86481
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25328
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132348
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 243065
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82464
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 135306
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92939
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69596
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41690
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179307
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 84518
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79348
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98225
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97553
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33360
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183318
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5365
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6497
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76705
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 17661
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36080
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29924
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106846
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199326
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47146
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28130
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48182
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27796
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29665
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88169
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170064
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113395
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53175
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 60308
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97994
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99408
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73379
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 257486
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70602
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24900
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121591
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96784
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48356
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131871
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 316967
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23366
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83421
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107167
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21257
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 272208
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167545
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 153977
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99513
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 258775
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 325041
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89150
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163532
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 174110
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 95131
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10687
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 126244
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57423
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 13300
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 5886
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69298
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11528
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105480
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113746
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210364
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93836
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123362
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151726
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68662
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67262
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 226546
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66596
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169378
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 116179
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111387
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179577
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144735
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246712
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26837
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178438
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38362
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42733
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108755
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176178
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 67213
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173710
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88540
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127939
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218381
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 365318
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82108
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186106
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75680
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59714
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97940
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87335
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 72836
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164326
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71073
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 83556
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168488
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 209833
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236877
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28646
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 71402
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 515721
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24556
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 165202
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155923
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61164
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45283
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193608
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209053
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 143872
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 156724
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187177
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74163
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167016
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110823
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96947
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13884
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16994
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142088
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216922
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104549
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39864
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126617
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 93537
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16507
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 150456
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151222
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83500
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 122052
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38731
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167269
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46856
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27389
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23873
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 270887
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 151004
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 117201
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49082
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13531
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251877
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 266712
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137845
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221187
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 128331
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33341
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 105569
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 61595
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23513
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 270384
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 295876
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 100206
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31982
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41769
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85925
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 60505
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63821
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 143369
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148161
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 126749
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 80428
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 106942
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249646
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195293
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148109
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68633
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 299995
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46124
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8994
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142288
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12301
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80069
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89965
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97164
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17835
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109611
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49202
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22803
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53240
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55502
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19136
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51306
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107974
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63999
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103615
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26011
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18324
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62414
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105895
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21998
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16981
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26006
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4839
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26509
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144980
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45704
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54586
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106182
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89597
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18804
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73615
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51606
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11864
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86153
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28481
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9509
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97295
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58276
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62670
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92337
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68999
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147454
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92086
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172961
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4484
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22377
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9200
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52065
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16039
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86479
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13775
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110261
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72376
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19720
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88365
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191856
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111163
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87410
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99539
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60449
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195960
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9510
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32247
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130068
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84322
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99637
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66599
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65650
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66509
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88366
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11706
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8171
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128553
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112637
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52080
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103538
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48074
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156079
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104852
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18552
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88253
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164644
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 47456
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70026
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94112
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86916
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148892
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129731
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94251
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80177
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9583
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123025
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158458
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36974
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69419
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155077
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140612
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97384
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55249
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141829
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 202810
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95006
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106372
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166463
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40022
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34545
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15193
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23355
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168692
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109661
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89739
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82868
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8097
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11573
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 229822
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183094
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 91535
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78022
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123698
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74986
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 71725
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165958
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113453
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49376
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82920
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97392
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229616
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50004
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18958
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71483
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97561
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223315
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171495
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203049
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101996
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 2678
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71205
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138013
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180466
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182986
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35445
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54081
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110798
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139239
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 122830
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43312
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139697
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66604
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 283279
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67160
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 180725
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 48700
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35438
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238529
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110883
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82877
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82594
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 108361
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63828
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89802
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 102062
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 285471
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19112
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120955
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190380
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72844
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43567
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130839
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13698
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20351
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25668
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95504
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47405
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146499
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247618
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 350121
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 248129
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232510
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14902
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239819
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100657
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 271633
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179699
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185235
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10381
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 202914
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 190095
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 261049
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41771
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 85595
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153153
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 330646
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 132539
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 282051
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140388
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155093
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165678
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113848
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 119652
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 210672
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169216
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 318060
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46089
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 323854
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20795
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242403
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242328
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 61946
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16467
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 354632
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 97371
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 209028
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 263213
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31867
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7878
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128719
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45411
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11750
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101124
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12529
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115642
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8272
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 47902
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96910
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13503
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15220
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110038
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5397
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58241
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34835
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57797
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42712
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94643
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53486
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30514
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45638
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29034
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20754
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16874
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71593
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12386
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 333168
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 154244
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102955
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76145
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10422
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19225
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84453
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96797
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92424
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116910
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25166
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10691
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160777
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50317
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65047
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73333
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9590
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91527
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65521
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53932
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84186
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40951
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27709
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91036
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68908
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33630
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7558
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79354
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83889
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108709
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10698
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23257
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125180
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110089
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19214
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26131
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162522
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 46813
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88838
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14678
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71706
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115451
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21822
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 248156
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91571
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34177
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16856
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12190
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172151
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21866
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9694
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186511
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10501
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139054
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90539
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82793
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31408
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128614
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 195375
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114189
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20229
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 95939
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41877
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82181
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59609
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16621
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108665
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114214
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137798
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98952
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 316426
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88914
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112559
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133554
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84960
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95361
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 407829
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226400
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23206
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 403873
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15363
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28512
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39652
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184391
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 340974
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 480606
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159522
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92708
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 59606
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 495144
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155171
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25992
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 269787
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99953
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 151961
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64007
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203764
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199845
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28824
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 147666
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229951
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 243882
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117207
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 252409
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68959
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14539
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13177
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148536
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 154424
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76604
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97309
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 253430
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 9468
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 318928
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14880
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57862
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 311042
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50148
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39752
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 79275
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 91244
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 307609
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17850
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203558
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 256556
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148897
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36242
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 308556
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51054
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73132
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7850
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38906
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77137
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25141
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155903
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5885
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104795
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55637
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33840
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10576
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89627
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45983
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127798
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 310965
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18004
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139706
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68452
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101386
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29075
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12954
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110585
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11259
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76255
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20966
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17529
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7466
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88008
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65432
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9224
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 49448
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166299
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33555
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207565
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22572
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66877
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30449
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81154
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52385
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 82965
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118811
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7409
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22009
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47948
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74094
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120534
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207962
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87204
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15658
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32067
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 44910
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155875
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39092
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132604
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7615
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77681
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69600
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6764
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34245
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5700
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129383
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95505
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100022
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10947
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48701
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36080
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85113
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82605
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76860
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 162287
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 175568
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22605
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28079
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16019
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74459
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15668
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226951
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102891
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54071
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22781
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47730
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5542
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80439
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81957
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65590
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8061
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164290
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65817
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80187
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73337
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72358
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54180
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111130
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17871
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63803
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62092
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 57353
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76186
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78474
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67169
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50003
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10360
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90999
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100261
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119046
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 44347
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69517
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 51251
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93627
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77703
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94036
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58960
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106306
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42853
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183937
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124829
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 175779
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99341
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92442
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7510
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193584
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62951
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27217
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79184
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128345
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191619
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113676
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100104
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60175
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106836
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92156
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76264
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121557
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103708
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 163499
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 37953
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87158
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193223
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82572
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8599
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 82072
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60187
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71365
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83390
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136779
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97053
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198110
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69977
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34511
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126295
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86454
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138548
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221096
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106325
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19994
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 61585
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12278
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 215241
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22240
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 375730
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49292
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43563
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220415
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96582
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21049
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 271173
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185966
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28703
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36136
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179173
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175340
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120676
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148087
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134486
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 100714
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135286
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56261
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22481
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60662
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104756
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161460
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44116
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15626
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 43066
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109449
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183118
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98890
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89830
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 21858
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 74070
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 243114
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221187
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 232925
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210485
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 113630
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 344424
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205225
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 80913
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289716
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170943
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205623
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 307404
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207036
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98344
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10335
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 168002
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54079
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149155
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157520
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67857
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59606
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10087
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52552
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7399
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71118
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9537
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103311
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59458
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83873
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124964
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51445
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59780
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9118
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 154223
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19478
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68557
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7005
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236361
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6566
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75937
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108069
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116379
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84010
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73622
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56452
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 183357
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50300
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98901
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86243
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136751
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7719
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82672
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83918
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5156
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51008
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46552
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27894
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 268136
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11813
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121959
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33906
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 133186
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 63116
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91963
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190820
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23030
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75295
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54437
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31471
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104911
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93186
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22998
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85679
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90770
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31187
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24966
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 252820
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55521
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63617
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93906
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 136092
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11265
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144594
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10865
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22471
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18849
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71205
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145765
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 127834
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35877
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73081
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167193
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85804
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41687
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74936
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44914
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75191
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35137
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124111
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92666
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10834
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 41275
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6258
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103505
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185786
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 228176
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7120
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65425
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115135
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81501
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10446
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95692
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25699
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42428
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76560
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143082
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77283
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79408
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122062
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74003
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74674
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104784
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79972
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 139123
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30668
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70948
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26256
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76223
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125770
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 289644
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78863
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17595
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77088
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 113648
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85498
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16692
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9566
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 273757
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88049
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110335
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59434
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83144
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81106
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 317873
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31677
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208591
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151585
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76535
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124951
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102385
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73018
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25046
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119776
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145810
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139730
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164378
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102694
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69757
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56936
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88965
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152696
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 244899
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117056
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11763
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 67341
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255587
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187381
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97021
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132015
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118606
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155944
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23770
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35996
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125385
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235689
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81112
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 153935
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120596
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72735
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28397
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77840
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36962
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11920
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12150
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23042
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210988
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27505
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155149
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7378
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197022
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 312335
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 205675
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247701
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 271070
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34371
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165208
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134730
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 239166
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44639
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 49911
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131471
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17478
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44134
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232962
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 232481
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 264987
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42994
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 254083
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 206951
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24380
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188232
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207506
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229959
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27566
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 131098
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84448
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221857
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 261392
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48874
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34362
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 59628
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97051
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 89728
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 29376
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 167316
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177494
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136389
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165376
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 10890
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186982
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 11934
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249895
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143098
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229509
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 110070
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21878
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102543
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92094
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73512
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23977
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9354
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74799
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9730
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78591
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13636
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 355239
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23163
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30074
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10632
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165916
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89748
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40850
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86622
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77902
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55984
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 132818
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234543
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64577
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142955
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16629
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98571
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169176
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26701
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36001
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102402
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14930
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19969
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48301
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166838
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96483
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157196
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82802
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47792
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6202
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235561
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109009
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17254
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31013
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74365
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117065
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144489
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71246
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71994
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102252
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119840
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67847
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5805
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10065
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7447
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95613
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135263
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112482
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64286
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14310
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19388
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106492
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 45008
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50176
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142349
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 53927
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51846
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16390
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50731
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 60873
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50768
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144340
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120293
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119094
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 81190
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155305
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42781
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24795
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32311
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30359
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66745
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55976
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18553
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 158485
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48077
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159952
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104307
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55894
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99173
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85938
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117071
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138126
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 71420
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26364
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237640
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97628
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 65036
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118373
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 84220
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44108
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95496
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49427
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119589
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64823
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59240
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223396
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136692
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81534
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84469
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53831
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 234670
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 69897
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104243
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12848
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88773
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90825
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10523
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49329
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88998
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35940
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199397
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84589
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152953
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37483
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69793
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 64937
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73030
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70495
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85434
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8689
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121664
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 128101
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67450
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88478
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40609
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75758
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67833
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52849
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79793
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39079
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17533
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93210
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102399
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78474
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91355
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134084
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72137
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104679
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12741
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71656
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201877
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13939
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92448
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87111
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 106780
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92221
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 73063
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66325
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9852
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30762
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233167
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146727
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74639
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86790
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247193
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141437
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28615
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 344947
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178203
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192389
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99553
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108168
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21301
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116916
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 172028
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223256
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10405
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78906
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101301
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103642
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108583
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176102
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58958
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78029
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104903
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198172
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80975
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63567
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190276
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101182
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 85920
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38841
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140181
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60875
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160227
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101136
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158370
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94601
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153525
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130022
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 91183
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15220
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20872
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86182
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28357
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 51787
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 274844
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124963
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83236
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165261
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69063
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 100713
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 166780
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29851
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 139219
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122371
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212310
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 53671
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97594
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 171683
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210687
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20328
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 42608
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 278587
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147451
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30989
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73355
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53515
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20886
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 99507
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60173
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 102792
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170962
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 237014
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 193637
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56943
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 302302
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132340
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83162
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25679
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 67402
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77261
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136982
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136825
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 241728
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162562
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69934
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95169
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99770
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7828
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50028
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 294311
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26329
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98541
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103879
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 290493
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248123
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 272870
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185317
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 90184
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122856
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105503
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34812
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53907
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55119
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130132
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65375
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193630
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 68853
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141969
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195881
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136378
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 54600
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 177610
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49693
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48987
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211202
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153505
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 83528
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 90380
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146228
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168386
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 10197
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209139
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20492
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 57823
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104958
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55798
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 79401
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 190386
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129014
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155559
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129105
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16558
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 95913
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217080
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189487
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16797
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191990
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14425
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9957
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84831
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18392
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24404
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59927
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7835
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55253
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239342
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54693
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110526
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32676
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66179
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14917
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42297
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44581
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23973
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26484
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128694
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19521
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186992
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29561
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20087
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77778
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13180
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97499
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90055
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 106183
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21268
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98308
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8601
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112203
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80459
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14635
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211154
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85769
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109902
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127300
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11818
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20421
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 400574
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86030
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6603
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 181229
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112024
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85938
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25593
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101476
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155873
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 47036
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75577
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289370
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32826
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 60804
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94661
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70568
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16313
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 112222
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161684
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183757
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11389
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70290
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71360
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49732
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93942
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8227
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105102
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32036
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171842
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 284266
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50751
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65891
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77342
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100184
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 82056
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12347
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247636
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89783
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10821
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183739
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 172300
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 164268
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78928
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6404
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 171063
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55804
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 344886
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89528
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22201
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97231
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 69901
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197108
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230246
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 268614
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 91162
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209920
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104842
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215450
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75917
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125764
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162197
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172908
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 95941
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167813
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 255638
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208309
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44205
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 252356
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36958
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131874
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 126967
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 139484
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235193
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187783
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51950
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185996
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189640
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200267
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235905
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 150171
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 66667
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 162368
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245341
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33451
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34441
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42253
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 21173
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196970
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210764
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69548
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 344709
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 34750
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 81515
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 297212
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 190361
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 59229
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149983
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153762
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 201222
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 254248
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 315679
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40790
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 139087
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90441
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57651
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10547
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13656
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97451
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 146302
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82312
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88119
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116505
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76784
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87754
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145503
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76344
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72393
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72251
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65132
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129900
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180137
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15420
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 194414
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49220
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4002
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63710
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79851
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75707
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 206205
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11447
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164126
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76775
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98957
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28414
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88632
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 216351
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23772
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9500
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51545
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111050
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18349
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48328
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97590
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97226
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5072
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93023
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25620
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144470
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113425
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28212
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60425
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67553
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13095
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69951
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48378
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63219
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 149912
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186679
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35622
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70460
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84484
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 35387
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84597
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74556
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114599
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102986
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26793
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25743
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113192
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50228
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15174
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62746
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 78210
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21831
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 51910
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 169349
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95276
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172717
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57102
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136184
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52770
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104723
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110406
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 143633
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143900
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113307
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128257
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29613
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48373
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54702
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 135855
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145527
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55626
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60117
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42317
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 137638
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75626
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51847
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83099
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20022
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251461
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 95730
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118297
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82936
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80131
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48804
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133501
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64623
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43590
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51998
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 2592
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76227
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69458
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104403
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79029
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235769
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15909
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242895
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 80462
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 226232
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151395
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85330
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24942
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 126054
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110159
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58853
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76698
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27962
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149676
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165430
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21852
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27156
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13295
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22756
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 106380
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63599
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81533
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26001
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31005
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112246
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182174
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107428
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141480
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33321
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15717
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99890
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58205
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98541
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132591
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191427
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26694
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176507
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15301
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 75152
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23672
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85614
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27080
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61239
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59252
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98233
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148170
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34520
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125195
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225106
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98479
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 274904
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73119
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24440
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 153637
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84810
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154252
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110030
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180903
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22643
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110251
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110068
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80511
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65069
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24832
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16313
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132896
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90127
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78079
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89196
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 95885
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91896
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112130
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7427
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 152251
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 281439
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100058
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150868
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23753
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37378
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62942
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165096
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77996
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94331
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142009
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144754
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82144
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 252726
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 15701
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23328
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144889
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 56038
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50608
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 206377
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 127423
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23735
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23695
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7757
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142153
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138540
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106534
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 262177
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24791
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99418
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144291
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133877
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13335
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 132483
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161236
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 76615
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 253714
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 264986
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137819
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20946
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 265868
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 190504
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7061
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116848
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 366382
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215463
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189000
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 180236
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 63326
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 293761
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 296316
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 262510
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 383914
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 34172
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 305510
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142608
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 145170
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41173
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203872
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208958
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34593
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12304
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8421
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99608
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4424
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78498
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32046
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10605
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87576
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141435
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26637
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98699
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13156
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9469
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64182
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7818
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48163
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67617
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98979
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18431
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71479
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 142388
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91604
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4769
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69412
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70137
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7501
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85106
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119685
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158850
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127524
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82261
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8303
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 129210
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21742
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60026
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104198
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7793
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58588
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 175115
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145106
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6261
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13470
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15645
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 114279
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5373
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6066
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89232
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110705
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27113
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118446
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12510
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 37275
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26032
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24966
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8340
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71715
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98219
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27675
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20966
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68066
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60469
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55591
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173819
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134778
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162769
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14715
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141064
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51501
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95240
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219958
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 194636
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184678
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228406
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95271
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 116367
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70261
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115182
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24788
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18000
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116558
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34378
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 258645
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189487
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 128721
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251463
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84365
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185100
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39266
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 101045
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186402
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 323971
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 86432
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14696
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110544
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29146
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63827
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99181
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44527
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66887
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 158154
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53289
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68197
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 118730
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80769
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155331
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 262128
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185566
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46765
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26672
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79797
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251784
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233232
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86473
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118299
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185275
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29237
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177341
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66126
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24443
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 156607
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 190139
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 53390
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178368
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265946
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 132918
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 130751
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18105
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 107667
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 149149
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157038
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 36828
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212250
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 94320
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18310
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29632
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 202889
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 73725
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 326927
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28027
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25976
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79219
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188090
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83695
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43552
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19258
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12754
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72440
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86076
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77723
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138073
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20052
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16767
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30977
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21645
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 207176
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117943
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108643
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25590
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91113
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62109
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44600
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85228
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39257
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8712
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69358
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57683
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70750
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52828
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26428
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23386
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10004
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61990
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7352
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148150
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185755
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76737
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19581
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19301
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110708
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15918
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76542
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7182
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116596
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10667
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89792
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141854
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 243130
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95944
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74461
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73566
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102084
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63206
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141063
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 174318
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90488
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91041
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13820
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121346
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66019
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 211846
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59938
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5150
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21504
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26975
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 250673
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55496
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69108
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115756
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128294
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133986
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40428
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 122735
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63055
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75221
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46156
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78485
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13679
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79481
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43896
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65812
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10342
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11589
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61014
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77565
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52138
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84016
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95137
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 56903
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55566
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23659
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81701
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13836
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15991
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62160
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108709
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109594
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17997
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130445
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180791
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106214
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67836
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 121515
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8292
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106186
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74415
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154174
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125418
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21586
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90158
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115043
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65601
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72738
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80501
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120070
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9454
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116384
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229776
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56329
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75751
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92490
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94719
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40357
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131718
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57652
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73841
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52384
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74612
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111885
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42309
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 372849
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179089
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 287457
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16326
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179903
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174985
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232591
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33240
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185507
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51777
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118508
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50865
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 304451
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 34075
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 66012
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 114473
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163647
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 308597
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 276152
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28994
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 268934
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61630
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 193872
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208126
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 382403
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 91750
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98317
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 7452
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 243499
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 463673
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 74891
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178871
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 75392
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46367
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 277567
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44519
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 151162
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 76790
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52799
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23041
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64537
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115770
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70747
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26718
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102713
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66416
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19489
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105604
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64846
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196786
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104128
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22655
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 37054
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 135200
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9974
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85556
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125824
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 68351
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30887
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116328
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42303
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82865
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94886
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100875
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82228
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88606
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89283
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15905
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82434
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75005
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81286
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25738
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22008
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25382
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82038
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77321
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45902
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8683
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13656
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131716
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78592
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73352
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25079
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73577
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67273
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 56009
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44388
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12736
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106709
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26718
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101624
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27300
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61605
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32801
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85367
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9150
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87399
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98964
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149210
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118996
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98866
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8843
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20656
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59017
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6842
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66361
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19889
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148432
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65649
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29116
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74719
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68217
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180654
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83907
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 64483
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97129
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6604
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68552
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16616
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7058
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 220337
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149100
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8827
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 151992
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 41648
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75164
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74971
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78577
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26307
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104169
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61301
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70045
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39902
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196653
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153777
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16712
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54585
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166521
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103944
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 247775
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111484
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 74965
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183864
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9757
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147941
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 160145
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23941
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132083
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8196
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43091
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142304
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78464
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 108032
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44500
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107632
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84396
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83613
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158742
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59513
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114811
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15632
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120515
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82409
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89567
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202196
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85773
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10355
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112526
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 157250
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251616
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 241149
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19059
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11249
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101119
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129587
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131237
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10827
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208125
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74011
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102538
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131134
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93942
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10766
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 269521
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93898
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18945
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114103
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 53558
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73417
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 213363
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87903
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73063
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21677
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189452
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19916
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57633
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21687
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193442
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11720
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64475
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113029
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148512
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128607
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216574
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120682
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127053
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108449
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12782
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27675
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94134
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130326
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6422
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74104
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11676
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66351
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81151
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 87150
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71516
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96838
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156732
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90650
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129335
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 49103
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82456
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23940
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 45626
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176842
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104737
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100035
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142013
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71302
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 288095
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16304
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124533
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96931
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46366
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134830
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113724
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 80113
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114716
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9145
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78774
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36255
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235296
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76479
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130335
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82546
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191142
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12032
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141949
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85943
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28823
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106980
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50890
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40799
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116104
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109497
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11797
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43364
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95544
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137964
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38581
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117822
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50014
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95908
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91519
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119035
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79042
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 136210
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40436
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58369
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192790
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 241559
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44725
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212437
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 109615
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154620
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110902
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136874
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31722
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 130274
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112385
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105345
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68282
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 91359
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9817
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201354
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47548
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196953
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50750
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70811
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161536
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101000
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174312
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16671
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 94075
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31578
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 234175
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161356
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27674
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216427
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229924
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97896
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20770
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 193068
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149155
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119949
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189134
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 73350
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78678
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 354208
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141587
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66396
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 144568
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 365410
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31886
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 306659
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31062
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 250867
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63706
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134710
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97821
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 68080
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55994
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 350793
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57907
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161717
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 85800
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 130917
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197318
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191015
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176995
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242500
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15144
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22551
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219607
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 237525
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221814
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148219
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 29537
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186346
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 162091
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 15885
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 276707
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196132
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13632
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245519
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50138
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52210
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 121455
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119104
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39556
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74818
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72764
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47673
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99172
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71489
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7389
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51308
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150388
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62766
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55081
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92823
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109951
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74840
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184590
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48486
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36965
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28387
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42232
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9581
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78703
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 54871
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17018
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87164
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10204
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 296003
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62580
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19900
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121306
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159094
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10036
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68506
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49496
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9535
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47662
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151309
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59596
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18557
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120540
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7767
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15380
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58963
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208719
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11218
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100243
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78399
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103408
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89046
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4486
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71001
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103312
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9774
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106792
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78580
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168184
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55373
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81521
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137522
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39785
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14717
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 89870
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63714
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81873
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29151
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21701
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87969
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102880
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88674
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81002
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105966
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50525
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122546
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 28401
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 207929
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 90830
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67241
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95337
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171720
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 474654
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23857
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114242
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89282
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46440
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154165
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17158
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 151660
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25473
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176801
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123380
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 208592
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33530
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35934
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 206471
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100138
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 160633
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 113962
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29088
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99785
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38136
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12390
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178610
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 127557
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19711
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13377
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147198
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 341939
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230677
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166621
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 75041
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16013
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 408233
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24041
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137641
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29005
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78521
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7135
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85469
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115327
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72498
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138571
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130714
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16439
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 13040
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34635
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101854
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16213
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182696
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25608
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 281026
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 47580
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79502
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197429
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179520
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 243134
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 207990
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137476
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30670
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237630
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22658
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 263475
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289232
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228258
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193226
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222033
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 280052
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 78819
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189192
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 17973
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22021
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181075
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 109136
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 256359
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 246747
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 66538
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 171280
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113925
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 282058
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69315
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16967
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11092
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 42225
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40841
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 74453
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15099
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96553
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10346
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57972
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6777
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68943
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63596
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10960
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201655
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78263
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158957
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27596
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235484
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80558
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86467
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91812
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24306
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103844
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70529
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154941
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131973
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186918
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21970
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124027
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53762
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78809
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54377
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143650
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63850
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37348
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43428
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53490
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47417
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8735
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160891
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195693
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67715
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28705
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22176
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40065
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125837
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99835
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15919
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12739
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83895
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24445
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7330
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34092
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89027
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53104
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6912
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67001
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77432
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65104
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76911
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75220
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200884
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9485
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95324
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15176
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25232
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8547
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103365
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165046
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 45605
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59389
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72499
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4839
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230410
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69336
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94059
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109956
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103676
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73440
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93311
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46497
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86071
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129472
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67606
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14965
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143442
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6408
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9044
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64420
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19908
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16989
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23794
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35598
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89777
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85198
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 54389
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26883
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69476
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77450
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31754
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69707
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62401
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37101
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7572
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11599
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28028
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99453
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73552
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23578
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126839
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11473
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9464
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123819
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83893
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12820
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92222
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131201
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 89279
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4534
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72322
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118800
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150495
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115379
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 75626
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144287
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104852
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189624
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3814
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176900
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78030
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191345
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86578
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42362
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 62941
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35483
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139885
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 105163
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32617
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22105
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83289
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64208
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14089
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70745
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162283
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19659
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19524
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85444
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111669
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 280282
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182867
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54203
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 138244
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21663
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134269
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63506
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66245
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87859
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9862
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145836
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212472
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 314436
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 422813
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81364
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191847
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67777
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 158249
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100028
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35611
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75538
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116707
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77511
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231960
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29182
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 116712
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150110
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 159324
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212030
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91329
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 160445
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202354
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169576
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81704
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225195
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 79453
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 30121
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 143410
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18360
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26606
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57993
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169763
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101845
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 293058
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170854
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5931
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 257165
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 273658
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 116021
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228591
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142573
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6914
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 90167
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157300
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 146327
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 93526
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187129
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 90528
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 177710
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186701
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14450
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218574
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27851
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242217
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194284
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28367
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 73849
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52577
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255919
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165145
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 370220
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 58519
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 154953
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247371
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 239942
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 342669
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 302004
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196200
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26212
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 21336
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 112486
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 226633
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 265484
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193667
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 101059
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35697
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 111660
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 90326
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 487716
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12184
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74290
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86595
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78085
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11585
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20952
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12414
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4271
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 243107
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66735
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57137
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11802
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91336
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84209
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12271
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82899
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40630
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69033
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87837
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15531
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5203
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58047
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96782
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128665
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147696
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111962
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71841
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77508
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48183
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68190
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157807
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15070
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95650
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51510
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34734
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91361
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178282
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 253401
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 77593
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31199
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65487
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36556
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27367
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113048
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61937
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 38264
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195572
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115291
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74394
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 44796
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19328
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15350
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7468
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88432
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20333
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69022
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63315
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127264
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17844
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125093
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54120
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28003
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100818
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27936
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99086
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42297
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12674
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111135
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135639
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34116
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114045
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92743
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76870
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16066
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 195402
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 99416
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15655
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68624
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67301
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75023
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19890
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57168
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112183
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183812
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81917
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78169
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19111
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119740
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50637
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172348
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98102
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85475
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 217047
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5033
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88799
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22140
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15278
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96419
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22855
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117248
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82819
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80650
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63498
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225089
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43462
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82761
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 183714
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117688
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105827
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175019
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105177
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60618
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9689
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86233
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 78319
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201692
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171704
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22641
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32343
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102615
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17021
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8259
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 60619
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20571
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116215
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127836
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 215601
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5164
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25097
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 200296
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9468
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18466
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199814
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17172
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224245
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77811
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 35267
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 64201
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116173
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8741
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164381
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 279469
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212498
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172329
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82308
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 139872
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75546
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26025
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226030
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27179
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48280
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120438
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129274
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132014
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122838
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97785
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26813
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33592
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116115
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174515
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148626
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151264
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80468
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127969
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 54917
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24781
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 43974
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 275658
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68644
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137028
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101755
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 6851
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187877
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33964
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8311
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177966
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158583
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23845
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 164470
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 136954
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 105570
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170934
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28576
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224395
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177358
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184009
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42325
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 226983
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 66494
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13134
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 17276
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96999
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72669
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48168
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 169842
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127793
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8558
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142846
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47192
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176787
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6309
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 163536
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13259
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73269
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 151468
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14523
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28442
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84758
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122822
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50379
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72284
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69446
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 37529
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12009
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107819
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109522
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21661
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4411
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17162
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79500
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135039
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4233
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10789
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109015
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52034
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78565
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75467
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128944
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14973
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18103
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102268
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53425
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37569
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12208
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31044
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 69235
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77140
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85807
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22770
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62386
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96782
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69521
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89696
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70752
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10599
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13896
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88739
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 143775
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26000
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31163
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71053
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51417
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86576
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77283
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5784
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52347
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5708
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4689
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120653
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180823
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6877
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 163876
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94052
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9836
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 175136
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77295
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145329
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18733
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65475
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 301728
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49260
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22259
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69725
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16789
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102508
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15501
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73578
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121381
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30432
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15977
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5309
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105168
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20262
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102250
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76348
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112794
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75316
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108664
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54073
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140553
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35067
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112368
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55451
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24665
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 122042
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62182
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92689
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 264639
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142634
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 132232
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41395
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78453
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64535
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119703
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 84838
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44427
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 41185
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25239
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160566
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117995
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181700
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 176408
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42076
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 255438
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 390005
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 314473
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16854
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153549
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170460
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 344870
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195770
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138444
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 142077
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36168
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249334
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 298234
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55350
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 73001
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198775
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 302221
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23638
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 116375
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 256125
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 111914
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227847
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40819
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247217
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203257
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38584
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7414
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27230
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9615
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22892
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81717
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76281
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63755
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17931
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167552
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90047
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172096
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84455
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10003
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95418
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88839
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86381
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90079
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90521
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122842
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104532
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8482
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69848
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52929
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48424
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71853
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50585
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22305
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 200665
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17288
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95790
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52933
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17941
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10266
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75748
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132111
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59528
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10987
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67350
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56279
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109956
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22978
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78173
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21878
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26607
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85498
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4699
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11775
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96004
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77323
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73399
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96618
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17752
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116118
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13011
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 97367
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47247
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99646
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8598
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31063
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163051
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143293
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13101
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123069
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49090
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86277
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4377
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89071
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93187
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36487
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19008
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122731
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183457
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23132
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31220
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115521
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25640
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120126
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85650
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191529
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87548
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78830
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68143
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11021
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26255
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54866
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29669
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112840
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26212
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18435
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8337
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143074
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89610
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178315
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50521
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13144
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47914
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56941
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90089
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101280
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34642
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121728
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92421
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26237
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56404
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32674
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7231
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154112
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18071
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100880
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154053
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100358
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101103
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74123
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71387
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179787
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88559
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71312
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117045
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188788
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8440
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 56158
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 203798
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100360
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131796
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31275
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12440
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53488
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68783
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78359
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 157441
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21400
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 52933
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108899
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107049
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11332
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36248
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12364
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95309
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105939
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25524
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203605
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 60805
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227884
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 47374
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32561
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 261660
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210660
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24396
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13516
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91781
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130949
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106318
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51040
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158095
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76404
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112032
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39442
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217350
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16487
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91045
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150017
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116219
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109654
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137873
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146215
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135703
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181131
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100140
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125907
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88115
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130122
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13483
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 178172
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118409
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81662
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110500
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28110
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34668
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 190074
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50819
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42011
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 29643
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190264
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 94927
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 257406
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146542
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 312132
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 350795
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 202313
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 321142
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 319608
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 83163
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23671
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95072
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186455
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153942
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 105355
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 194493
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137832
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138226
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 375707
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69202
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84641
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245039
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 199113
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 58606
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38143
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20656
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103216
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46170
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33939
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 194722
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163077
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 75614
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 90051
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 158245
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44702
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212339
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 91345
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27495
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51333
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118113
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 80728
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39637
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 107942
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 110780
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239406
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 205322
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149262
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32904
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50343
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181691
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 169007
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40314
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7111
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60850
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61203
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29114
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12067
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80839
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69606
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10555
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48304
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12922
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31703
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 177533
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9429
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27996
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7031
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39912
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56045
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69722
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190501
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85956
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70918
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19863
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42646
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24376
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39149
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 113367
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9852
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12501
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23844
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29466
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13801
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83553
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 74767
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59773
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24087
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86145
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46168
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12814
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148412
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67524
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103020
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13285
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 257548
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39317
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59493
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11048
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117497
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119638
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 200421
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26288
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78274
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76011
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25701
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99962
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83714
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33027
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184525
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153486
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45144
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204576
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24687
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113160
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65576
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73249
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58029
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81229
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35542
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93163
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67927
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77089
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97646
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64413
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95213
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69421
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57466
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 99738
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71323
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34951
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85612
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46170
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108507
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229735
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208839
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82222
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124437
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139265
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90939
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75818
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21597
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113089
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74983
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183643
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30887
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90487
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21843
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85782
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83189
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 239823
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127230
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56311
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17270
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34412
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157542
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21536
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91933
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58328
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133688
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14465
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14138
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 64822
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 131136
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101556
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59957
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55841
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106948
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54984
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68245
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91155
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93694
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47933
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52194
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96665
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 161521
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28818
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 492973
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89412
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 39460
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39369
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13525
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125398
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 56706
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64686
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106962
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31967
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36195
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93067
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22306
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 217354
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5910
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96338
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103734
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111735
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 101173
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70354
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26308
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11956
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 56962
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 100238
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 71593
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18577
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72566
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 249027
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80662
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85216
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73235
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90654
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90397
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50778
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195159
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61094
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125630
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87757
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 63822
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31025
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32760
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77728
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170825
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67877
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71487
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47461
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109429
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 204016
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92163
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55711
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31205
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80754
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88440
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 48098
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56115
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125462
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36969
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22075
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112304
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 195660
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17481
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76157
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76903
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170348
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47548
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 129858
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 39506
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83389
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137798
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145445
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92911
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69763
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12848
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12838
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30558
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9705
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114214
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115798
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123535
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5946
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13066
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57857
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 134589
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53601
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67371
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9096
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46411
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92069
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163689
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 322471
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123966
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211789
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124668
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83821
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22511
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124931
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11149
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86595
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66805
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75740
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41569
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68675
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 45693
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135436
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 158951
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92793
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147673
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16754
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131961
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 253149
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30790
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20428
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78417
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55148
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115280
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85834
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173539
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 90602
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10648
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 43830
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53171
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 243400
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18670
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185359
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 150314
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89077
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 50054
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120521
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221884
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 128835
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31115
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 263395
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116964
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 91251
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 338531
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166406
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209387
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 207123
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42304
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31022
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87817
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52354
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155228
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89504
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176144
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93599
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79517
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120578
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 94739
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87708
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140629
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107225
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117420
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 271934
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189499
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17183
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32826
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 62226
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74488
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 349924
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157448
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218088
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183806
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72300
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 240163
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 246000
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99863
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 156356
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 67948
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 64979
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53568
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196855
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 84791
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10273
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 273787
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224114
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169431
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130305
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 98091
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31052
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 212005
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50978
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 165948
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 310393
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19755
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121458
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16793
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 291245
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225171
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136886
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 299741
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28092
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154309
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154018
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25482
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148357
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29093
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96615
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140096
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 85853
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69249
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 416264
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 157151
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117189
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 72620
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118421
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 305396
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249227
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 168848
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 71856
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51348
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5822
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94095
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10955
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17577
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136182
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19224
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73636
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143027
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34327
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67196
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72767
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95990
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 40284
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40108
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6944
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83515
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55610
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63650
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93046
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 219274
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134925
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83369
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59047
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81123
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 253245
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90807
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75652
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9659
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95814
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119723
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41070
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83421
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91339
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82372
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 132449
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36769
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227382
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 121440
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149507
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24851
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7429
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89495
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 256314
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11569
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88843
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7960
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123615
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69348
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79456
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69072
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 209620
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26442
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63170
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12026
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5616
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137944
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211032
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74525
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135728
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 45672
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91461
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 97607
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9893
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5833
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108676
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80552
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20510
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107366
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83127
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23830
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6327
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76285
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112372
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109014
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77862
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91415
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25652
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152488
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 60911
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80888
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136760
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29696
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83955
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34722
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220261
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67512
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176272
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106342
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29241
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62795
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84279
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74163
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92955
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102602
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111124
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117684
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140971
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87116
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68292
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205914
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 46954
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 29885
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30515
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72934
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27966
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77436
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222933
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19804
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126332
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47070
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63937
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114101
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105367
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110268
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57190
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81722
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78842
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 125142
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187008
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 107261
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160820
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132244
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45865
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 204007
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43473
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211405
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 235492
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130933
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110762
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17767
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61777
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105093
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68559
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45832
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73658
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20127
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117263
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89048
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 266109
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 111864
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238689
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 96235
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 356070
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60209
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93620
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68572
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6961
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165332
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109784
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74189
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 213826
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21221
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98737
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57120
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 50877
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214126
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31558
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 78349
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 279513
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92164
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89921
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26211
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159553
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 132503
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 281205
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61573
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 85963
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107727
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192331
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63439
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 287988
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171680
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60574
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195652
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 111514
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35480
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 146268
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24681
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44026
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 239508
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105778
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 295414
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 135932
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 344635
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237188
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 224177
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179548
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 52019
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 283848
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138967
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59245
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 100404
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 59349
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 277398
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 80771
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218089
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 70225
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10970
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 313042
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 215173
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 485438
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159969
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 52279
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 326642
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 256151
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 243058
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155530
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46465
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 433334
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 178883
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 426999
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 226503
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92023
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62463
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33244
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114928
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86789
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81671
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104376
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78356
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 195176
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 205836
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99200
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107987
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 99374
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186256
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41671
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65874
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134346
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89378
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110157
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113392
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103401
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118574
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59701
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48646
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140951
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211590
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21109
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123127
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289659
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 124966
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189492
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34866
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35262
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21171
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30143
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25191
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69098
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174402
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24757
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113047
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15553
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44836
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80605
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58860
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91799
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 53179
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125135
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66955
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146733
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 131497
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 255050
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133404
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 78745
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20193
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12630
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229569
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42490
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118985
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196711
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52757
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25065
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75039
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 110693
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 95148
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 95541
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128065
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27942
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 323953
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 75655
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39475
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120177
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 397335
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23386
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 359067
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 317218
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121238
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178837
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54894
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175067
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133939
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94671
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 94706
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144864
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 323361
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173804
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 213047
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17485
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79971
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 60562
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53068
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90076
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 333425
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196277
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29118
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198413
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22095
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80238
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 234373
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28747
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 281476
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104945
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 318718
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50188
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22105
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219719
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63474
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205461
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173808
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15609
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31424
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138388
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219745
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173090
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24873
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178063
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181561
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 141386
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 280632
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 79496
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 168419
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 84821
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 510317
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 137658
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222368
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 239762
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 255213
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 17040
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 425025
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 72749
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7613
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 320257
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229830
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299677
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 236842
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54838
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11284
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110709
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106774
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80841
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60418
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54127
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144697
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26535
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101488
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17388
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13863
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76596
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99599
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 192411
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61484
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111229
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32453
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10777
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62580
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 189911
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145721
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82369
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15322
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86357
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63614
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114591
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31575
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19801
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115177
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74267
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40379
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79466
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9632
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140463
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30184
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84089
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97846
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41082
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93324
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31450
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131692
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99545
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43173
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 201575
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 215490
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114982
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67307
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94736
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89154
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162630
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74054
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40574
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8736
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75602
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22606
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91700
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48158
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30588
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66097
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36475
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83130
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70297
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27748
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95942
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69741
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86810
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 135564
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110072
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19286
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69032
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101583
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 203432
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22921
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80974
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4968
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118618
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57323
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122303
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26800
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73460
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48393
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68917
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186345
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86844
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36712
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45980
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15080
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28041
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75719
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71296
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91995
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84508
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9798
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86846
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55179
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108883
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71104
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61299
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148831
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56497
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90651
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100463
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17112
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109635
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81904
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13390
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194124
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73426
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 41317
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82259
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103322
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41638
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20253
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16553
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8250
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82463
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34529
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69616
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 89672
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112694
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195055
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92886
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24598
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80708
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92646
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42963
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16684
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39629
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6141
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100028
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132280
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12152
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93394
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 58062
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133352
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144325
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91392
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73524
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85828
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74529
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 171555
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 44949
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81885
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102864
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93406
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 82870
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85727
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 138146
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180352
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174948
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110700
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69649
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 48701
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5724
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25678
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39588
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25385
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73380
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12802
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100713
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85911
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151261
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105778
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86156
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182439
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104632
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 215477
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100021
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22575
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85067
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99888
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19062
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88322
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116916
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129156
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190271
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3819
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185516
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15382
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74252
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115456
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61924
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21202
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 115481
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 304979
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92544
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115664
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59504
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50679
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191927
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58540
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115397
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 70000
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88883
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75134
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103020
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77444
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100331
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107196
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195069
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106236
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51079
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 65529
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12988
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8326
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93832
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56868
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223722
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152893
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36346
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53094
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188525
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87199
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 156911
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96368
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 69000
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183455
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 160963
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72764
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41650
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121051
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82432
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108550
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87091
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 174376
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63551
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92115
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60284
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 11949
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 302128
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12398
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99409
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 334035
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73728
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 5259
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116043
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11159
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158940
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68870
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 60697
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225150
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125896
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 108615
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 162579
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 272041
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201384
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39997
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 468366
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 222343
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49150
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 332134
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24422
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220475
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 111040
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191270
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219121
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54849
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124162
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50678
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12987
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187660
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 12002
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66974
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25843
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 340706
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 143684
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98729
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 144203
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24479
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177986
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81821
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131338
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17999
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67339
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9292
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95207
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30951
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62326
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 37064
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65437
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59508
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10178
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51801
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45908
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11026
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48770
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24632
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116342
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14527
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67014
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24829
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76966
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8888
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100643
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22493
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35820
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113269
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73392
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46810
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99994
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26691
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54063
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75661
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9308
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22096
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 49155
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83329
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43108
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8441
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30063
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80316
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82839
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76130
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59110
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51364
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31741
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102167
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 200157
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35663
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54105
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76800
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133140
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31158
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13382
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187862
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66102
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58143
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152109
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85646
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44285
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101439
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87452
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59776
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91857
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87153
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24529
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16666
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137899
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83730
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74308
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94248
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83308
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52444
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87384
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57968
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152493
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109861
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7779
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92961
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11926
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79815
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61347
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8225
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185117
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 133118
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11753
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122611
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17963
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43610
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38230
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178620
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102072
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38596
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186590
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99673
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23889
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 51620
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25016
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163721
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10216
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25796
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74301
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20479
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 46491
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59872
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186536
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113377
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222707
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65869
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46931
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80033
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 42665
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77398
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31554
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143888
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76403
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94076
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157070
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11091
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 58483
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5779
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137817
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132467
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56852
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118211
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119402
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137327
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129216
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171043
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13357
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70397
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93604
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88847
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18557
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 267599
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71137
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34801
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7316
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101062
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71508
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74196
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176952
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152882
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 150086
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236047
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4446
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8138
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46696
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68203
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9991
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104016
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57327
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44968
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130874
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109286
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25492
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22132
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117973
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23302
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16299
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84985
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185614
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44709
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80101
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47134
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152210
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66301
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74923
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26670
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133220
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78109
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104868
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 73662
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92614
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124790
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70824
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67447
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25300
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74366
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48023
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 55250
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169262
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150625
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72582
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 14537
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65156
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14686
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 70542
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99648
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57986
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79180
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 149463
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 102433
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179822
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81916
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18155
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47532
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148895
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 250501
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69528
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 43746
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210748
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216151
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 152595
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8200
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36741
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87975
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169381
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22107
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43037
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 30326
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216655
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108043
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 154427
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120011
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18983
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106516
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226224
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 447642
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154103
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75497
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57508
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100151
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 42405
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62518
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83267
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4823
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67021
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81366
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64648
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7058
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68491
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13333
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103880
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53171
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124864
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 65095
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71047
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83048
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131351
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49880
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 69683
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49458
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26390
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 52755
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85075
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11677
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10534
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87425
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81220
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100736
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158095
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85513
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113898
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105477
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88827
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119641
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65243
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123298
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64571
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63260
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54813
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68832
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75828
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94167
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95773
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 39685
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36389
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37865
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 282691
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103890
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50781
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224522
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82563
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 56183
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106524
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74981
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77644
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78129
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124894
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158966
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 228268
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25934
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 36483
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82991
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15364
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73848
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21026
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 153739
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 70913
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100594
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127676
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50623
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78262
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106010
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119048
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27941
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66491
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27433
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12969
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57500
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104567
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56531
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26878
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170204
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19718
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128856
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87355
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25862
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151091
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98911
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51760
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112243
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12566
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65965
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56827
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 214479
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47279
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70335
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58842
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136591
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74037
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9844
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15732
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131362
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 102250
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95504
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9279
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10504
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152947
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25498
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105093
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88654
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21806
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81343
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103947
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42384
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 2993
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119748
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112535
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18266
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 213427
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10811
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133095
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17756
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73393
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11166
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77480
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140725
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78632
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70966
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 199896
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 143257
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68049
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107949
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6187
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89594
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 49268
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54116
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3832
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 73722
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23467
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117460
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95984
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91776
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89726
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86117
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34302
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74697
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78847
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 207607
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189618
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 267125
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116089
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117138
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90062
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103048
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69297
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221720
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23685
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50564
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131582
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25750
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 182554
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83291
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6099
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33536
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16746
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50917
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95051
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117230
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21313
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64122
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15236
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52724
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57025
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79020
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9840
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16330
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85090
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70191
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148739
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221036
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8529
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49888
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92402
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88556
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18581
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38697
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 90944
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128366
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182281
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191459
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 141928
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132896
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112188
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36686
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113906
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107208
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 189479
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55760
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 48371
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27647
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 45777
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 201913
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158528
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 333611
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172318
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82430
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 99584
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9416
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16722
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51822
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101533
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91910
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131758
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116879
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100681
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 34840
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92259
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59307
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15129
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 72625
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91009
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23698
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156147
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109961
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82606
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38889
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12611
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11605
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56150
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140385
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 269553
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 178361
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 366745
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 208769
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94966
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8495
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 318010
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158714
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60285
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76559
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177519
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76587
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105289
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120978
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 64542
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164700
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180090
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37047
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93325
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16386
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97689
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31526
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 15777
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143034
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130532
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19184
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 215152
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219235
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102148
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8568
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182108
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 214418
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 177163
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199980
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95245
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235611
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 71978
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 149106
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155465
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12642
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50587
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166927
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123919
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63045
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11462
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91547
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156654
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170781
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172119
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154683
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214624
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84949
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159580
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 45518
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64488
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91304
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9744
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163445
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118677
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112215
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 475479
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151944
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33483
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 6909
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135367
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17597
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73332
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107403
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182438
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21629
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171779
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 169752
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94065
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58365
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50749
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113424
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219231
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12702
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 122340
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 43639
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20483
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 209899
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229808
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135478
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 172497
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 81812
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59576
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139591
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 10376
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211162
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 119799
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 84424
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 13051
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26908
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 108508
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229184
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27471
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225389
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 510115
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 179502
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 182250
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176540
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 104450
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129435
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 275205
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64642
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 338427
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171524
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 96516
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 107566
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 332520
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 164103
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9967
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82533
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86536
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109175
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51763
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122023
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17305
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12184
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131950
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207048
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47034
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54154
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163468
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186150
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116946
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108247
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115806
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51554
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16688
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85445
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61472
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29011
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66016
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 151955
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60676
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91068
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198143
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26404
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80090
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203408
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100725
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78141
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61713
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75926
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107779
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72455
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48998
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106805
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 264589
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67521
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31602
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61951
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84703
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 245733
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122643
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216505
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 86059
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79929
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16695
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86169
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16710
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7716
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160264
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 206905
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133479
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13868
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85031
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78059
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86430
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7038
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19043
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26820
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 311669
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23642
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126558
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29706
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14086
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 48680
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126753
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26306
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58031
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100698
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70786
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 75895
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23900
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134164
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156351
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85706
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18158
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57154
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160581
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12068
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 82630
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148895
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 20333
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46014
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86215
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21532
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71958
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125819
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182170
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110357
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32758
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10615
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58295
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101278
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166059
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58306
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21212
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13688
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66869
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75553
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63985
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86593
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81384
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140680
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16813
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34856
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 51890
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5959
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97914
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120983
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14712
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15237
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121996
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 35704
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 154306
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8985
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 95159
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78297
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191046
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102048
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43124
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9978
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10880
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48299
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86158
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100469
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157081
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84512
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101094
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19771
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54093
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19383
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32523
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 143542
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175493
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97234
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165417
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 96912
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88380
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97104
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84835
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217683
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126347
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95389
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16697
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52908
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43268
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83128
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 142907
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33676
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69281
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 129394
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201264
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17902
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75290
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63245
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25482
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89309
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12467
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114238
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95791
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183897
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75574
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194146
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8808
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 59555
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94852
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125984
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29738
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65510
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89369
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52436
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 147846
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140021
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27269
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141479
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91328
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130398
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 301229
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28184
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4578
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 78149
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37439
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119309
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64072
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187619
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137345
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90451
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32997
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46909
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161854
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101721
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14740
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 241233
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190275
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70740
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12010
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11843
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 59125
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205790
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36088
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165140
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55060
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111938
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100540
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4390
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7156
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24497
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12524
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47432
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55086
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69050
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4368
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 41335
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23110
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93065
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45154
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 424172
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46529
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223865
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247969
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120306
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135807
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173378
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197879
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117125
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 42992
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 316462
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95224
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3914
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77890
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 125020
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 180651
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104419
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 11048
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187897
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211143
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171003
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223361
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27847
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94993
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14145
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113328
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43654
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159877
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 64644
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15711
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 43837
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157961
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58744
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5832
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 82598
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64609
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215434
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22797
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114156
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 156953
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 122717
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 58331
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68268
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189570
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15572
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72238
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193909
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 354964
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161146
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30304
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 300368
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10667
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64082
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108524
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106618
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 362553
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99185
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 69104
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52485
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35173
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56937
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123940
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150865
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73363
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66361
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8363
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195444
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53690
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107239
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180735
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21981
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221625
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115985
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 256249
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74043
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174499
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73679
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 123311
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196110
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26488
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178445
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196200
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116370
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 204731
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55469
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30662
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81057
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6908
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159014
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 83205
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78743
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17264
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 63391
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149564
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13314
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15012
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121008
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125224
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228015
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 318093
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125528
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41088
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105054
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 51184
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99241
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50429
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110405
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98140
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157095
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 325165
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238508
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27369
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68002
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53508
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150069
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 38517
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238495
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118162
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8055
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20497
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8988
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139173
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 266841
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167282
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65129
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74245
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65056
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21778
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56714
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 329304
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 352231
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110860
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30947
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 149335
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218787
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10747
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247921
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154049
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45424
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79882
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170287
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16993
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39625
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72820
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 54378
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12232
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 271949
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229857
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129340
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 276799
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136048
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17670
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 71812
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157486
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28867
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 12885
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77270
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225443
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235436
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31845
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46184
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72629
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42395
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57940
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 278416
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192009
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 296975
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208536
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145589
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 108575
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233234
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 195079
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 218792
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19637
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174255
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 318644
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47075
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33897
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 65501
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79294
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24501
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67810
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26613
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44032
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50696
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70943
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118921
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64222
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65847
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10961
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183138
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65378
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17424
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11240
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21507
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 3100
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37444
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 128808
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44963
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11072
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3933
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33644
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 235076
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94843
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5371
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119378
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93341
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10130
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73316
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8982
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144564
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 207470
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8624
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24416
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10840
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70079
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72940
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74892
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18054
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 55771
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26474
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88271
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20049
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116567
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115816
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180090
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42535
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89543
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191484
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 68959
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73195
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58459
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56284
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106894
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73894
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95381
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117330
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78213
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12965
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73438
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109025
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84221
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25213
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13283
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 218177
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88592
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10244
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116495
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201009
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10021
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116716
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7922
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74400
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70155
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86660
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82863
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57980
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105205
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149956
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24154
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56189
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118973
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175212
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 167731
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13259
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78371
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 63791
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80836
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15352
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9270
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104481
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76536
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 47105
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47526
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55344
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4206
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89449
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125956
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10529
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90769
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67613
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8390
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43828
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82720
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106786
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45618
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47549
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50289
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 45811
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 40357
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72511
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181460
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101452
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11228
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77799
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140449
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139872
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110663
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81715
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76455
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149991
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195592
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107216
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23839
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 110360
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116360
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146008
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128851
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75093
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119307
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54332
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82707
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19983
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29133
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53293
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115034
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82621
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136703
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88467
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102044
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 56777
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136920
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65237
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53669
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8265
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71395
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89835
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13419
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137960
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71258
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134503
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88971
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164556
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18065
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84715
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89362
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102538
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 102764
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109765
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16621
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194280
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180865
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 195401
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 42464
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73274
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42439
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123607
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 83397
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94265
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105978
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69312
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248751
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 265509
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45674
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88839
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 65637
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97983
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59305
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111309
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109890
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27478
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23019
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112485
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93856
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48888
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74899
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163339
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115725
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134382
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20156
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9094
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28858
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26986
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39640
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185587
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146139
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35312
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89603
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18340
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165520
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134333
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217807
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46533
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58820
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114046
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34689
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183752
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27785
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13072
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48436
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58119
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121681
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98655
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198371
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77341
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154713
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46250
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94589
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63933
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78630
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77854
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78965
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 70572
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173648
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64562
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44717
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20804
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 285598
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104783
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29103
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186927
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56303
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90499
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92344
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 210899
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225171
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 132842
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72548
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100487
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99009
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95164
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97904
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145531
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30103
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53298
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 300295
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36158
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76802
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86533
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80658
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 133174
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90776
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124382
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13970
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 352876
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97950
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97718
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 314307
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 76784
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44043
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12166
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203243
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15357
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 168286
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24703
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16609
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 414742
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 335695
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159830
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130073
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 161179
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 325423
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211782
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 58554
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91969
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88535
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228223
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20081
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25697
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 169673
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 152235
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156035
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52286
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124867
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 373283
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115925
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148957
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36297
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 447225
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235457
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20032
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63529
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22181
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74923
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11892
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52881
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56321
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86765
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 44638
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93318
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153260
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101474
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 292224
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17739
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20820
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71376
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88062
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116101
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 178137
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11023
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6808
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67645
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72813
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46125
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38984
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82799
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67123
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73017
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106967
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7387
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197090
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112110
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144952
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52306
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51702
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31123
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 401497
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16869
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98488
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79678
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86962
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19772
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15710
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93962
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111723
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90882
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24365
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24535
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 140348
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37649
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71710
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23475
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124975
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 381457
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64960
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130488
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75510
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100855
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 100187
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 129825
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16014
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55587
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92471
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32543
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 257003
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 219224
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85681
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94279
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 65399
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119318
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86483
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40646
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79390
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 273937
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27025
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96349
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239840
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24285
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140930
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 106087
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73736
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207104
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141996
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25515
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 319888
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94350
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 218802
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117871
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14048
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37709
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69109
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27526
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31012
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50138
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203387
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144881
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11072
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 130849
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89905
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4674
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26005
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28158
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 90252
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34978
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223587
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167786
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145661
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119943
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75676
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 173381
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52788
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10035
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109479
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52007
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 167018
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86029
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209480
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 55750
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108588
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125151
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12590
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 48148
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15916
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93646
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 415700
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 293132
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101807
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 285137
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118467
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265062
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134088
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15875
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149764
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22012
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210223
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65999
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231375
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37337
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35854
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44286
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 82739
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 67604
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144854
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26974
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18790
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230699
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122616
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 78401
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146678
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55643
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33883
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221449
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153095
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37630
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39562
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 177141
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 103179
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89586
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129442
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157614
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 137889
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 100824
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 446711
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 283024
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190321
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199775
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20484
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 277954
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 91543
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 140357
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 213441
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 180477
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 213243
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200265
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 209760
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49243
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 206971
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 319956
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216363
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 60985
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106874
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121846
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 59619
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251090
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182782
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181642
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27621
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109237
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46459
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 47615
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 3840
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70156
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73798
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87249
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61756
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4668
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26418
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7658
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49255
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163656
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13186
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20967
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49016
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70921
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68845
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69211
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22542
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34498
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67518
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73993
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68431
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198691
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141322
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87939
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15900
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 44334
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12365
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 250450
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120690
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154418
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111115
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174073
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24423
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242357
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83256
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47426
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160252
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105832
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 67442
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 394769
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23106
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129445
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114600
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110117
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33755
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61548
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96553
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 146913
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102913
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 206480
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150440
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 202724
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87195
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53981
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108545
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88656
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130924
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170325
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101606
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 102666
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15162
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136285
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 59293
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6798
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125364
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22836
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11008
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211164
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18076
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 260041
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129133
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98323
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171741
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131825
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57001
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60592
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 200732
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71896
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169320
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166229
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100403
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160719
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191838
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112619
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108395
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90498
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 256277
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68573
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172316
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 301315
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 112258
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98809
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228463
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13692
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140589
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32270
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265058
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83997
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221919
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139839
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46361
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185598
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 150794
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 287704
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193646
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139553
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36833
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38553
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155139
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127161
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173904
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108992
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 314930
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161498
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47629
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 209864
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224892
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 288791
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187899
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68397
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231746
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193081
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47978
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145628
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223133
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23347
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 49139
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176902
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40517
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 41702
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21240
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25835
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65353
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57012
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15012
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5286
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80972
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38318
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64381
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88713
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 95603
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97921
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75904
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54984
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48716
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100706
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105849
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19920
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45050
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56093
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51616
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64173
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87326
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5915
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116157
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 47597
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14439
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72899
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48930
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108205
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23234
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3404
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54825
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87521
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65422
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 255010
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8486
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78596
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32748
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13962
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100581
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73974
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19290
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114180
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259453
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9816
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74732
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26200
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138691
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99121
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77305
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90669
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 51949
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59153
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108109
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25686
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22491
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 140435
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91744
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38214
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108258
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231232
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8661
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104779
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52895
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 232243
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42997
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 202670
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125613
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17043
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59933
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 70098
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89606
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69809
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56246
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99769
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65095
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54511
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 128961
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66090
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110213
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97413
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72484
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197609
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35500
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 97575
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110710
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74941
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100342
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 43829
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85582
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169338
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86731
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115744
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145387
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23314
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92734
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79488
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106896
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74715
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78544
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114905
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83591
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81452
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86048
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70520
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191769
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 291276
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 160614
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23518
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85328
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35179
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20636
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16807
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6050
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118407
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174147
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8577
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71516
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 143075
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 267218
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142776
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33904
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25560
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67797
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18074
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126748
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56776
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71842
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144187
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106648
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77887
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 287115
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16812
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 117387
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 87704
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108617
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47649
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214931
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115692
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83871
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 111840
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170887
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177247
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85337
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186340
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122754
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162911
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 65340
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 167801
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157623
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44507
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167250
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 184610
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149542
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 156074
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69967
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111801
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60815
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217067
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 241221
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77733
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104593
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65258
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152155
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28673
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64806
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98942
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13129
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50795
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69061
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124907
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 153728
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174730
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 12980
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176544
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 611902
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108612
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 85855
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30118
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 342247
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185346
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 182312
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106278
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186649
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 60360
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 107975
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 37848
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17553
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196126
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103810
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35706
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 86734
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222717
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 240536
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175190
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 246800
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259690
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 73767
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140270
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191473
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227394
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31472
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161974
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 84865
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148483
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88712
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43861
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23929
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143440
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31719
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10553
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82218
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19995
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62500
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88704
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 127525
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20448
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101179
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52795
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62713
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22379
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101194
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52015
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20155
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109762
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 254659
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115361
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71509
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70750
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122890
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 164274
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92333
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22395
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9489
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80091
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24135
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16563
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62512
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57617
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186830
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52770
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251515
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87750
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122647
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79730
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99071
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 221359
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 57226
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125304
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193874
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64696
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144655
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32719
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65676
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117111
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112002
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17349
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21021
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71052
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81652
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 303783
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80735
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58566
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99775
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83522
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128043
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38301
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25784
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38122
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191897
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104694
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18901
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14078
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140205
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21119
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91203
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65193
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72108
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 121226
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21501
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137882
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23361
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 162652
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 134140
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231374
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75490
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36719
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 48531
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 202092
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82962
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 24038
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79471
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21044
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 50073
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164752
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90316
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107875
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72359
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72132
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128220
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67137
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82728
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10185
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26583
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19938
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64484
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20007
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98546
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67016
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10288
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6942
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91072
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40257
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110354
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62489
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155409
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53662
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247942
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133351
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52029
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12447
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198025
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88045
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101004
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5766
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55675
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6267
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110798
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 128741
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165059
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87421
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85025
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228494
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39355
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11968
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142521
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63867
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27945
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5805
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22590
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111986
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5779
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108230
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62190
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104991
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16720
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152530
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13332
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17745
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10279
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196388
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 213439
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82386
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97579
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22764
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74357
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8580
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81692
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48648
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7731
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216288
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97529
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 211165
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12791
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19395
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17726
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102521
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106294
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101614
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84910
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135931
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15018
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104297
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120024
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122346
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66384
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98819
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66519
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115771
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77059
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205141
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94045
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59959
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75123
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101097
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73301
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128976
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102735
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 132535
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86951
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 38914
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46049
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46735
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130322
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78563
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161762
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63348
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100754
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94611
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36305
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92094
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 258866
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68138
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85226
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43985
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64116
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26425
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72702
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211211
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47308
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10220
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41080
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 121705
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48239
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131400
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6875
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82884
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103097
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80580
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35254
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231148
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114855
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93074
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9910
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 61489
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44120
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 5247
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99448
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114613
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 280878
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16426
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137879
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126066
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100788
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92574
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7869
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 92462
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 307629
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 38448
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9571
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66278
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247999
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62670
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89781
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71329
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55446
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78264
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189034
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11150
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48477
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 112838
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57190
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67253
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150691
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26948
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 64739
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145837
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53766
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6535
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14423
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31601
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60467
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78092
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 236447
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146991
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131554
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80893
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9975
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 278265
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143835
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 265500
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 213568
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51620
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87659
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104234
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 30160
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10077
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122099
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78107
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 206436
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98206
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168134
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 49226
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120093
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113395
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 50141
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176670
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106840
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165514
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 266811
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178985
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32371
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70534
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 274469
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79128
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123814
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 236609
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 271495
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39285
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67274
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62421
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160305
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 198511
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 177693
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36647
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13624
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 75326
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36666
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111412
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128709
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82309
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74646
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85009
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107645
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68498
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91671
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9902
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32737
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77927
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73862
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41834
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64378
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12144
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32043
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59057
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8217
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9374
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23049
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40553
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79170
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196563
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5902
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89954
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9768
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 57904
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109692
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8201
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21019
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181081
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116603
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102824
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 194228
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6942
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49294
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125985
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37934
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58755
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62117
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 261821
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91392
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9360
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7311
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5065
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65225
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 243956
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131194
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63619
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40228
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 52166
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7199
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 104984
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18233
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74226
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114779
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9610
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81011
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79244
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114968
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130324
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44168
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 45194
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101958
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65935
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106533
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198202
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10362
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5099
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15987
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99869
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14641
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84695
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86450
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84638
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49360
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56998
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25958
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65493
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11833
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124352
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120857
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27279
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214251
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40613
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77829
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76790
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42791
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16005
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 3412
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51674
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88795
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74102
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58391
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58581
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79866
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93766
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8214
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70882
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78241
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102277
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 175343
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113263
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159195
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77764
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151817
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83173
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 132417
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100850
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71046
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73135
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106719
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91866
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 132704
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94814
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7510
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101751
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9675
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133254
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73593
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82904
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118566
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123584
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208351
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62991
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136389
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90365
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98000
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97114
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108764
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15251
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156200
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66796
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 72389
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128876
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65236
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81702
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 28538
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57865
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61499
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144316
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68801
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66686
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80497
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118563
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98708
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 317115
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99528
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9058
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 103264
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 256557
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 249860
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 94809
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181292
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49280
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 125239
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36133
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15594
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34245
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23217
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172115
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 320664
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190643
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63834
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183199
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15958
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30691
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104150
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104014
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182099
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100991
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62858
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67172
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71675
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 168188
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 198922
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 47408
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 138378
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28930
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12652
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92006
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140724
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110102
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49969
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15187
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71240
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98983
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79999
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 119481
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214512
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144504
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 451164
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81748
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67251
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109458
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157866
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239388
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 41637
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4511
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 401025
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93607
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32990
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69662
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104294
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18337
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 190988
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48759
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31404
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 78629
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82013
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34699
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31018
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48805
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31771
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97212
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86587
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60722
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16984
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236127
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45117
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166754
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126692
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92700
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24217
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217792
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13521
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57962
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82534
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50996
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98980
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185337
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18258
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85608
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66790
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71586
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245748
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71425
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14514
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 226541
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119373
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77661
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 97391
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9102
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 64023
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94315
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90493
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22973
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57727
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113239
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19873
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65433
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180085
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 169568
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13604
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153406
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 90100
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96349
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64479
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77682
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92379
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13666
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84982
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11167
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66414
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11519
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66755
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9940
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231675
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58354
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18353
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100133
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255620
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299186
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102257
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100972
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88438
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19797
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143607
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55717
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142110
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 277932
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9311
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7097
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80712
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192154
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62742
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 256816
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33243
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17214
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142309
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 35368
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77903
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137924
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85629
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20300
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 107716
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205686
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123037
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80810
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133626
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146709
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 17098
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193396
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 244706
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 85751
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 46294
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17529
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 85163
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136631
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55273
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102531
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17590
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68112
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 79343
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60069
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211712
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134786
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92004
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 4989
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97827
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10015
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69833
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8930
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21169
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100634
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176840
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 132820
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83960
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93894
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90656
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 2773
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96245
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 52578
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99533
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 80562
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104578
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111815
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 284303
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32952
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34932
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95726
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105605
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25434
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 172065
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31308
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65051
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135257
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 253944
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 286037
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 151663
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 70483
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135687
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53345
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162610
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189293
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73954
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 223506
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 144451
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123072
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 320924
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 213911
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 136556
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24463
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 114943
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46478
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 119112
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 305290
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135766
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 224230
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27020
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180046
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140141
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 90501
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196496
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155821
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 175929
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 233662
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 268678
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172283
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 292394
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 276776
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140793
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 188920
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 246065
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191147
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69624
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9818
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 198270
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 245859
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222702
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101180
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 8620
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18507
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137166
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 82554
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 114591
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192220
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37125
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34844
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 344491
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 12480
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192450
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289479
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 66023
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 35399
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205399
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 3767
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 72144
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177047
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183543
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 323386
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15057
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42157
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12555
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7379
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99273
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30166
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 227636
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92923
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29882
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9279
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95987
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18447
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 86959
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85136
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75276
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32695
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44934
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21827
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19079
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49206
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64406
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98134
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170207
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53687
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11338
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115921
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21675
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78385
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71466
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71632
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91394
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106435
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226590
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114036
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63240
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33077
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111361
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29822
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21286
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146487
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115526
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6259
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139800
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119205
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98142
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12213
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108011
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47582
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 53975
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63407
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71526
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7135
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161855
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229271
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202010
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227375
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 64187
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101926
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20117
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147842
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96228
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5868
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146331
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76482
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 216177
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92890
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30996
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24251
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92563
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109781
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28837
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174330
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67832
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79847
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 290331
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211819
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32520
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186041
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50529
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106560
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75335
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167524
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117324
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 58365
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249180
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71732
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143598
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83716
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51023
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 63525
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 99193
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57321
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 123616
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42495
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214693
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61729
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 17564
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17753
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 96052
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 161603
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16662
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80059
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22267
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99668
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83948
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90337
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157740
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91308
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 175902
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26204
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245636
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96407
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88657
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 337433
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 140856
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146964
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225109
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129684
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6221
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72081
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 82281
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28188
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 53490
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44180
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155187
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 143908
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70230
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193803
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64000
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20939
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11087
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39213
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 76264
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 262963
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13115
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227534
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 215116
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 102955
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 99480
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136180
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 35000
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133035
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180608
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18028
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33048
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251941
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95930
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18973
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 175835
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 123492
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170494
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58429
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79518
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84561
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11637
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66817
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41774
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60455
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6643
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20902
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25352
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56185
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60828
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109391
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105485
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36244
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25100
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43709
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15017
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129692
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45847
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53343
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15707
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101884
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26345
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116949
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9106
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71567
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5666
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22574
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154654
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8167
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81203
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9442
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108891
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 57721
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32162
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 47700
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97016
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22556
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96868
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15862
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117965
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27918
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17476
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95861
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26130
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177111
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 44148
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32844
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91577
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74124
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23425
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127238
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 220501
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 58392
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36787
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10738
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18030
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 103716
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30194
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152877
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65414
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209617
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191819
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72592
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9338
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231032
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98947
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22487
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120421
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153666
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89585
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54871
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18547
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146797
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 46235
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110736
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201680
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58062
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103659
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98301
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94445
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 80297
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22558
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29663
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95173
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174345
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64242
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81975
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117297
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59295
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26685
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 10073
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154224
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188486
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219706
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16800
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142088
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 111013
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126583
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57543
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83506
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 256930
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73422
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 275275
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155429
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80444
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36049
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28186
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221866
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64395
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105111
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117615
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 369307
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 91522
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55512
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93591
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226634
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 54535
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185173
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80109
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67436
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 233796
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199350
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23699
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50017
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54318
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14569
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159145
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22390
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16756
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144863
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114037
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52266
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 60077
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 64509
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181222
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49899
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 84359
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179280
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 78143
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 43463
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 168603
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 458370
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 373818
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 237565
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 72327
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 256821
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42355
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25191
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 96075
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63833
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 134916
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238015
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199434
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 52480
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 267862
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203360
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 139476
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 301055
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 112395
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 131375
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103266
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4818
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8690
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5778
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78477
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24183
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59562
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79295
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 61293
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103381
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 270501
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20506
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 166855
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65946
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86752
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29012
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102122
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65143
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 153784
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22669
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 277123
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29975
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31143
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128377
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103253
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61621
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19430
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15512
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27528
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161937
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 278276
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13308
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72719
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 190919
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122968
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59576
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39689
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85737
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 48133
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 23088
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7720
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82399
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24032
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130424
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97284
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86361
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161422
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33338
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 215855
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56030
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44068
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 80360
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29835
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44539
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 33066
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10679
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43663
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64558
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101665
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178762
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24193
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67237
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85765
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62910
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96698
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144040
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12838
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48944
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61130
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115248
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10901
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63748
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 295170
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11329
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 100060
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14731
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66258
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50346
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24039
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 90968
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60883
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71594
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66277
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195241
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 243113
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136317
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120667
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123315
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157414
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71368
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24317
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76372
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92651
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105898
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87799
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175938
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 260254
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6380
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47291
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46684
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32924
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69085
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87829
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 134272
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34718
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226528
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 333812
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92397
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 79909
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105297
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86557
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62637
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 101521
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95218
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22679
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 279762
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 204813
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142865
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21117
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 274844
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221872
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153748
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 253839
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214797
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125276
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55010
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 324077
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234335
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131549
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24877
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8331
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 287514
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 254105
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209793
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 29929
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108427
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116049
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18360
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 233909
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201464
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 29031
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148663
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26694
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 88932
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 43767
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129693
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 10315
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 179828
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55101
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44099
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 317983
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92442
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154741
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47444
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228085
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28437
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 255696
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101701
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 229944
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170598
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 113462
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13278
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245885
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22079
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 359997
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 155555
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 236383
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152764
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 271994
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 70708
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44927
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 188309
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247614
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203394
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41523
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10312
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 43907
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4473
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14950
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85445
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25461
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51210
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121713
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75336
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61650
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47427
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53972
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114145
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86645
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90698
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110059
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187241
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98454
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9158
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13290
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38821
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50760
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67199
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30391
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39906
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129782
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 43594
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 262020
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81795
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76877
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24903
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127770
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93139
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 17144
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26119
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 239997
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 194940
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118794
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235714
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91456
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67905
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141412
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100033
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8277
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111572
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112871
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65479
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85401
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 137595
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163110
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15169
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8221
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72801
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88628
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40903
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25800
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91213
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56855
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74097
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19596
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145859
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97145
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115843
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119402
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22896
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71006
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63287
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71933
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 75957
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49120
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46869
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 80415
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80152
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26020
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75758
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56819
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198607
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89538
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 132039
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51672
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16377
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101424
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98663
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159085
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34207
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 164788
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17990
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76752
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6129
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24867
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75956
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27265
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 49921
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20306
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81780
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 250108
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 21995
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19966
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11619
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115437
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59484
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81362
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15024
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94516
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72712
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120781
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191672
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118317
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95166
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118251
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103586
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5987
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60351
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88489
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14241
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 38363
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93595
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 158801
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32153
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75409
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130955
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34163
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17167
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 240089
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106513
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65213
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27130
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98111
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83574
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20919
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134392
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99732
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23542
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22908
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69081
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 32239
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14799
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185259
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18508
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12199
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56470
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81161
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17884
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116727
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 153837
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90676
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97017
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104043
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74778
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 319972
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82754
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66017
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 215576
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111017
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113831
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87744
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79333
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18890
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140580
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176053
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31281
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78112
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18997
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99192
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 166346
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121607
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64449
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80053
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109237
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143498
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25407
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 159017
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124838
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121354
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107930
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122812
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 371700
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 59497
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 143553
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7271
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107783
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128321
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27724
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132121
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131590
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20517
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90032
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 129551
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156148
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29460
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92132
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99703
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28817
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99629
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170761
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15662
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65457
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96760
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137101
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114945
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30212
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90043
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186477
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141665
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100356
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108876
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12132
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96861
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74199
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70513
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110740
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 53572
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 122687
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 148020
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30845
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35707
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78733
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81114
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 321813
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120986
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151487
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33312
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42014
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 63672
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39508
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57049
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191258
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130603
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27581
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 405573
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181100
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145103
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85167
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150207
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 56593
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61120
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13308
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146930
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225209
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132698
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100967
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204426
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103705
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26151
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12444
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97500
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 214193
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42685
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116596
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79912
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117138
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 118968
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44750
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68782
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15509
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152146
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 11182
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130049
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126052
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 114949
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 57650
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38972
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90848
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171280
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188573
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179784
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180925
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 258498
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87622
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39190
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166614
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13503
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 302404
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 56379
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191808
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 275914
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 57446
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 266700
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51160
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54045
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135715
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 312002
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 88495
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167985
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 80194
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216280
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 252425
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47519
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9377
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 193443
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9763
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 267050
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40977
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158264
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 234122
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 60286
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 112922
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169344
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38841
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 142312
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 115921
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 15666
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 71276
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157383
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55235
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216416
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 455395
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 70925
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251642
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 283469
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245910
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 54814
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192585
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53222
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67722
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98862
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166425
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28729
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9817
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81382
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9884
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 116915
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60379
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97570
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 156350
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 49158
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121163
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79119
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121615
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89569
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139707
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98638
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76528
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68723
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 81113
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85284
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159044
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114710
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14919
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114049
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13709
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39229
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70555
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72311
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83241
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 240210
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10522
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99256
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127950
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156588
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 41347
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67563
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81052
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96240
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 181233
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149957
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63958
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109338
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9600
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202874
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75704
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24284
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89870
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 176640
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78010
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16539
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137996
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117353
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117246
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122822
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21060
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 8464
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87390
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122222
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148002
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143123
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163738
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 69643
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6354
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52567
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28250
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129843
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 236575
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71647
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 144993
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5842
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101870
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136416
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109759
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 296733
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83547
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185264
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 7682
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 263545
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103262
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 240225
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 311302
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97657
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168628
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8532
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78259
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 54334
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 94846
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34468
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 265734
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 128379
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74336
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 201381
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9715
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216562
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 116440
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71335
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84442
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130671
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27485
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101452
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14041
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18141
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90132
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76470
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 232609
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184294
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123938
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23292
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110855
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87250
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58234
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26735
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 86620
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62255
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92960
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 236374
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72351
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77053
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 216191
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 275185
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67685
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41284
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 175391
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20209
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85653
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210002
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19170
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94673
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102599
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239840
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81513
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202344
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159265
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 59228
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 215248
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 261349
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132334
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21026
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299337
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221364
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126234
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10491
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63448
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74068
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124856
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225204
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 490899
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54166
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171669
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 67552
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33180
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28069
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 180759
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102852
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110640
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 65691
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190852
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139482
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94035
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176599
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22486
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139446
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 322273
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37443
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 213660
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 8957
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183706
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189635
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14521
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 275595
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133385
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61052
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55438
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106140
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142271
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251781
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 146205
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 222558
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85710
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248943
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206301
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 357848
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 159980
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 81829
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31505
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160185
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33107
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 91689
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 30148
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65160
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 9233
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 500312
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 73090
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 454165
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 345121
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230575
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 16250
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210238
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101572
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25381
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 244918
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96122
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36554
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 295586
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63991
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 250579
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182514
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84547
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85448
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110963
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109638
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9607
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109434
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70220
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33471
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 14829
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100694
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14081
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86404
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9712
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90881
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134768
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18253
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198042
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110129
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60578
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77293
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59712
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40675
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13916
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52583
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120402
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64124
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229396
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92590
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66303
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130747
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 106021
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119825
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15191
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109719
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129590
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55716
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12321
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25809
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103190
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20300
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172818
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158458
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 85222
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131001
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12041
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 72364
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7556
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119385
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94702
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84413
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88527
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 146423
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12271
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43093
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78245
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24389
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56233
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83535
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156573
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34135
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 66248
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34981
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100338
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140360
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109321
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34003
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89521
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123189
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61203
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102988
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79491
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77620
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92785
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114532
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113306
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27479
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122739
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 145534
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115440
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 413647
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186451
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 148617
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119791
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96910
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223518
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38094
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110051
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18602
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74686
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 57899
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 57398
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33007
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210092
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74811
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199183
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111938
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170202
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 72225
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18938
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161260
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 93049
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 190422
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 96491
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34422
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 273909
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33302
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19339
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 108083
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 8432
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 266154
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43790
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13913
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174350
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142607
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221750
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 169221
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 280220
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4586
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231814
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157122
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 281333
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150505
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139048
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178971
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180262
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39432
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44200
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 79618
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237671
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 316484
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 238767
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224528
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 61302
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 201337
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 325233
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 45631
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 19333
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209184
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 281014
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 279628
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203300
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 110698
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29783
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25357
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51712
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 36600
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149284
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55368
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 81315
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87421
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111436
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9520
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101058
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100015
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108392
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44730
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5052
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 129380
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90673
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25118
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57024
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23769
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53431
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11606
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123803
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67591
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11435
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12597
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10179
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136115
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15865
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87198
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165640
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 3183
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 163904
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19315
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140633
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71812
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40201
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51759
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 39248
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 270296
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75460
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47305
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 80776
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80505
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 366757
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55316
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113417
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175173
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82822
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 40715
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170139
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96673
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91369
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55504
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 51542
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63112
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112219
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106417
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105175
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 31093
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170937
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 156225
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27233
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128042
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 121734
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74452
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251406
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13909
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138329
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37665
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 353096
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86574
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125718
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98841
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173859
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36743
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 5985
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 304418
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79370
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186074
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18768
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 203756
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8687
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20584
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67281
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10439
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111460
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20079
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207536
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89976
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80668
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128884
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145628
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 188563
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124359
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40024
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 167361
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52003
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 143046
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81469
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 351375
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 116797
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 42002
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175523
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91029
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99336
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 39484
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50061
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82009
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86075
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118369
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67446
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15966
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 84046
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 59648
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43694
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31332
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 272616
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81124
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 176221
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56176
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 67226
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 50030
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111524
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151681
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77354
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185422
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153673
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132288
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123507
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93253
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182099
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 69570
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 229725
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148343
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185326
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 54883
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65505
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196776
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66238
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87124
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131066
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 16643
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124443
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 117294
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 198708
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231780
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68411
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196085
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69049
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103580
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89925
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132109
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50502
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136537
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 108055
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 192516
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 92574
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139801
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 84343
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140072
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8994
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221330
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40190
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 49221
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 38228
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 161731
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51334
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199482
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35477
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 253426
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134245
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 119238
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 11534
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 145068
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 305806
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196613
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 176387
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20489
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230035
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 92612
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 155755
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156516
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61072
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 259540
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 312132
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25068
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 285944
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251928
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144804
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16001
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12426
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251317
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 43017
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 45244
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 168893
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141648
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 65352
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36990
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 427698
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86205
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116905
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 261509
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182451
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 323652
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 67825
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36297
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35879
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21751
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137309
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152379
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29786
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83044
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 106245
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133335
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140720
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193937
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27666
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90898
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153323
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 146191
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79725
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15635
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29348
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127134
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5601
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117828
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 286559
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 119469
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 103343
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 9453
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71521
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118164
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 102300
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9624
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172794
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42805
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127753
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61249
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 137960
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17184
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116724
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60695
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181677
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64291
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146654
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 37886
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 345807
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20240
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149780
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 108284
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95372
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 203532
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48982
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96415
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21700
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189519
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27365
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 129030
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9217
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98730
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 57285
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 43274
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137901
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 65809
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105148
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4759
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 88368
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103438
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 272382
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 164447
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26107
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 103397
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 498678
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14955
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166516
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54952
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 210090
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167591
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 252815
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96549
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 9544
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123682
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 72263
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251805
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190413
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36614
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 467255
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11188
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 154395
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 139812
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 280307
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176137
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 303446
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146466
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126649
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222192
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214926
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189707
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135919
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59622
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 122873
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133034
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218537
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 226632
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 238170
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 31133
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 266404
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 10678
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10472
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87299
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 205376
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20591
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35688
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40470
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139197
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25611
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35555
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 298287
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201212
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 114167
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97757
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125605
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12344
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19874
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67597
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92182
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19442
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 47019
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98067
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28593
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20212
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20116
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 143962
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69132
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89197
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12539
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121892
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111477
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7328
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84205
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 59406
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115501
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70275
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71040
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68991
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13911
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95137
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 214675
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104905
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64195
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19744
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122322
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134697
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37094
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 42698
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73741
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175704
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118472
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5029
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67836
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9224
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14633
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40345
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 115532
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8541
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 41284
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88780
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19099
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85063
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 23806
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217384
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36482
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79091
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112303
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98697
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117298
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69617
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129400
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16990
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125295
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97466
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21883
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 147479
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103599
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 48718
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124734
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51819
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182607
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 81702
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148112
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105546
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76454
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72078
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43900
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15951
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12536
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149776
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65402
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 152333
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113282
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69647
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66314
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 391546
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 133828
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99648
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79634
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93042
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 18961
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105839
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 120629
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87165
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13715
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81576
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117891
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13575
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105054
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242673
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56677
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87583
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70030
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112324
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33399
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17571
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57457
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109855
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115633
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64918
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45789
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83973
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35734
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117437
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 161990
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47500
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 42566
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145218
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63544
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117726
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85072
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104328
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41238
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123114
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208426
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85444
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126508
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 126824
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25186
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 255942
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 146601
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124260
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11709
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 198082
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115970
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15729
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7136
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134431
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60414
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167713
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28806
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75186
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14215
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93954
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225880
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 56216
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59495
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6571
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218410
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189759
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163135
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 150300
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158712
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 132668
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145872
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 353505
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189141
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115028
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13871
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 280627
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88601
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37906
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14334
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117912
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 344838
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 221572
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54218
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192230
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194545
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 210412
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 48481
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 259088
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161740
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160184
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 204085
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150716
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35086
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114356
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141974
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 71813
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 98099
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 202658
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227921
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23500
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115048
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 30895
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 89821
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 76692
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39394
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 285247
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81559
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87863
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100893
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70788
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36361
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112178
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247006
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20969
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 131980
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251973
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30996
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 215717
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183181
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 160254
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 62516
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 266159
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185295
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102008
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 70589
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91950
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20630
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194440
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77195
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117363
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31560
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 109765
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 67323
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126551
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 306559
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 53059
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25190
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157871
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196352
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19852
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149653
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 74837
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 226704
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 248751
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 67660
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114355
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18108
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54592
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 104472
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210932
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 275611
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 11939
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174736
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218823
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70024
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144241
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187757
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29887
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37961
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 79590
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 288916
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14886
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 229896
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 111651
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 119075
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 66494
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 58712
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 408258
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251240
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 310255
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 82234
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 273668
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 232962
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84139
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93550
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16454
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72026
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15366
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8080
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 128079
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20611
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 60894
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 76041
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15976
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 194046
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74326
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29590
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186453
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64169
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61485
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65840
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60759
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125155
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24201
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41365
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129319
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40404
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81265
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52893
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72697
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82886
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10501
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66265
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79187
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70790
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53569
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 60812
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93739
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11673
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94925
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113419
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 36714
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85242
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119499
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73137
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90189
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 72992
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16761
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237751
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57401
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88799
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8749
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7814
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73703
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79455
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90701
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76867
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81668
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119301
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10514
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98600
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 76151
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18535
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107514
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 136995
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6159
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6518
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 88749
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190667
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103109
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124051
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92289
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99106
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58676
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 114950
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30962
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87469
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120110
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 134424
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146460
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113125
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62051
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80143
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22564
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 122723
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14185
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78912
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72328
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151301
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214642
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111087
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43552
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87291
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73916
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41510
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79574
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90306
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187824
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 37324
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18387
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32824
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 280201
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 30702
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 309475
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 219813
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 295520
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99863
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55187
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34406
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56170
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 337020
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186586
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65421
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55227
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66217
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81674
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189674
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136470
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88173
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 289847
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12821
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149942
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 235350
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 17540
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34574
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148356
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201614
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26397
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33680
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52708
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140325
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 326324
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224137
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145396
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 100851
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 60878
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106733
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11373
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55115
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106659
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10248
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 70446
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208103
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115909
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194822
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 398941
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 109440
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 134164
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186517
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 154745
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 131707
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 236818
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 102916
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 109361
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 146247
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 46153
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 296825
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 122364
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204399
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 32657
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 96725
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 165863
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206969
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54033
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99729
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71123
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 191557
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67334
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35385
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54685
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50596
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143310
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116831
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105233
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17927
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 334078
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159202
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15076
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177828
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161600
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251268
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172736
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24192
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24682
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 53840
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48729
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 418900
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 300818
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 22166
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 48613
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120786
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192133
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107980
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 275050
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 127137
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 202479
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 159689
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 30273
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195064
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27740
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 184480
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170814
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212514
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143598
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227173
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 82009
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 264965
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191495
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53777
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 224121
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 316699
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 184418
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91956
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 71923
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 138806
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68360
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 254011
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 166502
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 200849
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 258694
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17088
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 93328
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108748
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96475
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74654
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42052
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 70186
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75332
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 38845
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79108
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 123761
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18255
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13631
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67401
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123824
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93033
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 106622
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 174907
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 132494
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121436
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73024
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127923
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133088
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152576
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 232458
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25438
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 9514
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 111202
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 107048
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 150938
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118248
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103340
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87185
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64833
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107535
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42190
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74919
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83952
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93814
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76011
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13959
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131833
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 353097
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77619
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85235
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75824
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84155
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25833
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67760
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88498
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24609
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 158626
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20010
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109789
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 218050
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79032
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103370
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163656
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107149
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 33808
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 212795
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 61983
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45630
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 120200
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 251957
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 210529
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 127605
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21505
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57462
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102418
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 50483
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72724
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123848
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95432
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88755
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83028
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86708
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12825
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 36406
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 213138
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109637
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92088
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122402
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239027
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5752
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16177
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 88271
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81346
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104113
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95992
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44452
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 209513
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68464
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 298585
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118802
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4543
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 122250
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12279
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 55883
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125363
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129967
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 205119
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72716
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 44667
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 27208
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125407
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7037
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27216
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 77698
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81735
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23659
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 8986
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33477
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162914
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63318
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48974
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64106
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139713
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80587
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 208992
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63600
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91681
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175508
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45913
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61871
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131526
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123970
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154518
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 441680
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139861
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33893
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41997
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98358
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 15281
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28260
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107664
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80695
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117862
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53471
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179572
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 30017
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201839
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105359
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259262
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64195
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89194
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 62769
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234079
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188629
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 118573
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122182
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 134994
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 225156
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48671
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 228457
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 80308
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145976
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196792
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 213546
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 217804
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50474
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 302336
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 19888
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 232088
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 232300
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 123801
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219081
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 227583
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170652
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205863
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 339873
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 239564
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 34811
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176411
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125562
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 312273
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171047
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122080
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121618
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 63472
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 131877
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92060
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 140617
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 233433
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 109218
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45323
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220225
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184302
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176774
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169166
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34998
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172647
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50886
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 110480
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 67106
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50703
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 256598
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208244
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 82482
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121293
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 112020
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 141389
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 260935
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 167551
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 80387
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 271727
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116960
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106159
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 130201
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230702
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178682
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 101791
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234912
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64813
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164713
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 134665
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14913
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 104823
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 273738
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189897
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13960
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201764
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39060
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 34828
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198927
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 101857
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196072
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126917
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 259159
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183129
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234778
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40748
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 298623
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44345
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 257413
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 235791
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 153988
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124149
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227221
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 71373
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 499766
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83584
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190553
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46894
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 283479
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73197
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34831
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11421
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16217
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120821
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45424
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18734
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 111674
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152121
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53677
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115305
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179743
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107501
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186946
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231896
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 122309
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 42812
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 521980
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93651
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108358
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121061
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 53697
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78757
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141285
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142931
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 184526
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 277796
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 206373
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67963
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98854
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45398
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247435
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156391
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52317
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 125097
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188881
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 205007
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 8646
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 232891
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 46288
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 56147
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 308888
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 187992
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155828
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75657
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 137089
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 179085
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197092
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 179447
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 264946
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 125437
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 247901
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 163155
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15345
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21873
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 21439
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155896
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 262316
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 65772
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 244851
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95280
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 91760
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14684
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 101340
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26262
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 309476
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198455
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171621
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 287276
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 116048
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 48732
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99374
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 86013
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 74900
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31370
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177884
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89076
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33859
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109416
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 104959
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79571
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108486
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 67575
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144871
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 39225
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5392
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13987
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78788
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 48318
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71894
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210735
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26697
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7363
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6614
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 88479
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25820
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103612
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27731
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66613
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6818
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22852
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 76882
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9137
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30677
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116567
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67942
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11013
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69025
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96275
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8724
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 135536
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61661
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 33720
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 47516
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145627
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162976
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147384
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 86853
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76039
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66393
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53976
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18206
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161626
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28051
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167052
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85879
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138311
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107434
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 42575
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138281
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171313
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10921
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9718
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 128816
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78383
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171990
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10359
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89060
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77996
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151300
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 200201
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66210
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 194508
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 142426
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92328
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110807
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77938
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27738
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34959
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14924
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81906
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90785
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13589
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125994
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 177604
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94683
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18085
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 17623
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57715
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95157
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27411
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 294048
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124191
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 268111
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 129023
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139225
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 46379
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 146961
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71786
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 98525
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130744
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 278423
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 52272
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171435
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 237517
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87622
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 71195
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 164535
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23930
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121593
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 75905
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39453
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 36779
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56789
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196691
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129289
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 57333
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160951
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125903
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 298902
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 231171
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 32747
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145375
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 269483
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96194
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 184487
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193710
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 151167
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27794
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33313
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32885
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 126281
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 329722
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 98118
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165482
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48694
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42569
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 78678
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190912
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190608
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 140989
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 36851
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 397828
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234880
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 317122
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62259
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155716
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163481
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44744
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 285360
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188216
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97594
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27166
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 13539
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35994
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15934
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13769
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161821
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 97928
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 358794
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 271049
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 190968
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 55343
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 66214
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 146184
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 355544
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 224956
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24988
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 199106
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 163035
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 253416
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233732
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 177709
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44946
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 61170
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 333058
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175099
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 161739
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192183
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 189841
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 63548
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154843
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231610
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 104660
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110100
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10263
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131738
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114615
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15771
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54755
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 289203
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57239
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 62922
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 135214
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 117446
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83565
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28161
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155273
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20274
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87765
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87836
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10347
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 59361
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54169
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 157244
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108717
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90227
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 16072
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126379
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125233
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187763
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23418
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21366
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85032
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9558
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91404
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 99789
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91396
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16628
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85612
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19351
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 208884
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41848
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 189035
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28521
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68919
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166052
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27383
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93640
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67675
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 127991
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71671
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108007
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82442
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34749
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 11266
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 137628
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28535
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124107
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 52121
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107854
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50912
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105930
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132574
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58274
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196772
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176632
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108453
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121452
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78425
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 105760
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59087
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 70826
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9564
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 34346
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176630
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93122
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 26581
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115982
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97096
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100049
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 49944
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12690
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39292
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100423
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160115
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23649
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28983
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69304
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 91050
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 28018
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94048
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134376
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90376
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221826
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106216
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216433
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30232
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13757
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 104499
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17623
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105454
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 54966
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 15079
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119380
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98294
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86273
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 23392
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209150
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12127
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 94565
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115944
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197191
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93800
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23666
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 121758
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10861
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 181654
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 155092
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190683
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 268189
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38385
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 141862
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166451
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 84398
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 89323
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 336630
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11629
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299383
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 87927
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 60175
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 333214
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 335323
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31904
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31811
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56523
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 253867
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 246608
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197131
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76992
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 270668
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29234
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219987
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32196
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 100316
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 189465
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29542
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149959
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186507
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 359335
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49474
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233424
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 72535
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 186691
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32480
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 396806
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 106264
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53908
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 234896
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 67465
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 60844
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167531
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 330042
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 30259
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 60723
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 213215
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 807317
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 183855
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43142
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 182756
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47862
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 173789
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 216370
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 8684
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 54940
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259022
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 269334
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 127847
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113562
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94344
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28334
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82962
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26104
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86637
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9786
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82932
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24594
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128880
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90712
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85457
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100394
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123688
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20404
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11278
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74877
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66344
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9776
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95872
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72259
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105819
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10266
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142044
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13432
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15616
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29787
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 48019
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 241441
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 170875
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 21296
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 97748
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84515
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195879
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30477
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116746
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70817
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87556
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106433
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 73495
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93468
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29947
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115443
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 127426
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56789
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 19055
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79222
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28522
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41597
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 187795
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151129
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4902
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7651
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198611
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113292
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 73185
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18147
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 168017
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62000
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 231198
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 143036
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14413
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 10620
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218850
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 27049
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160777
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119626
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51085
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51964
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 4491
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 90641
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20086
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80467
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 22206
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13929
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 85398
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190081
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52170
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69268
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 130066
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133307
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85069
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125631
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96351
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203685
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217335
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 132650
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51017
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38434
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110507
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245456
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167431
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10387
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184139
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 172335
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 277356
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96116
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181446
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22866
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 254329
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24377
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 167746
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61866
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 134170
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193081
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106150
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138825
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121134
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117309
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154698
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27802
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 290416
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6285
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 272607
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106741
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173294
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168518
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 269472
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147887
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217173
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220368
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 234300
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 78759
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 226434
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 60473
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57688
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 123194
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32106
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 250055
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 82054
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124013
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 73244
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24093
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 67054
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 138943
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66874
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84838
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 30318
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52986
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72836
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48183
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12876
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78831
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 130626
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63563
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 11624
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 27296
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14532
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178030
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 6312
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28773
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201754
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 123061
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 246073
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121743
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166627
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87603
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 61529
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103683
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 66648
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55737
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78879
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89713
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8797
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71295
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52007
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 120025
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 15204
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 61950
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249535
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51555
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68292
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93914
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85062
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 59859
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24680
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166610
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 240617
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85447
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108053
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57026
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73029
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 67007
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 21242
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81681
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121131
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102878
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66702
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160572
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15466
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135996
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57102
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98836
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 322919
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150188
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46865
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134195
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78424
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192794
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123161
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 253788
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 119742
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117510
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 135794
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66713
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 141009
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39164
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 110776
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64158
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209219
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68448
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117403
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159504
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103621
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141418
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 49436
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 183376
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 34875
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71543
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 130482
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26883
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 153675
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8637
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 313442
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 163490
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197013
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119198
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44933
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 299907
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82361
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84307
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 159599
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 207373
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5246
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 83624
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167029
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 155044
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 44593
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71345
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108176
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67318
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24767
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 268537
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113447
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138491
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 357606
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 138405
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208744
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192754
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 132749
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80065
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 133934
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214528
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10807
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48647
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 136572
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203080
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 200751
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 11326
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 192541
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57469
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 270070
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 125147
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 240881
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5985
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 70041
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42532
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219633
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7964
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 113798
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157497
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 141248
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20597
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 204111
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66490
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 63877
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82471
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42271
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 256958
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38309
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 30222
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 310304
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 29972
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 261843
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203660
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168678
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48037
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 39081
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85569
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196377
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60620
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219936
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 103081
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162685
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 233566
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 149906
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 272112
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 6307
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 131879
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196451
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 10866
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 43128
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 158229
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102373
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 29598
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 61797
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 77659
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 123132
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 46437
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85816
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201250
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83037
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 222905
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64745
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 176998
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50832
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81949
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15647
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93680
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136018
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 231085
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165184
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40958
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192705
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102471
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19224
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98150
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8388
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150819
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78644
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87681
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93945
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44521
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 42014
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71915
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171686
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214143
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 57929
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219580
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 95458
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146828
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183329
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67778
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74922
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54093
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72737
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 34980
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 149760
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 107450
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83571
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91449
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 181943
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 137132
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99800
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19828
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 28406
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 14393
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49769
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49881
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 44347
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 178722
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130075
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112466
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 29641
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 100492
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29552
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122845
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171225
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 138887
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237256
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 124201
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142606
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 12874
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120730
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 205137
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 78570
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43137
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 69788
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131351
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 176410
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10913
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 27424
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62821
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17238
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 156535
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 470470
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 321297
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152336
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58399
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 127127
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182413
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174630
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 38000
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 202495
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 133833
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59415
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 7951
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 282878
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32321
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221305
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 197463
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10532
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 74079
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86065
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13175
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209675
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209661
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221708
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 18470
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132941
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 186943
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 160290
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 188173
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89133
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82546
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 57081
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 151580
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 19789
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 163182
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123466
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139497
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66611
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208093
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 300067
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 342979
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 164917
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 38189
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148320
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 94103
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 342151
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 198879
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 191068
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 41844
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99790
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 73438
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195475
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194153
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8355
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211928
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 7612
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197451
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 261141
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22131
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172005
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214328
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 144313
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 28177
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170501
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225865
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 35929
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 144343
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 326561
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185016
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 413902
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 35561
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 98853
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83255
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 78476
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 72344
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 10949
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 15888
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19809
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93702
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73603
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76133
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81111
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139809
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113965
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 288927
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152740
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 63324
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80408
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 234571
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120199
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 32076
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91918
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115004
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 123656
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15887
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 113219
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134868
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 133615
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 30855
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91407
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 162353
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191219
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159884
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 326634
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108276
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 93480
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 143518
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108739
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93369
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129478
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 18122
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 96695
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 10860
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73065
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151766
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 124264
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 350398
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27218
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 147458
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173609
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 150276
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 29850
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 110896
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 85816
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 24470
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40242
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 14015
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104159
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 5956
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6707
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74430
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107731
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46656
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81081
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106322
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 152098
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 42997
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 66271
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 305688
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14494
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 280472
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 213673
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170381
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 154100
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 10881
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54859
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 70375
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 343146
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148035
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 28584
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 188127
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 53771
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139513
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 4444
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194198
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 86955
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 47718
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 35243
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203005
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 5694
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 68150
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32321
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40480
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 148159
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 102306
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 101384
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203207
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 326596
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 30701
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 240689
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184676
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 282458
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 56108
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233011
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174557
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217108
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 266064
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 162296
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143348
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 336716
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136774
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 20556
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 259917
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 157740
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102035
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77665
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 30827
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90206
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 79896
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104270
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74177
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39737
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104996
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45358
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 267374
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91154
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 18882
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174336
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127925
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129387
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83902
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 199129
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85473
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109488
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 20140
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49981
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137151
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232836
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143686
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36753
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111527
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 25595
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227837
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101336
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31251
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31756
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127643
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123846
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 25701
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89134
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14762
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152378
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86362
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 83524
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232212
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53522
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118888
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 87872
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 14287
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30005
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 103974
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 140530
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64182
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 89360
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178614
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 48795
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 142630
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 100227
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 107690
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211472
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179944
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 71978
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172411
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 200900
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 55568
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 79101
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152210
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 93800
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84661
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 45358
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201357
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108043
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221025
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222672
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 281884
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 102428
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 184948
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 258448
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 258846
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 122180
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 81427
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 82562
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 219464
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220632
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220542
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147826
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 22720
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37047
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 41790
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 83327
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 32272
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 24218
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 5792
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187245
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 274449
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 56074
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 242978
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 163768
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23856
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 265455
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 314875
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 31370
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 57076
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 263730
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223802
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39262
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 565498
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 62026
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47777
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71813
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9434
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114732
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 59743
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75823
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6771
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 141852
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 301074
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87939
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 13756
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7390
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 4631
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5295
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76672
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 116397
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 108321
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 126088
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17246
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8373
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 290545
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 51980
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7774
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237056
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 10619
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161010
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 26542
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 150897
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 72427
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17393
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51196
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10311
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115305
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79813
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52631
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74291
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 160533
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8681
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 292917
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56629
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 116284
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 96354
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 6723
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 19326
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 173678
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 98702
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29302
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96713
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47326
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97006
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17478
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 144512
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80743
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62807
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74419
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46871
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92212
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103726
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56758
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 92557
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 60049
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 20877
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 100725
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51861
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 74197
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 112023
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232515
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 65832
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33911
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84493
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 121474
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73645
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 114613
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75310
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24328
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174180
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141909
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17972
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 7337
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124539
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31328
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47917
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50108
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 68722
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 87049
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 23102
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 13008
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 158461
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71351
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17606
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 5492
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 106559
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37926
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 84101
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53089
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 18996
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142009
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118584
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127234
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62866
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103934
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148352
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156359
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127078
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 292728
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 127908
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129848
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 96308
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 79086
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197502
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 22378
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112078
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76164
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19552
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 290476
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92291
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 156584
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128835
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 54311
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16139
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 31706
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 149749
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230433
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 90370
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 93103
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 148067
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75875
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117201
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 92304
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 115553
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 83947
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27419
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 233546
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 261165
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 153533
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 226661
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 182730
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8067
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123399
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37729
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51107
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 98697
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 142152
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15945
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 47004
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 81384
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 194614
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 64522
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 97561
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45796
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 19399
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 274012
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120297
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 95627
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 268469
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 81252
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 66294
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 145385
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193332
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 129053
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25234
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247222
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 260544
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 155505
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 50329
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 162609
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199978
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125966
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46399
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 376530
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 178340
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 245621
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 343722
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 40463
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 180831
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 323240
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 292476
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 151206
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95580
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 290906
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 71493
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47623
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 39299
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 71269
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 106788
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 259386
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 37523
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 57608
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 79275
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 105264
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69147
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77643
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175431
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 65238
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 103974
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 114547
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 64641
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214042
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 133518
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 419206
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160785
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80369
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117939
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22570
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161526
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50708
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 63658
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 195838
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 214984
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 134110
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 303303
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174884
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86464
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50837
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 70545
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147227
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 145280
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 85105
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 70435
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 11309
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 25592
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242672
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 108444
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 308184
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 372051
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231688
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28351
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 117367
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 5736
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 175877
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79146
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218622
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43356
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 143524
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22932
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 250036
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216157
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179145
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179056
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 95883
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 56647
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107154
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 82526
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32600
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 125493
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 51001
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 217396
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 221901
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 160772
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 288282
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 16576
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53957
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22988
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 101920
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16117
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159854
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172043
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 66656
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 82319
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132250
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 161298
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 165342
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 271400
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37079
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 196547
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 57387
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 164302
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 80931
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 216893
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 204291
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 170023
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98161
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 158744
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 217133
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 272180
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26555
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 202295
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 177746
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 53508
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 153093
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 75703
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 36198
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 221735
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8843
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63070
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71249
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211173
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 174753
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 535102
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 91431
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 254143
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192759
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 132607
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 24336
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 23752
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190121
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 197626
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 187041
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 54848
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 293793
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 206110
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133100
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 87277
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191133
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 51080
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 20428
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 277590
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 166147
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 74872
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 41874
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 161090
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105042
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 323287
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14217
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 209218
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 147387
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 360252
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116299
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 89511
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 236658
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 279583
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192113
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 132842
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 60952
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 48029
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99487
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232320
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58341
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101982
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 109847
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 92755
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 65254
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 21816
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 49022
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 105250
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31720
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104511
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7785
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71832
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112105
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 89988
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 152932
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84243
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 206180
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 80453
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41478
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124882
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 77157
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 131058
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 134924
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15549
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 51714
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 97931
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 136113
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 68523
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84383
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 95426
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26346
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 58566
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 79893
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 303579
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86767
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104455
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 53806
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78171
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68743
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 20904
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11829
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 82334
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 94246
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165253
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 152956
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100529
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98222
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77244
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71722
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57981
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12675
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20924
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109601
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 139357
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 168486
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100992
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117025
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96663
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91215
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99395
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222141
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 8866
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 143242
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 130870
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 129349
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165182
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 55989
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8438
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102963
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47712
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 172917
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77049
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78439
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 124391
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128889
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 85571
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117399
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 238501
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 12634
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 18273
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 8187
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71335
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 75001
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 100789
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 91605
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 234246
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 101902
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 154645
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174831
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 231751
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18237
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 33009
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 94715
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 142208
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 185768
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 359343
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 6993
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 55687
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 129523
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 104997
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 110925
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81503
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 158602
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 76451
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 94085
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100679
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32646
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 87577
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 99677
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 134270
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 165634
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 46952
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 73657
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 43067
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 100270
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 20177
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 10001
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118562
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 62352
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 121469
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 97627
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154992
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75228
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 85986
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 84158
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23781
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 105824
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 27289
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68771
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 103080
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11894
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 89436
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 95327
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 191490
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 207818
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 114965
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151663
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107825
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90367
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109204
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 215726
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57385
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 88577
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29497
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 352493
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 70982
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 21060
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 81682
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 37745
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 14821
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 26704
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 23157
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17935
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 109267
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 208676
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 19362
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 62745
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68914
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 295392
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 179490
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124190
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39603
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 25847
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 40737
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 121718
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50821
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 48766
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 225867
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 211197
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68611
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 127128
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 323445
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162041
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 133859
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24501
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 43970
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21044
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 309794
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 345820
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 133212
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 175557
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 254931
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 93972
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156400
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 351400
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 152986
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87432
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 75728
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 126882
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118630
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74030
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 119124
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66215
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226523
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 107764
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139835
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112205
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 37745
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 33337
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 12757
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 260211
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 129658
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 193853
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 37464
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171084
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 66243
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 194784
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 84099
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 74348
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 64299
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 263238
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 141512
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 44722
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 21309
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 38667
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108251
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 36880
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 25750
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 30556
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 211307
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 184546
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 328587
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249707
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 154469
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 193235
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 327162
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 174467
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 35547
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201507
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 67331
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 185706
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 303895
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203150
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 220472
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 151806
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108401
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 335124
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 251013
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51864
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 44819
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 315583
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 72522
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 39018
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 84494
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 27646
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 13584
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 220817
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 38483
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 25231
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 53071
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 86476
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 258781
  },
  {
      "roleId": 108,
      "role": "Laboratory Technician",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 43688
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75935
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116233
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 69163
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148863
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 123068
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 88637
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 122586
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 137229
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 62726
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 81129
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 171188
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102136
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 15757
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 119964
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13198
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 185858
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 32245
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 55163
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 139957
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 26610
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29469
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 17461
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150175
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81736
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58556
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 31756
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 96088
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 100144
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 88004
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 24912
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 245848
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 164184
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 37194
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 32218
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 232236
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 148255
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107338
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 67625
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 233288
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85174
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 8111
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 170782
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 81776
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 9916
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 108695
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107588
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 86649
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 180095
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 131953
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 124089
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 16069
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 18676
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27059
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 282114
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46348
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94546
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 10417
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159638
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 196049
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120669
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 99712
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 41542
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 282610
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 180685
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81453
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43095
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177758
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157680
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 328885
  },
  {
      "roleId": 144,
      "role": "Content Specialist",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26657
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 63631
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94562
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 169349
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 58359
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247470
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 452948
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 34179
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 53953
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23333
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 270793
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76298
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192917
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 203438
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 52456
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 192491
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 223836
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 109129
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 39012
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 294478
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 210717
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 123922
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 57032
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 85315
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 52438
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33101
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 131263
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 22097
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 161168
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92689
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214093
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 63288
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 162918
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 343653
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 115071
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115804
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 256045
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 120822
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 362809
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168247
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 119187
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 230574
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 114501
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 188497
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 111022
  },
  {
      "roleId": 26,
      "role": "Customer Experience Specialist",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116023
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 262573
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 159924
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 64436
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 184777
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 70077
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63499
  },
  {
      "roleId": 136,
      "role": "Medical Rep",
      "msaId": 1,
      "msa": "london",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 165036
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 26777
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111482
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 200166
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 17289
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 48640
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 64949
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106600
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 9,
      "msa": "bengaluru",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7753
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 6941
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 57165
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29774
  },
  {
      "roleId": 52,
      "role": "Account Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 31513
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 78222
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 41097
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 112142
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 101556
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 39512
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 94314
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107044
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 172878
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 110703
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 81742
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117899
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17059
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29043
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 78177
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 118901
  },
  {
      "roleId": 146,
      "role": "Graphic Designer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 17914
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 71809
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 201010
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 107941
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74014
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 58982
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 7920
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80210
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 68639
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 28441
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 104563
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 31051
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 113524
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 142054
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112636
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 12981
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72199
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 15699
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 95433
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85476
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 58843
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 159819
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 167108
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 111218
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 73356
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21565
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 162177
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 32530
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 125776
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 112028
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 10991
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 156567
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131705
  },
  {
      "roleId": 98,
      "role": "Machine Operator",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9576
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 107990
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 166727
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 14209
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 68296
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 112297
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 87985
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 79064
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174977
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42509
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 194061
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 64110
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 107545
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94165
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 23061
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 11634
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 99284
  },
  {
      "roleId": 148,
      "role": "Marketing",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 167022
  },
  {
      "roleId": 89,
      "role": "Foreman",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 126475
  },
  {
      "roleId": 55,
      "role": "Operations Coordinator",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 61083
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 263789
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 106572
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 104990
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 113172
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 108248
  },
  {
      "roleId": 106,
      "role": "Web Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 113593
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 96544
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 74773
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 159450
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 93369
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 83728
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 150449
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 132839
  },
  {
      "roleId": 86,
      "role": "IT Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 333931
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 33888
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 104524
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 213059
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106189
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 40065
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 146107
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 27703
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 137431
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 171907
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203190
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 242686
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 32905
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 129583
  },
  {
      "roleId": 130,
      "role": "Coordinator",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 17358
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 337400
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 270360
  },
  {
      "roleId": 149,
      "role": "Producer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 222243
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 87747
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 186260
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 145818
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218773
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 277939
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 46934
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 174459
  },
  {
      "roleId": 80,
      "role": "Technical Support Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 77832
  },
  {
      "roleId": 133,
      "role": "Scientist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 60736
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 94982
  },
  {
      "roleId": 94,
      "role": "Business Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54275
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 22712
  },
  {
      "roleId": 58,
      "role": "Logistics",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 218958
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 29,
      "msa": "rotterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 44804
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 190178
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 134906
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 24359
  },
  {
      "roleId": 31,
      "role": "Cleaner",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 45014
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 11,
      "msa": "chicago",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 313103
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205642
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 174389
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 156590
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 324074
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 66,
      "msa": "vancouver",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 99828
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 28350
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 139581
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 50765
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 17279
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 249215
  },
  {
      "roleId": 110,
      "role": "Data Analyst",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 67770
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 219880
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 288682
  },
  {
      "roleId": 116,
      "role": "Student Intern",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 210405
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 21358
  },
  {
      "roleId": 104,
      "role": "IT Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 227048
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12192
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 13542
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 41776
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 76172
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 63311
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 118802
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 60251
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 76962
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 47380
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 26025
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 50399
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 365415
  },
  {
      "roleId": 141,
      "role": "Digital Marketing Specialist",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 45792
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 154729
  },
  {
      "roleId": 23,
      "role": "Sales Service Representative",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 26886
  },
  {
      "roleId": 88,
      "role": "Construction Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82722
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87027
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 69492
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 81388
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 177245
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 24382
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 237633
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 109639
  },
  {
      "roleId": 107,
      "role": "Data Scientist",
      "msaId": 31,
      "msa": "seattle",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 214690
  },
  {
      "roleId": 96,
      "role": "Manufacturing Associate",
      "msaId": 974,
      "msa": "hyderabad",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9839
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 86775
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 136717
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 56905
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104471
  },
  {
      "roleId": 111,
      "role": "Mechanic",
      "msaId": 17,
      "msa": "boston",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 116495
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 9810
  },
  {
      "roleId": 147,
      "role": "Communications Specialist",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 12545
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 45,
      "msa": "chennai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 13492
  },
  {
      "roleId": 24,
      "role": "Retail Sales",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 104667
  },
  {
      "roleId": 41,
      "role": "Sales Representative",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 92712
  },
  {
      "roleId": 33,
      "role": "Commercial Manager",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 85233
  },
  {
      "roleId": 64,
      "role": "Officer",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 73725
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 87829
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 182273
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 139422
  },
  {
      "roleId": 145,
      "role": "Public Relations",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 21954
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 116973
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 54884
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 5,
      "msa": "sao paulo",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30334
  },
  {
      "roleId": 99,
      "role": "Technical Architect",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 5701
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 68187
  },
  {
      "roleId": 10,
      "role": "Investment Specialist",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 240196
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 74350
  },
  {
      "roleId": 2,
      "role": "Economist",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 90824
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 13140
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 38,
      "msa": "denver",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 83409
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 22,
      "msa": "madrid",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 41304
  },
  {
      "roleId": 103,
      "role": "Technology Lead",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 90528
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 8,
      "msa": "singapore",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 128528
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 71519
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 72097
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 94941
  },
  {
      "roleId": 12,
      "role": "Client Services",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 52522
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 199464
  },
  {
      "roleId": 7,
      "role": "Wealth Manager",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 165482
  },
  {
      "roleId": 16,
      "role": "Auditor",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 151329
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 88145
  },
  {
      "roleId": 54,
      "role": "Distribution Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 80278
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 98138
  },
  {
      "roleId": 83,
      "role": "Technician",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 123523
  },
  {
      "roleId": 49,
      "role": "Sales Associate",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 303568
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 95414
  },
  {
      "roleId": 51,
      "role": "Customer Success Specialist",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 66043
  },
  {
      "roleId": 140,
      "role": "UX Designer",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 109870
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 125337
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 117762
  },
  {
      "roleId": 129,
      "role": "Coach",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 42081
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 58,
      "msa": "manila",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 16472
  },
  {
      "roleId": 21,
      "role": "Sales Training Specialist",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 69250
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 75048
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 82015
  },
  {
      "roleId": 97,
      "role": "Automation Engineer",
      "msaId": 20,
      "msa": "mumbai",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 9888
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 184118
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 102034
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 75621
  },
  {
      "roleId": 17,
      "role": "Accountant",
      "msaId": 30,
      "msa": "houston",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 201179
  },
  {
      "roleId": 68,
      "role": "Production Operator",
      "msaId": 26,
      "msa": "atlanta",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 91528
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 92560
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 43577
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 34562
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131041
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 28,
      "msa": "dallas",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 115162
  },
  {
      "roleId": 5,
      "role": "Financial Analyst",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 72199
  },
  {
      "roleId": 131,
      "role": "Information Specialist",
      "msaId": 84,
      "msa": "kuala lumpur",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 15518
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 98001
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 42567
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 117275
  },
  {
      "roleId": 38,
      "role": "Corporate Strategy",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 2,
      "seniority": "Junior",
      "salary": 30113
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 120353
  },
  {
      "roleId": 36,
      "role": "Receptionist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 202398
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 29738
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 122613
  },
  {
      "roleId": 19,
      "role": "Security Specialist",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 102582
  },
  {
      "roleId": 34,
      "role": "Customer Service",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 106730
  },
  {
      "roleId": 113,
      "role": "Software Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 226867
  },
  {
      "roleId": 118,
      "role": "HR Business Partner",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 65779
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 197997
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 48,
      "msa": "san jose",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 227327
  },
  {
      "roleId": 90,
      "role": "Mechanical Engineer",
      "msaId": 13,
      "msa": "amsterdam",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 168048
  },
  {
      "roleId": 122,
      "role": "Corporate Trainer",
      "msaId": 7,
      "msa": "delhi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 16547
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 24,
      "msa": "philadelphia",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 121863
  },
  {
      "roleId": 66,
      "role": "QA Tester",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 108691
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 140130
  },
  {
      "roleId": 3,
      "role": "Financial Advisor",
      "msaId": 37,
      "msa": "pune",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 14106
  },
  {
      "roleId": 87,
      "role": "Electrical Engineer",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 36882
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 180174
  },
  {
      "roleId": 43,
      "role": "Stylist",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123559
  },
  {
      "roleId": 20,
      "role": "Sales Engineer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 141265
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 21,
      "msa": "san francisco",
      "seniorityId": 3,
      "seniority": "Associate",
      "salary": 131235
  },
  {
      "roleId": 44,
      "role": "Crew Member",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 247070
  },
  {
      "roleId": 48,
      "role": "Pilot",
      "msaId": 15,
      "msa": "los angeles",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 377688
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 209632
  },
  {
      "roleId": 69,
      "role": "Quality Assurance",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 60797
  },
  {
      "roleId": 119,
      "role": "Human Resources Specialist",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 157500
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 18,
      "msa": "melbourne",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 237666
  },
  {
      "roleId": 11,
      "role": "Agent",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 22801
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 32,
      "msa": "jakarta",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 33471
  },
  {
      "roleId": 143,
      "role": "Writer",
      "msaId": 46,
      "msa": "stockholm",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 260990
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 123993
  },
  {
      "roleId": 53,
      "role": "Project Administrator",
      "msaId": 68,
      "msa": "cairo",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 29534
  },
  {
      "roleId": 9,
      "role": "Banker",
      "msaId": 10,
      "msa": "toronto",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 246434
  },
  {
      "roleId": 102,
      "role": "Technology Analyst",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 25603
  },
  {
      "roleId": 75,
      "role": "Safety Officer",
      "msaId": 56,
      "msa": "montreal",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 118516
  },
  {
      "roleId": 85,
      "role": "Systems Engineer",
      "msaId": 71,
      "msa": "berlin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 116291
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 40809
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 245797
  },
  {
      "roleId": 109,
      "role": "Software Developer",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 292558
  },
  {
      "roleId": 127,
      "role": "Legal",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 217836
  },
  {
      "roleId": 59,
      "role": "Procurement Specialist",
      "msaId": 2,
      "msa": "new york city",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 230843
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 14,
      "msa": "washington",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 187384
  },
  {
      "roleId": 8,
      "role": "Realtor",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 276882
  },
  {
      "roleId": 74,
      "role": "Test Engineer",
      "msaId": 23,
      "msa": "dubai",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 256977
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 116113
  },
  {
      "roleId": 84,
      "role": "Technical Support",
      "msaId": 51,
      "msa": "phoenix",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 161456
  },
  {
      "roleId": 32,
      "role": "Restaurant Manager",
      "msaId": 67,
      "msa": "austin",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 214429
  },
  {
      "roleId": 18,
      "role": "Billing Specialist",
      "msaId": 113,
      "msa": "rio de janeiro",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 49987
  },
  {
      "roleId": 142,
      "role": "Designer",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 149859
  },
  {
      "roleId": 132,
      "role": "Operations Administrator",
      "msaId": 55,
      "msa": "minneapolis",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 173867
  },
  {
      "roleId": 117,
      "role": "Recruiter",
      "msaId": 110,
      "msa": "copenhagen",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 131785
  },
  {
      "roleId": 138,
      "role": "Case Manager",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 271720
  },
  {
      "roleId": 93,
      "role": "Data Engineer",
      "msaId": 88,
      "msa": "santiago",
      "seniorityId": 5,
      "seniority": "Vice President",
      "salary": 51716
  },
  {
      "roleId": 60,
      "role": "Project Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 203600
  },
  {
      "roleId": 45,
      "role": "Cashier",
      "msaId": 3,
      "msa": "paris",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 192454
  },
  {
      "roleId": 57,
      "role": "Operations Manager",
      "msaId": 35,
      "msa": "milan",
      "seniorityId": 1,
      "seniority": "Entry Level",
      "salary": 29464
  },
  {
      "roleId": 71,
      "role": "Structural Engineer",
      "msaId": 16,
      "msa": "sydney",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 183958
  },
  {
      "roleId": 29,
      "role": "Pharmacy Technician",
      "msaId": 52,
      "msa": "istanbul",
      "seniorityId": 4,
      "seniority": "Manager",
      "salary": 47825
  },
  {
      "roleId": 56,
      "role": "Driver",
      "msaId": 41,
      "msa": "barcelona",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 105317
  },
  {
      "roleId": 72,
      "role": "Process Engineer",
      "msaId": 57,
      "msa": "bogota",
      "seniorityId": 7,
      "seniority": "C-Suite",
      "salary": 114323
  },
  {
      "roleId": 47,
      "role": "Product Manager",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 196807
  },
  {
      "roleId": 73,
      "role": "Sustainability Specialist",
      "msaId": 50,
      "msa": "abu dhabi",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 205172
  },
  {
      "roleId": 82,
      "role": "Information Security",
      "msaId": 33,
      "msa": "brussels",
      "seniorityId": 6,
      "seniority": "Director",
      "salary": 104865
  }
]
