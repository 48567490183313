import { Input } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { usePlayer } from './context/PlayerContext';
import { uniqueId } from 'lodash';

type FormValues = {
  email: string;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EmailFormInput = ({ subscribe, status, message }: any) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormValues>();

  const { setPlayer } = usePlayer();

  const onSubmit = handleSubmit((value) => {
    const { email } = value;

    setPlayer({ playerId: `player_${uniqueId}`, email });

    reset();

    // subscribe({
    //   EMAIL: email,
    //   // TODO: This is a Test tag, replace this with the actual tag
    //   tags: '5444085',
    // });
  });
  return (
    <form
      onSubmit={onSubmit}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* <Text color="white" mb="8px">
        Enter your email
      </Text> */}
      <Input
        autoFocus
        autoComplete="off"
        w="full"
        size="lg"
        h="80px"
        fontSize="36px"
        textAlign="center"
        type="email"
        backgroundColor="white"
        color="black"
        // pl="40px"
        // variant={'filled'}
        borderRadius="40px"
        placeholder="Enter your email"
        {...register('email', {
          required: 'Email',
          pattern: {
            value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i,
            message: 'Invalid Email Address',
          },
        })}
      />
      {/* <button type="submit" style={{ color: 'white' }}>
          Submit
        </button> */}
      <div style={{ color: 'white' }}>
        {status === 'success' && message}
        {status === 'error' && message}
        {errors?.email &&
          Object.entries(errors).map((error, index) => (
            <span key={error[1].message}>
              {Object.keys(errors).length === index + 1 ? (
                <>{error[1].message} Required</>
              ) : (
                <>{error[1].message}, </>
              )}
            </span>
          ))}
      </div>
    </form>
  );
};
