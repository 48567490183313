import {
  Button,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useMemo } from 'react';

import { isEmpty, startCase } from 'lodash';
import { KDEPlot } from '@revelio/d3';

import { MarketTightnessSlider } from './MarketTightnessSlider';
import { Loading } from '@revelio/core';
import { WinnerInterface } from '../game/Game';
import SlotText from '../game/SlotText';
import spotLights from '../game/assets/spot-lights.png';
import { NoResultsPlaceholder } from '../game/NoResults';
import { Leaderboard } from './leaderboard/Leaderboard';

type ResultsDataType = Record<string, string>;

interface ResultsModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: ResultsDataType;
  // TODO: Need to type data
  // eslint-disable-next-line
  kdeData: any;
  isLoading?: boolean;
  setIsPlotLoading?: React.Dispatch<boolean>;
  winner: WinnerInterface[];
  setHighestScore?: React.Dispatch<number>;
  [key: string]: any;
}

const ResultsModal = ({
  isOpen,
  onClose,
  data,
  kdeData,
  isLoading,
  setIsPlotLoading,
  winner,
  setHighestScore,
  containerRef,
  leaderboard,
}: ResultsModalProps) => {
  console.log({ isOpen });
  const generateStatsListEntities = (results: ResultsDataType) => {
    if (isEmpty(results)) {
      return <NoResultsPlaceholder />;
    }
    return Object.entries(results).map(([key, stat]) => {
      if (key === 'market_tightness') {
        return (
          <Flex key={key} direction="column">
            <Text pb={8} color="white" fontSize="20px">
              {startCase(key)}
            </Text>
            <MarketTightnessSlider marketTightness={stat} />
          </Flex>
        );
      }
      return (
        <Flex key={key} justify="space-between">
          <Text color="white" fontSize="20px">
            {startCase(key)}
          </Text>
          <Text
            color={
              /* eslint-disable-next-line no-nested-ternary */
              stat.includes('−')
                ? '#ff2261'
                : stat.includes('+')
                  ? '#9dff4e'
                  : 'white'
            }
            fontSize="20px"
          >
            {stat}
          </Text>
        </Flex>
      );
    });
  };

  const statsList = useMemo(() => {
    if (!data) {
      return [];
    }
    return generateStatsListEntities(data);
  }, [data]);

  return (
    <Modal
      onClose={onClose}
      size="full"
      isCentered
      isOpen={isOpen}
      portalProps={{ appendToParentPortal: true, containerRef }}
    >
      <ModalContent h="100%" maxH="100%" maxW="100%" background="none">
        <Grid
          flexDirection="column"
          color="white"
          templateRows="repeat(20, 1fr)"
          h="full"
          w="full"
        >
          <GridItem rowSpan={11}>
            <ModalBody background="rgba(0, 0, 0, 0.94)" h="100%" p={10}>
              {isLoading && (
                <Flex
                  h="100%"
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                  zIndex={80}
                >
                  <Loading transparentBackground reverse />
                </Flex>
              )}

              <Grid
                templateColumns="repeat(4, 1fr)"
                templateRows="repeat(10, 1fr)"
                h="100%"
                visibility={isLoading ? 'hidden' : 'visible'}
              >
                <GridItem gridArea="1 / 1 / 2 / 5">
                  <Text
                    fontSize="40px"
                    color="#fff"
                    fontWeight="400"
                    fontFamily="Righteous, sans-serif"
                  >
                    RESULTS
                  </Text>
                </GridItem>

                <GridItem gridArea="2 / 1 / 6 / 4">
                  <Leaderboard value={leaderboard} />
                </GridItem>
                <GridItem
                  gridArea="2 / 4 / 6 / 5"
                  backgroundColor="rgba(0,0,0,0.7)"
                  p={4}
                  borderRadius={16}
                >
                  <Flex direction="column" rowGap={5}>
                    <Flex>
                      <Text
                        fontFamily="Righteous, sans-serif"
                        color="white"
                        fontSize="18px"
                        fontWeight={600}
                      >
                        OTHER STATS
                      </Text>
                    </Flex>
                    <Flex direction="column" gap={6}>
                      {statsList}
                    </Flex>
                  </Flex>
                </GridItem>
                <GridItem gridArea="7 / 1 / 11 / 5">
                  <Text
                    fontFamily="Righteous, sans-serif"
                    color="white"
                    fontSize="24px"
                    fontWeight={600}
                    textAlign="center"
                  >
                    Salary Distribution
                  </Text>
                  <KDEPlot
                    data={kdeData?.value || []}
                    lineColor="rgba(79, 255, 79, 1)"
                    linearGradient={[
                      'rgba(119, 36, 255, .5)',
                      'rgba(119, 36, 255, 0)',
                    ]}
                    lineStrokeWidth={4}
                    xAxisLabelColor="#fff"
                    xAxisLabelSize="18px"
                    setIsLoading={setIsPlotLoading}
                    customMargins={{
                      bottom: 60,
                    }}
                    axisGridLineClassName="axis-grid-gameV2"
                    // showMaxPoint
                    // setHighestScore={setHighestScore}
                  />
                </GridItem>
              </Grid>
            </ModalBody>
          </GridItem>
        </Grid>
      </ModalContent>
    </Modal>
  );
};

export default ResultsModal;
