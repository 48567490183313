import React from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import { isEmpty } from 'lodash';

interface LeaderboardItemProps {
  rank: string;
  username: string;
  result: string;
  salary: string;
  isCurrent?: boolean;
}

export const LeaderboardItem = ({
  rank,
  username,
  result,
  salary,
  isCurrent,
}: LeaderboardItemProps) => {
  return (
    <Flex
      border={`3px solid ${isCurrent ? 'rgba(79, 255, 79, 1)' : 'rgba(54, 54, 54, 1)'}`}
      backgroundColor="rgba(0,0,0,0.7)"
      w={isCurrent ? '90%' : '75%'}
      borderRadius="10px"
      justifyContent="space-between"
      alignItems="center"
      fontFamily="Russo One, sans-serif"
      px={4}
      py={2}
    >
      <Flex gap={3} alignItems="center">
        {isCurrent && (
          <Text color="white" fontSize="20px">
            YOU
          </Text>
        )}
        <Text
          fontSize="35px"
          color="transparent"
          opacity={isCurrent ? 1 : 0.5}
          sx={{
            WebkitTextStroke: '1.2px rgba(255, 255, 255, 1)',
          }}
        >
          {rank}
        </Text>
      </Flex>
      <Flex
        direction="column"
        alignItems="center"
        opacity={isCurrent ? 1 : 0.5}
      >
        <Text fontSize="30px" color="#fff">
          {(!isEmpty(username) && username) || 'Anonymous'}
        </Text>
        <Text fontSize="20px" color="#fff">
          {result}
        </Text>
      </Flex>
      <Text
        fontSize="40px"
        opacity={isCurrent ? 1 : 0.5}
        sx={{
          background: 'linear-gradient(180deg, #00C113 11.81%, #8CFF56 86.22%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          blendMode: isCurrent ? 'normal' : 'luminosity',
          WebkitTextStroke: '1.2px rgba(255, 255, 255, 1)',
        }}
      >
        {salary}
      </Text>
    </Flex>
  );
};
